import React, { useEffect, useState } from "react";
import { Button, Input, Select, DatePicker } from "antd";
import { httpService } from "../../../../../services/httpService.service";
import dayjs from "dayjs";
import SearchIcon from "./../../../../../Assets/svg/searchIcon.svg";
import debounce from "lodash/debounce";
const { Option } = Select;

const Filters = ({ onChange, setFilters }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [implementingPartners, setImplementingPartners] = useState();
  const [clearButtonClick, setClearButtonClick] = useState(0);

  const debouncedFilterChange = debounce((key, value) => {
    onChange(key, value);
  }, 500);

  useEffect(() => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => setAllCountries(res.data),
      (error) => console.log(error)
    );

    const workspaceId = localStorage.getItem("currentContractId");
    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleFilterChange = (key, value) => {
    debouncedFilterChange(key, value);
  };

  const handleClearAll = () => {
    setFilters({});
    setSelectedMonth(null);
    setSelectedYear(null);
    setClearButtonClick((prevClick) => prevClick + 1);
  };

  const handleMonthChange = (date, dateString) => {
    if (date) {
      const month = date.month() + 1;
      setSelectedMonth(month);
      handleFilterChange("Month", month);
    } else {
      setSelectedMonth(null);
      handleFilterChange("Month", null);
    }
  };

  const handleYearChange = (date, dateString) => {
    if (date) {
      const year = date.year();
      setSelectedYear(year);
      handleFilterChange("Year", year);
    } else {
      setSelectedYear(null);
      handleFilterChange("Year", null);
    }
  };

  return (
    <div className="filtersWrapper">
      <div className="containerFilters">
        <Input
          key={`search-input-${clearButtonClick}`}
          placeholder="Type something here"
          suffix={<img src={SearchIcon} alt="Search" />}
          onKeyUp={(e) => handleFilterChange("Search", e.target.value)}
        />
        <Button className="clearButton" onClick={handleClearAll}>
          Clear All
        </Button>
        <Button onClick={handleToggleFilters} className="toggleButton">
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filtersContainer">
          <div className="filter">
            <p className="filterText">Implementing Partner</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the IP"
              allowClear
              showSearch
              onChange={(value) =>
                handleFilterChange("ImplementingPartnerId", value)
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              {implementingPartners &&
                implementingPartners.map((implementingPartner) => (
                  <Option
                    key={implementingPartner.id}
                    value={implementingPartner.id}
                  >
                    {implementingPartner.name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Location</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select the state"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              onChange={(value) => handleFilterChange("State", value)}
            >
              {allCountries.map((c) => (
                <Option key={c.id} value={c.stateName}>
                  {c.stateName}
                </Option>
              ))}
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Site visit status</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a site visit status"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              onChange={(value) => handleFilterChange("SiteVisitStatus", value)}
            >
              <Option value="1">Monitoring</Option>
              <Option value="2">Data collection in progress</Option>
              <Option value="3">Review</Option>
              <Option value="4">Delivered</Option>
              <Option value="5">Debrief</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Reporting status</p>
            <Select
              key={`select-input-${clearButtonClick}`}
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select a reporting status"
              allowClear
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
              onChange={(value) =>
                handleFilterChange("FieldTeamReportingStatus", value)
              }
            >
              <Option value={1}>Data Collection</Option>
              <Option value={2}>Data Check</Option>
              <Option value={3}>Translation</Option>
              <Option value={4}>Field Reporting Completed</Option>
            </Select>
          </div>
          <div className="filter">
            <p className="filterText">Month</p>
            <DatePicker
              key={`month-input-${clearButtonClick}`}
              picker="month"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select month"
              onChange={handleMonthChange}
              format="MM"
              allowClear
              value={selectedMonth ? dayjs().month(selectedMonth - 1) : null}
            />
          </div>
          <div className="filter">
            <p className="filterText">Year</p>
            <DatePicker
              key={`year-input-${clearButtonClick}`}
              picker="year"
              style={{ width: 200, marginBottom: 20 }}
              placeholder="Select year"
              onChange={handleYearChange}
              format="YYYY"
              allowClear
              value={selectedYear ? dayjs().year(selectedYear) : null}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
