import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Typography,
  Spin,
  Col,
  Select,
  Row,
  Switch,
  DatePicker,
  Upload,
  Checkbox,
  InputNumber,
} from "antd";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import "../../../planning/VisitBoard/Modals/style.scss";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import dayjs from "dayjs";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";
import dangerIcon from "../../../../../Assets/svg/dangerIcon.svg";

const { Option } = Select;

const { TextArea } = Input;

const { Title } = Typography;

const Edit = ({ visible, onCancel, onOk, siteVisitCardId, setMessage }) => {
  const [form] = Form.useForm();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enumerators, setEnumerators] = useState([]);
  const [siteVideos, setSiteVideos] = useState([]);
  const [reportedDocs, setReportedDocs] = useState([]);
  const [otherReportedDocs, setOtherReportedDocs] = useState([]);
  const [showVisitDetails, setShowVisitDetails] = useState(false);
  const [siteVisitId, setSiteVisitId] = useState("");
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [existingFiles, setExistingFiles] = useState([
    {
      report_documents: [],
      video_files: [],
      otherReportedDocs: [],
    },
  ]);
  const [filesLimit, setFilesLimit] = useState({
    photosLimit: 0,
    videosLimit: 0,
  });
  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => setMessage({ trigger: false }), 3000);
    }
    return isValidSize;
  };

  const handleVideoUpload = (info) => {
    const { fileList } = info;
    setSiteVideos(fileList);
    form.setFieldsValue({
      video_files: fileList,
    });
  };
  const handleReportingDocs = (info) => {
    const { fileList } = info;
    setReportedDocs(fileList);
    form.setFieldsValue({
      report_documents: fileList,
    });
  };

  const handleOtherReportDocs = (info) => {
    const { fileList } = info;
    setOtherReportedDocs(fileList);
    form.setFieldValue({
      otherReportedDocs: fileList,
    });
  };

  const handleDeleteSiteVisit = (itemToRemove) => {
    const newFiles = reportedDocs?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    setReportedDocs(newFiles);
  };

  const handleDeleteOtherReport = (itemToRemove) => {
    const newFiles = otherReportedDocs?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    setOtherReportedDocs(newFiles);
  };

  const handleDeleteSiteVideos = (itemToRemove) => {
    const newFiles = siteVideos?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    setSiteVideos(newFiles);
  };

  const handleDeleteExistingSiteVisit = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        report_documents: prevFiles.report_documents?.filter(
          (item) => item.fileDataId !== file.fileDataId
        ),
        video_files: prevFiles.video_files,
        otherReportedDocs: prevFiles.otherReportedDocs,
      }));
    }
  };
  const handleDeleteExistingOtherReportingDocs = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        otherReportedDocs: prevFiles.otherReportedDocs?.filter(
          (item) => item.fileDataId !== file.fileDataId
        ),
        report_documents: prevFiles.report_documents,
        video_files: prevFiles.video_files,
      }));
    }
  };
  const handleDeleteExistingVideos = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        report_documents: prevFiles.report_documents,
        otherReportedDocs: prevFiles.otherReportedDocs,
        video_files: prevFiles.video_files?.filter(
          (item) => item.fileDataId !== file.fileDataId
        ),
      }));
    }
  };

  useEffect(() => {
    if (siteVisitCardId && visible && form) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          const enumeratorsData = res?.data?.tracking?.enumerators;
          const enumeratorsProgress = enumeratorsData.reduce(
            (acc, enumerator, index) => {
              acc[`trainingScore-${index}`] = enumerator?.trainingScore || "";
              acc[`percentageCompleted-${index}`] =
                enumerator?.percentageCompleted || "";
              return acc;
            },
            {}
          );
          setEnumerators(res?.data?.tracking?.enumerators);
          setSiteVisitId(res?.data?.id);
          setFilesLimit({
            photosLimit: res?.data?.photoCount,
            videosLimit: res?.data?.videoCount,
          });
          const files = res?.data?.siteVisitFiles;
          setExistingFiles({
            report_documents:
              files?.filter((file) => file?.fileCategory == 1) || [],
            video_files:
              files?.filter(
                (file) => file?.fileCategory == 3 || file?.fileCategory == 4
              ) || [],
            otherReportedDocs:
              files?.filter((file) => file?.fileCategory == 5) || [],
          });
          console.log(typeof res?.data?.videoCount); // Check the data type here

          const initialData = {
            ...enumeratorsProgress,

            videosRequired: res?.data?.hasVideos || false,
            videosLimit: res?.data?.videoCount ?? 0,
            photosRequired: res?.data?.hasPhotos || false,
            photosLimit: res?.data?.photoCount ?? 0,
            identifier: res?.data?.identifier,
            actualDateOfVisit: dayjs(res?.data?.tracking?.actualDateOfVisit),
            dateOfReport: dayjs(res?.data?.tracking?.dateOfReport),
            dateOfDebrief: dayjs(res?.data?.tracking?.dateOfDebrief),
            fieldTeamReportingStatus:
              res?.data?.tracking?.fieldTeamReportingStatus,
            percentageOfDataCollectionCompleted:
              res?.data?.tracking?.percentageOfDataCollectionCompleted,
            reportingMonth: res?.data?.tracking?.reportingMonth,
            reportingYear: res?.data?.tracking?.reportingYear,
            siteVisitStatus: res?.data?.tracking?.siteVisitStatus,
            trainingDate: dayjs(res?.data?.tracking?.trainingDate),
            trainingIsCompleted: res?.data?.tracking?.trainingIsCompleted,
            trainingScoreInfo: res?.data?.tracking?.trainingScoreInfo,
          };
          setRequiredPhotos(res?.data?.hasPhotos || false);
          setRequiredVideos(res?.data?.hasVideos || false);
          form.setFieldsValue(initialData);

          setLoading(false);
        },
        (err) => {
          console.log("Error: ", err);
          setLoading(false);
        }
      );
    }
  }, [visible, form]);

  const closeModal = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        formData.append("Id", siteVisitCardId);

        formData.append("HasVideos", values.videosRequired || false);
        formData.append("VideoCount", values.videosLimit || 0);

        formData.append("HasPhotos", values.photosRequired || false);
        formData.append("PhotoCount", values.photosLimit || 0);
        // Start with index 0
        let index = 0;

        // EXISTING REPORTED FILES
        existingFiles?.otherReportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 5);
          formData.append(`UpdateFiles[${index}].EntityId`, file.id);
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].FileKey`, file.filePath);
          index++; // Increment index for the next file
        });

        // NEW REPORTED FILES
        otherReportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 5);
          formData.append(`UpdateFiles[${index}].file`, file.originFileObj);
          index++; // Increment index for the next file
        });

        // EXISTING REPORTED FILES
        existingFiles?.report_documents?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 1);
          formData.append(`UpdateFiles[${index}].EntityId`, file.id);
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].FileKey`, file.filePath);
          index++; // Increment index for the next file
        });

        // NEW REPORTED FILES
        reportedDocs?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 1);
          formData.append(`UpdateFiles[${index}].file`, file.originFileObj);
          index++; // Increment index for the next file
        });

        // EXISTING VIDEOS AND PHOTOS
        const existingVideos = [];
        const existingImages = [];

        existingFiles?.video_files?.forEach((file) => {
          if (file?.fileKey?.endsWith(".mp4")) {
            existingVideos.push(file);
          } else {
            existingImages.push(file);
          }
        });

        existingVideos?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 3);
          formData.append(`UpdateFiles[${index}].EntityId`, file.id);
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].FileKey`, file.filePath);
          index++; // Increment index for the next file
        });

        existingImages?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 4);
          formData.append(`UpdateFiles[${index}].Id`, file.id);
          formData.append(`UpdateFiles[${index}].EntityId`, file.id);
          formData.append(`UpdateFiles[${index}].FileKey`, file.filePath);
          index++; // Increment index for the next file
        });

        // NEW FILES THAT HAVE BEEN UPLOADED INTO VIDEO_FILES INPUT
        const videosUploaded = [];
        const imagesUploaded = [];

        siteVideos?.forEach((file) => {
          if (file.type.startsWith("image")) {
            imagesUploaded.push(file);
          } else if (file.type.startsWith("video")) {
            videosUploaded.push(file);
          }
        });

        videosUploaded?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 3);
          formData.append(`UpdateFiles[${index}].file`, file.originFileObj);
          index++; // Increment index for the next file
        });

        imagesUploaded?.forEach((file) => {
          formData.append(`UpdateFiles[${index}].FileCategory`, 4);
          formData.append(`UpdateFiles[${index}].file`, file.originFileObj);
          index++; // Increment index for the next file
        });

        httpService.put(
          "/api/Site/updateSiteVisit",
          formData,
          (res) => {
            const message =
              res?.message || "You have successfully updated site visit files!";
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: `Site visit updated successfully!`,
              text: message,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
            setSubmitLoader(false);
            handleLeave();
            onOk();
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "Failed to add files to this site visit. Please try again later!";

            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Update Failed",
              text: errorMessage,
            });

            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);

            setSubmitLoader(false);
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const handleLeave = () => {
    form.resetFields();
    setSiteVideos([]);
    setReportedDocs([]);
    setOtherReportedDocs([]);
    setExistingFiles({
      report_documents: [],
      video_files: [],
      otherReportedDocs: [],
    });
    onCancel();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handlePhtosLimitChange = (limit) => {
    form.setFieldsValue({ photosLimit: limit });
    setRequiredPhotos(limit > 0);
    form.setFieldsValue({ photosRequired: limit > 0 });
  };

  const handleVideosLimitChange = (limit) => {
    form.setFieldsValue({ videosLimit: limit });
    setRequiredVideos(limit > 0);
    form.setFieldsValue({ videosRequired: limit > 0 });
  };

  const handleVideosRequiredChange = (e) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ videosRequired: isChecked });
    setRequiredVideos(isChecked); // optional, if you still need an external state
  };

  const handlePhotosRequiredChange = (e) => {
    const isChecked = e.target.checked;
    form.setFieldsValue({ photosRequired: isChecked });
    setRequiredPhotos(isChecked); // optional, if you still need an external state
  };

  useEffect(() => {
    console.log("Form:", form.getFieldsValue());
  }, [form]);

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={closeModal}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site Visit Reporting</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={closeModal}
      width={800}
      footer={[
        <Button key="back" onClick={closeModal} className="inviteButtonTPM">
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          Save changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form key={siteVisitCardId} form={form} layout="vertical">
          <div className="d-flex justify-content-between align-items-center gap-4 w-100">
            <div className="indentifierTracking w-100">
              <Form.Item
                className="w-100"
                label="Site visit card identifier"
                name="identifier"
              >
                <Input disabled />
              </Form.Item>
            </div>
            <Button
              className="btnSeeSiteDetails d-flex mt-1 justify-content-center"
              onClick={() => setShowVisitDetails(!showVisitDetails)}
            >
              See site visit card
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6 12L10 8L6 4"
                  stroke="#AA1A5F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button>
          </div>

          <Divider />

          <h3 className="m-0 mb-4 p-0">Tracking</h3>
          <div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="reportingYear" label="Reporting year">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="reportingMonth" label="Reporting month">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={"Training Completed"}
                  name="trainingIsCompleted"
                  valuePropName="checked"
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="trainingDate" label="Training date">
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8} />
            </Row>

            <div className="d-flex flex-column gap-3 mb-5 mt-4">
              <h3 className="m-0 p-0">Training scores</h3>
              {enumerators?.map((enumerator, index) => (
                <div
                  key={enumerator?.id}
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{
                    padding: "10px 16px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid var(--5, #F5B0D2)",
                    background: "var(--7, #FFF5FA)",
                  }}
                >
                  <div className="d-flex justify-content-start align-items-center h-100 w-100">
                    <p
                      className="m-0"
                      style={{
                        color: "var(--Neutrals-Black, #3A3737)",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                    >
                      {enumerator?.firstName && enumerator?.lastName
                        ? enumerator?.firstName + " " + enumerator?.lastName
                        : enumerator?.email}
                    </p>
                  </div>

                  <Form.Item
                    name={`trainingScore-${index}`}
                    className="m-0 p-0"
                    label="Training score"
                    style={{ width: "178px" }}
                  >
                    <Input
                      type="number"
                      disabled
                      value={enumerator?.trainingScore}
                    />
                  </Form.Item>
                </div>
              ))}
            </div>

            <Row gutter={24}>
              <Col span={16}>
                <Form.Item
                  name="actualDateOfVisit"
                  label="Actual date of visit"
                >
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex flex-column gap-3 mb-5 mt-4">
              <h3 className="m-0 p-0">% of data collection completed</h3>
              {enumerators?.map((enumerator, index) => (
                <div
                  key={enumerator?.id}
                  className="d-flex justify-content-between align-items-center w-100"
                  style={{
                    padding: "10px 16px",
                    alignSelf: "stretch",
                    borderRadius: "8px",
                    border: "1px solid var(--5, #F5B0D2)",
                    background: "var(--7, #FFF5FA)",
                  }}
                >
                  <div className="d-flex justify-content-start align-items-center h-100 w-100">
                    <p
                      className="m-0"
                      style={{
                        color: "var(--Neutrals-Black, #3A3737)",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                    >
                      {enumerator?.firstName && enumerator?.lastName
                        ? enumerator?.firstName + " " + enumerator?.lastName
                        : enumerator?.email}
                    </p>
                  </div>

                  <Form.Item
                    name={`percentageCompleted-${index}`}
                    className="m-0 p-0"
                    label="% completed"
                    style={{ width: "178px" }}
                  >
                    <Input
                      suffix="%"
                      type="number"
                      disabled
                      value={enumerator?.percentageCompleted}
                    />
                  </Form.Item>
                </div>
              ))}
            </div>

            <Row gutter={24}></Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item name="dateOfReport" label="Date of report" disabled>
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="dateOfDebrief" label="Date of debrief">
                  <DatePicker style={{ width: "100%" }} disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="siteVisitStatus" label="Site visit status">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Preview
              visible={showVisitDetails}
              onClose={() => setShowVisitDetails(false)}
              siteVisitCardId={siteVisitId}
            />
            <Col span={8}>
              <Form.Item
                name="fieldTeamReportingStatus"
                label="Reporting Status"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </div>

          <Divider />

          <Form.Item
            name="report_documents"
            label="Upload site visit report document(s)"
            style={{ fontWeight: "500" }}
          >
            <Upload.Dragger
              listType="text"
              multiple={true}
              name="report_documents"
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              fileList={reportedDocs}
              onChange={handleReportingDocs}
              beforeUpload={(file) => handleFileSize(file)}
            >
              <div className="d-flex justify-content-center pb-3">
                <img src={uploadIcon} alt="" />
              </div>
              <Button className="uploadButton">
                Drag and drop site visit report document(s) here, or click to
                select files
              </Button>
              <div className="d-flex justify-content-center bottomText ">
                <span className="span3">
                  Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
                </span>
                <span className="span4">Max file size: 5MB</span>
              </div>
            </Upload.Dragger>
            <div className="uploadsContainer">
              {Array.isArray(reportedDocs) &&
                reportedDocs?.map((file) => (
                  <div className="uploadContent" key={file.uid}>
                    <img
                      className="uploadIcon"
                      src={uploadIcon}
                      alt={file.name}
                    />
                    <div>
                      <h1>{file.name}</h1>
                      {file.size && (
                        <p>
                          {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                        </p>
                      )}
                    </div>
                    <img
                      onClick={() => handleDeleteSiteVisit(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              {existingFiles?.report_documents?.map((file) => (
                <div className="uploadContent" key={file?.fileDataId}>
                  <img
                    className="uploadIcon"
                    src={uploadIcon}
                    alt={file?.fileName}
                  />
                  <div>
                    <h1>{file?.fileName}</h1>
                  </div>
                  <img
                    onClick={() => handleDeleteExistingSiteVisit(file)}
                    src={RemoveIcon}
                    alt="RemoveIcon"
                  />
                </div>
              ))}
            </div>
          </Form.Item>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              paddingBottom: "20px",
            }}
          >
            <div className="mediaWrapper">
              <div className="info">
                <div className="content">
                  <div className="image">
                    <img src={dangerIcon} alt="danger icon" />
                  </div>
                  <p>
                    <span>Disclosure:</span> prior informed consent is needed.
                  </p>
                </div>
              </div>
              <div className="card">
                <Form.Item name="videosRequired">
                  <Checkbox
                    checked={requiredVideos}
                    onChange={handleVideosRequiredChange}
                  >
                    Videos
                  </Checkbox>
                </Form.Item>

                <Divider className="m-2" />
                <Form.Item
                  name="videosLimit"
                  label="How many?"
                  required={requiredVideos}
                >
                  <InputNumber
                    min={0}
                    onChange={handleVideosLimitChange}
                    disabled={!requiredVideos}
                  />
                </Form.Item>
              </div>
              <div className="card">
                <Form.Item name="photosRequired">
                  <Checkbox
                    checked={requiredPhotos}
                    onChange={handlePhotosRequiredChange}
                  >
                    Photos
                  </Checkbox>
                </Form.Item>

                <Divider className="m-2" />
                <Form.Item
                  name="photosLimit"
                  label="How many?"
                  required={requiredPhotos}
                >
                  <InputNumber
                    onChange={handlePhtosLimitChange}
                    min={0}
                    disabled={!requiredPhotos}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="uploadDivStyle">
            <Form.Item
              name="video_files"
              label="Upload site photo and video file(s)"
              style={{ fontWeight: "500" }}
            >
              <Upload.Dragger
                listType="text"
                accept=".mp4, .jpg, .jpeg, .png"
                fileList={siteVideos}
                beforeUpload={(file) => handleFileSize(file)}
                multiple={true}
                onChange={handleVideoUpload}
              >
                <div className="d-flex justify-content-center pb-3">
                  <img src={uploadIcon} alt="" />
                </div>
                <Button className="uploadButton">
                  Drag and drop files here, or click to select files
                </Button>
                <div className="d-flex justify-content-center bottomText">
                  <span className="span3">
                    Allowed formats: .mp4, .jpg, .jpeg, .png
                  </span>
                  <span className="span4">Max file size: 5MB</span>
                </div>
              </Upload.Dragger>
              <div className="uploadsContainer">
                {Array?.isArray(siteVideos) &&
                  siteVideos?.map((file) => (
                    <div className="uploadContent" key={file.uid}>
                      <img
                        className="uploadIcon"
                        src={uploadIcon}
                        alt={file.name}
                      />
                      <div>
                        <h1>{file.name}</h1>
                        {file.size && (
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        )}
                      </div>
                      <img
                        onClick={() => handleDeleteSiteVideos(file)}
                        src={RemoveIcon}
                        alt="RemoveIcon"
                      />
                    </div>
                  ))}

                {existingFiles?.video_files?.map((file) => (
                  <div className="uploadContent" key={file?.fileDataId}>
                    <img
                      className="uploadIcon"
                      src={uploadIcon}
                      alt={file?.fileName}
                    />
                    <div>
                      <h1>{file?.fileName}</h1>
                    </div>
                    <img
                      onClick={() => handleDeleteExistingVideos(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              </div>
            </Form.Item>
          </div>

          <Form.Item
            name="otherReportDocs"
            label="Upload other report document(s)"
            style={{ fontWeight: "500" }}
          >
            <Upload.Dragger
              listType="text"
              multiple={true}
              name="otherReportDocs"
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              fileList={reportedDocs}
              onChange={handleOtherReportDocs}
              beforeUpload={(file) => handleFileSize(file)}
            >
              <div className="d-flex justify-content-center pb-3">
                <img src={uploadIcon} alt="" />
              </div>
              <Button className="uploadButton">
                Drag and drop other report document(s) here, or click to select
                files
              </Button>
              <div className="d-flex justify-content-center bottomText ">
                <span className="span3">
                  Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
                </span>
                <span className="span4">Max file size: 5MB</span>
              </div>
            </Upload.Dragger>
            <div className="uploadsContainer">
              {Array.isArray(otherReportedDocs) &&
                otherReportedDocs?.map((file) => (
                  <div className="uploadContent" key={file.uid}>
                    <img
                      className="uploadIcon"
                      src={uploadIcon}
                      alt={file.name}
                    />
                    <div>
                      <h1>{file.name}</h1>
                      {file.size && (
                        <p>
                          {(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB
                        </p>
                      )}
                    </div>
                    <img
                      onClick={() => handleDeleteOtherReport(file)}
                      src={RemoveIcon}
                      alt="RemoveIcon"
                    />
                  </div>
                ))}
              {existingFiles?.otherReportedDocs?.map((file) => (
                <div className="uploadContent" key={file?.fileDataId}>
                  <img
                    className="uploadIcon"
                    src={uploadIcon}
                    alt={file?.fileName}
                  />
                  <div>
                    <h1>{file?.fileName}</h1>
                  </div>
                  <img
                    onClick={() => handleDeleteExistingOtherReportingDocs(file)}
                    src={RemoveIcon}
                    alt="RemoveIcon"
                  />
                </div>
              ))}
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
export default Edit;
