import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import Flags from "./Flags";
import PostVisitCard from "./PostVisitCards/PostVisitCard";

const VALID_TABS = ["Flags", "PostVisits"];

function MainFlags() {
  const [activeComponent, setActiveComponent] = useState("Flags");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultActiveKey = () => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    return VALID_TABS.includes(activeKey) ? activeKey : "Flags";
  };

  useEffect(() => {
    const defaultTab = getDefaultActiveKey();
    setActiveComponent(defaultTab);
  }, [location.search]);

  const handleTabChange = (key) => {
    if (VALID_TABS.includes(key)) {
      navigate(`/project/flags?${key}`);
    } else {
      navigate("/project/flags");
    }
  };

  const renderComponent = () => {
    if (activeComponent === "Flags") {
      return (
        <div>
          <Flags />
        </div>
      );
    } else if (activeComponent === "PostVisits") {
      return (
        <div>
          <PostVisitCard></PostVisitCard>
        </div>
      );
    }
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner text={`${localStorage.getItem("projectRoundName")} - Flags`} />
      <div style={{ background: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "Flags"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="Flags"
                checked={activeComponent === "Flags"}
                onChange={() => handleTabChange("Flags")}
              />
              Red Flags Board
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "PostVisits"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="PostVisits"
                checked={activeComponent === "PostVisits"}
                onChange={() => handleTabChange("PostVisits")}
              />
              Post-Visit Card Board
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default MainFlags;
