import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  Button,
  Spin,
  Divider,
  Card,
} from "antd";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { taskTypes } from "../taskTypes";
import { DataContext } from "../DataContext";
import dayjs from "dayjs";
import IconStrategy from "../../../../../Assets/svg/task.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";

const { Option } = Select;

const EditTaskModal = ({ visible, taskId, setShowEditModal, setMessage }) => {
  const [form] = Form.useForm();
  const context = useContext(DataContext);

  const [taskCreators, setTaskCreators] = useState([]);
  const [taskCreatorsLoading, setTaskCreatorsLoading] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [team, setTeam] = useState(null);
  const [showEnumerators, setShowEnumerators] = useState(false);

  const onClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    setShowEditModal(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleCountryChange = (country) => {
    setCities([]);
    setSiteVisits([]);
    setTaskCreators([]);

    form.setFieldsValue({
      city: undefined,
      siteIds: undefined,
      assignedTo: undefined,
    });

    httpService.get(
      `/api/GeoData/cities?countryId=${country}`,
      (res) => setCities(res.data),
      (error) => console.log * error
    );

    const projectId = localStorage.getItem("currentProjectID");
    const countryData = locations.find((location) => location.id == country);

    httpService.get(
      `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryData?.stateName}&IgnorePagination=true`,
      (res) => setSiteVisits(res.data),
      (err) => console.log(err)
    );
  };

  const handleSubmit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();

      const payload = {
        id: taskId,
        name: values.taskName,
        deadline: values.deadline,
        taskLocation: {
          countryId: values.country,
          // cityId: values.city,
        },
        collectionMethod: values.collectionMethod,
        type: values.taskType,
        otherType: values.otherType,
        description: values.description,
        numberOfInterviews: values.numberOfInterviews,
        contactInformation: values.contactInformation,
        additionalNotes: values.additionalNotes,
        status: values.taskStatus,
        siteVisitId: values.siteIds,
      };

      httpService.put(
        "/api/Tasks/updateTask",
        payload,
        (response) => {
          const message =
            response.data.message ||
            "This task was correctly updated to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: IconStrategy,
            title: "Task updated successfully!",
            text: message,
          });
          handleLeave();
          fetchData();
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setButtonLoading(false);
        },
        (error) => {
          console.error("Failed to submit task:", error);
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem updating this task, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: IconStrategy,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setButtonLoading(false);
        }
      );
    } catch (errorInfo) {
      console.error("Form validation failed:", errorInfo);
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 3000);
      // Handle form validation errors if needed
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const fetchTeamBySiteVisit = async (siteVisitId) => {
    setTaskCreatorsLoading(true);
    setTeam(null);
    const queryString = `siteVisitIds[0]=${encodeURIComponent(siteVisitId)}`;

    httpService.get(
      `/api/Site/getTeamBySiteVisits?${queryString}`,
      (response) => {
        const teamOfSiteVisit = response.data[0];

        setTeam(teamOfSiteVisit);
      },
      (error) => {
        console.error("Failed to fetch team by site visit:", error);
        setTaskCreatorsLoading(false);
      }
    );
  };

  const fetchTaskData = async () => {
    try {
      if (taskId && visible) {
        // Set loading to true initially
        setLoading(true);

        // Create an array of promises to handle the async requests
        const promises = [];

        // Promise to fetch task data
        const taskPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Tasks/getTask?id=${taskId}`,
            (response) => {
              if (response && response.data) {
                const task = response.data;
                console.log("Task response:", task);

                // Set form fields with task data
                form.setFieldsValue({
                  taskIdentifier: task?.identifier,
                  taskName: task?.name,
                  deadline: dayjs(task.deadline),
                  country: task?.taskLocation?.country?.id,
                  city: task?.taskLocation?.city?.id,
                  siteIds: task?.taskSite?.id,
                  assignedTo: task?.taskUsers[0]?.userName,
                  district: task?.taskLocation?.district,
                  collectionMethod: task?.collectionMethod,
                  description: task?.description,
                  numberOfInterviews: task?.numberOfInterviews,
                  contactInformation: task?.contactInformation,
                  additionalNotes: task?.additionalNotes,
                  taskType: task?.type,
                  taskStatus: task?.status,
                });

                resolve(task); // Resolve the task data
              } else {
                reject(new Error("Task data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching task data: " + error.message));
            }
          );
        });

        promises.push(taskPromise);

        // Promise to fetch locations (countries)
        const locationsPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/GeoData/countries`,
            (res) => {
              if (res && res.data) {
                setLocations(res.data);
                resolve(res.data); // Resolve the locations data
              } else {
                reject(new Error("Countries data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching locations: " + error.message));
            }
          );
        });

        promises.push(locationsPromise);

        // Wait for both task and locations data to be fetched
        const [task, locations] = await Promise.all(promises);

        // Fetch cities based on the countryId
        const countryId = task?.taskLocation?.country?.id;

        // Get the projectId from localStorage
        const projectId = localStorage.getItem("currentProjectID");

        // Get the country name for the selected countryId
        const countryData = locations.find(
          (location) => location.id === countryId
        );
        const countryName = countryData?.stateName;

        // Fetch site visits based on the countryName
        const siteVisitsPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Site/getAllSiteVisitsByCountry?ProjectId=${projectId}&State=${countryName}`,
            (res) => {
              if (res && res.data) {
                setSiteVisits(res.data);
                resolve(res.data); // Resolve the site visits data
              } else {
                reject(new Error("Site visits data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching site visits: " + error.message));
            }
          );
        });

        promises.push(siteVisitsPromise);

        // Fetch team data based on the site visit ID
        const siteVisitId = task?.taskSite?.id;
        const queryString = `siteVisitIds[0]=${encodeURIComponent(
          siteVisitId
        )}`;

        const teamPromise = new Promise((resolve, reject) => {
          httpService.get(
            `/api/Site/getTeamBySiteVisits?${queryString}`,
            (res) => {
              if (res && res.data) {
                setTeam(res?.data[0]);
                resolve(res?.data[0]); // Resolve the team data
              } else {
                reject(new Error("Team data is undefined or empty"));
              }
            },
            (error) => {
              reject(new Error("Error fetching team data: " + error.message));
            }
          );
        });

        promises.push(teamPromise);

        // Wait for all promises to resolve before setting loading to false
        await Promise.all(promises);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false); // Set loading to false once all requests are done
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchTaskData();
  }, [taskId, form, visible]);

  if (!context) {
    return <></>;
  }
  const { fetchData } = context;

  return (
    <Modal
      closable={false}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Edit Task Card</h2>
        </>
      }
      open={visible}
      onCancel={onClose}
      width={800}
      footer={[
        <Button className="inviteButtonTPM" key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={buttonLoading}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item label="Task card identifier" name="taskIdentifier">
            <Input
              placeholder="xxxx - Task Name - Task Type - date created"
              disabled
            />
          </Form.Item>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Task name"
                name="taskName"
                rules={[
                  { required: true, message: "Please enter the task name" },
                ]}
              >
                <Input placeholder="Enter text here" />
              </Form.Item>
            </Col>
          </Row>

          <Col span={24}>
            <Form.Item
              label="Task Type"
              name="taskType"
              rules={[{ required: true, message: "Please select a task type" }]}
            >
              <Select placeholder="Select an option">
                {taskTypes.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Deadline"
                name="deadline"
                rules={[
                  {
                    required: true,
                    message: "Please select the deadline date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  // onChange={(date) => handleInputChange("deadline", date)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please select Country" }]}
              >
                <Select
                  placeholder="Select an option"
                  onChange={handleCountryChange}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {locations &&
                    locations.map((location) => (
                      <Option key={location?.id} value={location?.id}>
                        {location?.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="City"
                name="city"
                rules={[{ required: true, message: "Please select City" }]}
              >
                <Select
                  placeholder="Select a city"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={cities?.length < 1 ? true : false}
                >
                  {cities &&
                    cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col> */}
          </Row>

          <Col span={24}>
            <Form.Item
              label="Pick the site visit card"
              name="siteIds"
              rules={[
                { required: true, message: "Please input your site ID!" },
              ]}
            >
              <Select
                placeholder="Select a site visit"
                onChange={(value) => {
                  fetchTeamBySiteVisit(value);
                }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={siteVisits?.length < 1}
              >
                {siteVisits &&
                  siteVisits?.map((visit) => (
                    <Option key={visit.id} value={visit.id}>
                      {`${visit?.identifier} - ${visit?.ipPlan?.identifier}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {team != null && (
              <Form.Item
                className="mb-0"
                label="Assigned to (selected site visit team)"
                name="assignedTo"
              >
                <div className="form-control-plaintext">
                  <p
                    style={{
                      border: "2px solid #d9d9d9",
                      borderRadius: "10px",
                      padding: "8px 12px",
                      fontSize: "14px",
                      color: "#495057",
                    }}
                  >
                    <span>
                      {team != null || team || undefined
                        ? team?.name
                        : "Pick a site visit card to show the team"}
                    </span>
                  </p>

                  {team?.members && (
                    <button
                      style={{
                        marginTop: "0",
                        marginBottom: "15px",
                        border: "none",
                        backgroundColor: "transparent",
                        color: "var(--2, #AA1A5F)",
                        fontWeight: "500",
                      }}
                      onClick={() => setShowEnumerators(!showEnumerators)}
                    >
                      {showEnumerators
                        ? "Hide enumerators"
                        : "Show enumerators"}
                      <img
                        src={DownArrow}
                        style={
                          showEnumerators ? { transform: "rotate(180deg)" } : {}
                        }
                        alt="toggle"
                      />
                    </button>
                  )}
                </div>
              </Form.Item>
            )}
            {showEnumerators && (
              <div className="teamDetails mb-4">
                <Card>
                  {team?.members
                    ?.filter((en) => en?.roleName === "Field Enumerator")
                    ?.map((en) => (
                      <p key={en.id}>
                        {en?.firstName && en?.lastName
                          ? `${en?.firstName} ${en?.lastName} (${en?.username})`
                          : en?.username}
                      </p>
                    ))}
                </Card>
              </div>
            )}
            <Form.Item label="Collection method" name="collectionMethod">
              <Input
                placeholder="Enter the collection method"
                // onChange={(e) =>
                //   handleInputChange("collectionMethod", e.target.value)
                // }
              />
            </Form.Item>
          </Col>

          <Form.Item label="Task description" name="description">
            <Input.TextArea
              rows={3}
              placeholder="Enter the task description"
              // onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Number of interviews" name="numberOfInterviews">
            <InputNumber
              span={6}
              min={1}
              type="number"
              style={{ width: "100%" }}
              placeholder="Enter a number"
            />
          </Form.Item>
          <Form.Item label="Contact information" name="contactInformation">
            <Input
              placeholder="Enter the contact information"
              // onChange={(e) =>
              //   handleInputChange("contactInformation", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item label="Any additional notes" name="additionalNotes">
            <Input.TextArea
              rows={3}
              placeholder="Enter additional notes"
              // onChange={(e) =>
              //   handleInputChange("additionalNotes", e.target.value)
              // }
            />
          </Form.Item>
          <Form.Item
            label="Task status"
            name="taskStatus"
            rules={[
              { required: true, message: "Please select the task status" },
            ]}
          >
            <Select placeholder="Select the task status">
              <Option value={1}>Archived</Option>
              <Option value={2}>Completed</Option>
              <Option value={3}>On hold</Option>
              <Option value={4}>Accepted</Option>
              <Option value={5}>Assigned</Option>
            </Select>
          </Form.Item>
        </Form>
      </Spin>

      <Divider />
    </Modal>
  );
};

export default EditTaskModal;
