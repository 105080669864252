import React, { useState } from "react";
import { Layout, Menu, Dropdown, Button, Spin } from "antd";
import { Outlet, Link, useLocation } from "react-router-dom";
import "./MainLayout.css"; // Import the CSS file
import AdminHeader from "./components/AdminHeader/AdminHeader";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../Assets/svg/TPMlogo.svg";
import menu1 from "../Assets/svg/menuIcon1.svg";
// import menu3 from "../Assets/svg/menu3.svg";
import menu2 from "../Assets/svg/menuIcon2.svg";
import budgetsummarylogo from "../Assets/svg/budgetsummarylogo.svg";
import offices from "../Assets/svg/office.svg";
import icon7 from "../Assets/svg/iconMenu7.svg";
import SettingsButton from "../Assets/svg/settingsButton.svg";
import { useNavigate } from "react-router-dom";
import { RolesEnum } from "../services/rolesEnum";
import { isAuthorized } from "../services/authService";
import AuthService from "../services/authentication.service";

const { Header, Content, Sider } = Layout;

const MainLayout = ({
  notifications,
  setNotifications,
  unreadNotifications,
  setUnreadNotifications,
}) => {
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to toggle the small screen state based on screen size
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const handleLogout = async () => {
    AuthService.logout();
  };

  // Event listener for window resize
  window.addEventListener("resize", handleResize);

  // Initial check for small screen on component mount
  if (window.innerWidth < 768 && !isSmallScreen) {
    setIsSmallScreen(true);
  }

  // Dropdown menu for small screens
  const menu = (
    <Menu theme="dark" mode="vertical">
      {" "}
      {isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
      ]) && (
        <Menu.Item key="1">
          <Link
            to="/dashboard/user-management"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            User Management
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="2">
        <Link
          to="/dashboard/contracts"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Contracts
        </Link>
      </Menu.Item>
      {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
        <Menu.Item key="5">
          <Link
            to="/dashboard/offices"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Offices
          </Link>
        </Menu.Item>
      )}
      {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
        <Menu.Item key="3">
          <Link
            to="/dashboard/budget-summary"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Budget Summary
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="4">
        <Link
          to="/settings/budget-summary"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Account Settings
        </Link>
      </Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  return (
    <Spin spinning={loading}>
      <Layout style={{ minHeight: "100vh" }}>
        {isSmallScreen ? null : (
          <Sider className="sider">
            <div className="menuDesign">
              <img src={logo} style={{ marginBottom: "46px" }} alt="logo" />
            </div>
            <p className="siderText">Admin Management</p>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[location.pathname]}
              className="menu"
            >
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
              ]) && (
                <Menu.Item
                  key="1"
                  className={
                    location.pathname === "/dashboard/user-management"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/dashboard/user-management"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img
                      src={menu1}
                      style={{ marginRight: "12px" }}
                      alt="menu1"
                    />
                    User Management
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item
                key="2"
                className={
                  location.pathname === "/dashboard/contracts"
                    ? "costum-menu-item-active"
                    : ""
                }
              >
                <Link
                  to="/dashboard/contracts"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <img
                    src={menu2}
                    style={{ marginRight: "12px" }}
                    alt="menu2"
                  />
                  Contracts
                </Link>
              </Menu.Item>
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
              ]) && (
                <Menu.Item
                  key="3"
                  className={
                    location.pathname === "/dashboard/budget-summary"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/dashboard/budget-summary"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img
                      src={budgetsummarylogo}
                      style={{ marginRight: "12px" }}
                      alt="menu2"
                    />
                    Budget Summary
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
              ]) && (
                <Menu.Item
                  key="6"
                  className={
                    location.pathname === "/dashboard/offices"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/dashboard/offices"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img
                      src={offices}
                      style={{ marginRight: "12px" }}
                      alt="menu2"
                    />
                    Offices
                  </Link>
                </Menu.Item>
              )}
              <Menu.Item
                key="5"
                className={
                  location.pathname === "/settings/profile-information"
                    ? "costum-menu-item-active"
                    : ""
                }
              >
                <Link
                  to="/settings/profile-information"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <img
                    src={SettingsButton}
                    style={{ marginRight: "12px" }}
                    alt="menu1"
                  />
                  Account Settings
                </Link>
              </Menu.Item>
              <Menu.Item
                key="6"
                className={
                  location.pathname === "/login"
                    ? "costum-menu-item-active logoutMenu"
                    : "logoutMenu"
                }
              >
                <Link
                  to="/logout"
                  onClick={handleLogout}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {/* Assuming icon7 is correctly imported and available */}
                  <img
                    src={icon7}
                    style={{ marginRight: "12px" }}
                    alt="logout"
                  />
                  Logout
                </Link>
              </Menu.Item>
              {/* Add more menu items as needed */}
            </Menu>
          </Sider>
        )}
        <Layout className="site-layout">
          <Header className="adminHeader">
            <div className="leftContent">
              {isSmallScreen ? (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <MenuOutlined
                    style={{
                      fontSize: "24px",
                      marginBottom: "16px",
                      color: "#6A113C",
                    }}
                  />
                </Dropdown>
              ) : null}
            </div>
            <div className="rightContent">
              <AdminHeader
                loading={loading}
                setLoading={setLoading}
                notifications={notifications}
                setNotifications={setNotifications}
                unreadNotifications={unreadNotifications}
                setUnreadNotifications={setUnreadNotifications}
              />
            </div>
          </Header>
          <Content style={{ margin: "0px", backgroundColor: "white" }}>
            <Outlet style={{ width: "100% !important" }} />
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default MainLayout;
