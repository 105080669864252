import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Implementation.scss";
import VisitBoard from "./VisitBoard/VisitBoard";
import MonitoringRoundOverview from "./MonitoringRoundOverview/MonitoringRoundOverview";
import Banner from "../../components/Banner/Banner";
import MessagePopUp from "../../components/Messages/MessagePopUp";

const VALID_TABS = [
  "VisitBoard",
  "MonitoringRoundOverview",
  "FieldLogisticsBoard",
];

function Implementation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState("VisitBoard");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const getDefaultActiveKey = () => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    return VALID_TABS.includes(activeKey) ? activeKey : "VisitBoard";
  };

  useEffect(() => {
    const defaultTab = getDefaultActiveKey();
    setActiveComponent(defaultTab);
  }, [location.search]);

  const handleTabChange = (key) => {
    if (VALID_TABS.includes(key)) {
      navigate(`/project/implementation?${key}`);
    } else {
      navigate("/project/implementation");
    }
  };

  const renderComponent = () => {
    if (activeComponent === "VisitBoard") {
      return (
        <div id="excludedStrictMode">
          <VisitBoard />
        </div>
      );
    } else if (activeComponent === "MonitoringRoundOverview") {
      return (
        <div id="excludedStrictMode">
          <MonitoringRoundOverview />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="overflow-hidden">
        <Banner text={`${localStorage.getItem("projectRoundName")} - Implementation`} />
      </div>
      <div style={{ backgroundColor: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "VisitBoard"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="VisitBoard"
                checked={activeComponent === "VisitBoard"}
                onChange={() => handleTabChange("VisitBoard")}
              />
              Visit Board
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "MonitoringRoundOverview"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="MonitoringRoundOverview"
                checked={activeComponent === "MonitoringRoundOverview"}
                onChange={() => handleTabChange("MonitoringRoundOverview")}
              />
              Monitoring Round Overview
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default Implementation;
