import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableViewIpDonor from "./TableView/TableView";
import KanbanViewIpDonor from "./KanbanView/KanbanView";
import CalendarViewIpDonor from "./CalendarView/CalendarView";
import { httpService } from "../../../../services/httpService.service";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import debounce from "lodash/debounce";

function IpDonorStatusBoard() {
  const [data, setData] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [IPPlans, setIPPlans] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    ImplementingPartnerId: "",
    IPStatus: null,
    AssignedTo: "",
    Month: "",
    Year: "",
    Search: "",
  });

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };

  const fetchIpPlan = async (ignorePaginagtion) => {
    setLoading(true);
    const params = {
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      IPStatus: filters.IPStatus,
      AssignedTo: filters.AssignedTo,
      Month: filters.Month,
      Year: filters.Year,
      Search: filters.Search,
      IgnorePagination: ignorePaginagtion,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    await httpService.get(
      `/api/IPPlan/getAllIPPlans?${queryString}`,
      (response) => {
        setData(response.data);
        const ipPlansData = response.data.map((ipPlan) => ({
          id: ipPlan?.id,
          trackingId: ipPlan?.id,
          content: {
            title: ipPlan?.identifier,
            statusUpdated: formatDate(ipPlan?.updatedDate) || "Never updated",
            ip: ipPlan?.implementingPartner?.name,
            state:
              ipPlan?.office?.cities.length > 0
                ? ipPlan?.office?.cities[0]?.country?.stateName
                : ipPlan?.office?.states[0]?.country?.stateName,
            municipality:
              ipPlan?.office?.cities.length > 0
                ? ipPlan?.office?.cities[0]?.name
                : ipPlan?.office?.states[0]?.stateName,
          },
          column: ipPlan?.ipDonorReportingEnum,
        }));

        setIPPlans(ipPlansData);
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visits:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchIpPlan();
  }, [filters, pagination.pageSize, pagination.current]);

  const debouncedFilterChange = debounce((key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  }, 500);

  const handleFilterChange = (key, value) => {
    debouncedFilterChange(key, value);
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onChange = (key) => {
    if (key === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }

    if (key === "3") {
      fetchIpPlan(true);
    } else {
      fetchIpPlan(false);
    }
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableViewIpDonor
          data={data}
          fetchIpPlan={fetchIpPlan}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
          setDataForExcel={setDataForExcel}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanViewIpDonor
          handleFilterChange={handleFilterChange}
          setFilters={setFilters}
          fetchIpPlan={fetchIpPlan}
          IPPlans={IPPlans}
          setIPPlans={setIPPlans}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarViewIpDonor
          handleFilterChange={handleFilterChange}
          setFilters={setFilters}
          data={data}
          filters={filters}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <span className="tableViewHeaderText">IP/Donor Status Board</span>
        <div className="tableViewHeaderButtons">
          <Button
            className={`exportButtonTPM addButtonTPM ${
              removeExportButton ? "d-none" : ""
            }`}
            onClick={() => {
              if (dataForExcel.length !== 0) {
                exportToExcel(dataForExcel, "IP-planning");
              } else {
                setMessage({
                  trigger: true,
                  type: "danger",
                  icon: "",
                  title: "Oops! A problem has occurred!",
                  text: "Please select which IP plan's you want to export!",
                });
                setTimeout(() => {
                  setMessage({
                    trigger: false,
                    type: "",
                    icon: "",
                    title: "",
                    text: "",
                  });
                }, 3000);
              }
            }}
          >
            <img src={ExportButton} alt="" />
            Export selected cards
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default IpDonorStatusBoard;
