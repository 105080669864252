import React from "react";
import TableView from "./TableView/TableView";

function TeamCards({ teams, fetchTeams }) {
  return (
    <div className="tableViewcontainer visitBoardComponent p-5">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Enumerator Team Cards</span>
      </div>
      <TableView teams={teams} fetchTeams={fetchTeams} />
    </div>
  );
}

export default TeamCards;
