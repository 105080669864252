import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Card, Divider, Spin } from "antd";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import dayjs from "dayjs";
import { httpService } from "../../../../services/httpService.service";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";

const Preview = ({ visible, onClose, currentContractId }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: "",
    description: "",
    projectCode: "",
    clients: [{ name: "" }],
    contractNumber: "",
    contractDates: [],
    referenceDocuments: "",
    linkToSharedDrive: "",
    countryIds: [],
  });

  useEffect(() => {
    const fetchContract = async () => {
      setLoading(true);
      await httpService.get(
        `/api/Contract?id=${currentContractId?.id}`,
        (res) => {
          const contract = res.data;
          setInitialValues({
            description: contract.description,
            projectCode: contract.projectCode,
            contractNumber: contract.contractNumber,
            contractDates: [
              dayjs(contract.contractStartDate),
              dayjs(contract.contractEndDate),
            ],
            referenceDocuments: contract.referenceDocuments,
            linkToSharedDrive: contract.linkToSharedDrive,
            countryIds: contract.countries.map((country) => country.stateName),
            clients: contract.clients.map((client) => ({
              name: client.name,
              id: client.id,
            })),
          });
          setLoading(false);
        }
      );
    };

    if (currentContractId?.id) {
      fetchContract();
    }
  }, [visible, currentContractId]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );

    return formattedDate;
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width={800}
      closable={false}
    >
      <img
        src={cancelX}
        alt="BlackXIcon"
        onClick={onClose}
        style={{ float: "right", cursor: "pointer" }}
      />
      <h1 className="title-assessment-modal">Preview Contract</h1>
      <div className="admin-workspace">
        <div className="top-form">
          <div className="login-form login-signin" style={{ display: "block" }}>
            {loading ? (
              <Spin>
                <div className="content" />
              </Spin>
            ) : (
              <Formik initialValues={initialValues} enableReinitialize>
                {({ values }) => (
                  <FormikForm>
                    <div
                      style={{
                        fontStyle: "Poppins",
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#AA1A5F",
                      }}
                    >
                      Contract Details
                    </div>
                    <div>
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Project Round Code
                        </label>
                      </div>
                      <Form.Item>
                        <p
                          className="previewText"
                          style={{ marginLeft: "12px" }}
                        >
                          {values.projectCode}
                        </p>
                      </Form.Item>
                    </div>
                    <div>
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Contract Number
                        </label>
                      </div>
                      <Form.Item>
                        <p
                          className="previewText"
                          style={{ marginLeft: "12px" }}
                        >
                          {values.contractNumber}
                        </p>
                      </Form.Item>
                    </div>
                    <div>
                      <div className="label-name-assessment-modal">
                        <label style={{ width: "120px" }}>
                          <span>*</span>Country
                        </label>
                      </div>
                      <p className="previewText" style={{ marginLeft: "12px" }}>
                        {values.countryIds.join(", ")}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="w-100">
                        <div className="label-name-assessment-modal">
                          <label>
                            <span>*</span>Contract Dates
                          </label>
                        </div>
                        <Form.Item style={{ width: "100%" }}>
                          <p
                            className="previewText"
                            style={{ marginLeft: "12px" }}
                          >
                            {`${formatDate(values.contractDates[0])} - 
                            ${formatDate(values.contractDates[1])}`}
                          </p>
                        </Form.Item>
                      </div>
                    </div>
                    <FieldArray name="clients">
                      {() => (
                        <Card
                          style={{
                            marginBottom: "10px",
                            background: "#FFF5FA",
                            borderRadius: "8px",
                          }}
                        >
                          {values.clients.map((client, index) => (
                            <div key={index} style={{ marginBottom: "10px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "#AA1A5F",
                                  }}
                                >{`Client ${index + 1}`}</label>
                              </div>
                              <Form.Item>
                                <div className="label-name-assessment-modal">
                                  <label>
                                    <span>*</span>Client Name
                                  </label>
                                </div>
                                <p
                                  className="previewText"
                                  style={{ marginLeft: "12px" }}
                                >
                                  {client.name}
                                </p>
                              </Form.Item>
                              {index < values.clients.length - 1 && <Divider />}
                            </div>
                          ))}
                        </Card>
                      )}
                    </FieldArray>
                    {values.description && (
                      <div>
                        <div className="label-name-assessment-modal">
                          <label>Contract description</label>
                        </div>

                        <Form.Item>
                          <p className="previewText">{values.description}</p>
                        </Form.Item>
                      </div>
                    )}
                    {values.referenceDocuments && (
                      <div>
                        <div className="label-name-assessment-modal">
                          <label>Links to reference documents</label>
                        </div>

                        <Form.Item>
                          <p className="previewText">
                            {values.referenceDocuments}
                          </p>
                        </Form.Item>
                      </div>
                    )}

                    <div>
                      <div className="label-name-assessment-modal">
                        <label>
                          <span>*</span>Link to Shared Drive
                        </label>
                      </div>
                      <Form.Item>
                        <a
                          href={
                            values.linkToSharedDrive.startsWith("http")
                              ? values.linkToSharedDrive
                              : `http://${values.linkToSharedDrive}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="previewText"
                          style={{ marginLeft: "12px" }}
                        >
                          {values.linkToSharedDrive}
                        </a>
                      </Form.Item>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="footer-buttons">
                        <Button
                          className="cancel-workspace-button"
                          key="cancel"
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Preview;
