import React, { createContext, useState, useEffect } from "react";
import { httpService } from "../../../../services/httpService.service";
import { useTaskStore } from "./store/taskStore";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { fetchTaskss, fetchAllTasks } = useTaskStore();
  const [inputSearch, setInputSearch] = useState("");
  const [totalItemCount, setTotalItemCount] = useState(0);

  const [data, setData] = useState([]);
  const [draftsData, setDraftsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [draftsPagination, setDraftsPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    Search: "",
    Type: "",
    Status: "",
    CountryId: "",
    UserId: "",
  });

  const changeTaskStatus = (taskId, newStatus) => {
    setLoading(true);
    const params = {
      id: taskId,
      status: newStatus,
    };

    httpService.put(
      `/api/Tasks/updateTaskStatus`,
      params,
      (response) => {
        console.log("Response:", response);
        fetchData();
      },
      (error) => {
        console.error("Error:", error);
      }
    );
  };

  useEffect(() => {
    if (fetchTaskss) {
      fetchData(pagination.current, pagination.pageSize);
      fetchAllTasks(false);
    }
  }, [fetchTaskss]);

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    const projectId = localStorage.getItem("currentProjectID");
    const params = {
      ProjectId: projectId,
      Search: filters.Search,
      Type: filters.Type,
      Status: filters.Status,
      CountryId: filters.CountryId,
      UserId: filters.UserId,
      pageSize: pageSize,
      pageNumber: current,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const url = `/api/Tasks/getAllTasks?${queryString}`;

    httpService.get(
      url,
      (response) => {
        const result = response.data;
        setData(result);

        if (response.metaData) {
          const meta = response.metaData;
          setPagination((prevPagination) => ({
            ...prevPagination,
            total: meta.totalItemCount,
            current: meta.pageNumber,
            pageSize: meta.pageSize,
          }));
        } else {
          console.warn("No metaData found in response");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Options for formatting
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format; set to true for 12-hour format
    };

    // Format the date
    return date.toLocaleString("en-US", options);
  }

  const fetchDraftsData = async (current, pageSize) => {
    setLoading(true);
    const projectId = localStorage.getItem("currentProjectID");
    const params = {
      ProjectId: projectId,
      Type: 4,
      pageSize: pageSize,
      pageNumber: current,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
    const queryString = new URLSearchParams(filteredParams).toString();
    const url = `/api/Draft/getAll?${queryString}`;

    httpService.get(
      url,
      (response) => {
        const formattedData = response?.data?.map((data) => {
          const jsonData = JSON.parse(data?.jsonData);
          console.log(jsonData);
          return {
            key: data?.id,
            lastUpdated:
              formatDate(data?.updatedDate) || formatDate(data?.createdDate),
            deadline: formatDate(jsonData?.deadline),
            taskUsers:
              jsonData?.assignedTo?.firstName && jsonData?.assignedTo?.lastName
                ? `${jsonData?.assignedTo?.firstName} ${jsonData?.assignedTo?.lastName}`
                : jsonData?.assignedTo?.username,
            taskLocation: jsonData?.taskLocation || "N/A",
            type: jsonData?.type,
          };
        });
        console.log("formattedData:", formattedData);
        setDraftsData(formattedData);

        if (response.metaData) {
          const meta = response.metaData;

          setTotalItemCount(meta.totalItemCount);
          setDraftsPagination((prevPagination) => ({
            ...prevPagination,
            total: meta.totalItemCount,
            current: meta.pageNumber,
            pageSize: meta.pageSize,
          }));
        } else {
          console.warn("No metaData found in response");
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize, filters]);

  useEffect(() => {
    fetchDraftsData(draftsPagination.current, draftsPagination.pageSize);
  }, []);

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        fetchData,
        changeTaskStatus,
        filters,
        setFilters,
        pagination,
        setPagination,
        fetchDraftsData,
        draftsData,
        setDraftsData,
        draftsPagination,
        setDraftsPagination,
        inputSearch,
        setInputSearch,
        totalItemCount,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
