import React, { useState, useEffect } from "react";
import "../Implementation.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanView";
import TableView from "./TableView/TableView";
import TrackingModal from "../../planning/VisitBoard/Modals/AddTrackingCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

function VisitBoard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [siteVisitCardsUntracked, setSiteVisitCardsUntracked] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [paginationUntracked, setPaginationUntracked] = useState({
    pageSize: 10,
    current: 1,
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [calendarView, setCalendarView] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
    IgnorePagination: false,
  });

  const [filtersUntracked, setFiltersUntracked] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });

  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards();
    fetchSiteVisitCardsUntracked();
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const onChange = (key) => {
    if (key === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }
    if (key === "3") {
      setCalendarView(true);
    } else {
      setCalendarView(false);
    }
  };

  const fetchSiteVisitCards = async () => {
    setLoading(true);

    const params = {
      ProjectId: projectID,
      includeOnlyWithTracking: true,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      Search: filters.Search, // Ensure you're using filters.Search here
      ImplementingPartnerId: filters.ImplementingPartnerId, // Match the key
      State: filters.State, // State -> filters.State (correct reference)
      SiteVisitStatus: filters.SiteVisitStatus, // Ensure you're passing the correct value
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      IgnorePagination: calendarView,
    };

    // Clean up the params by removing undefined or null values
    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const siteVisitCardsData = response?.data;
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        if (siteVisitCardsData.length > 0) {
          setSiteVisitCards(siteVisitCardsData);
        } else {
          setSiteVisitCards([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const fetchSiteVisitCardsUntracked = async () => {
    setLoading(true);
    const params = {
      ProjectId: projectID,
      includeOnlyWithTracking: false,
      pageNumber: paginationUntracked.current,
      pageSize: paginationUntracked.pageSize,
      Search: filtersUntracked.Search,
      ImplementingPartnerId: filtersUntracked.ImplementingPartnerId,
      State: filtersUntracked.State,
      SiteVisitStatus: filtersUntracked.SiteVisitStatus,
      ClusterId: filtersUntracked.ClusterId,
      Month: filtersUntracked.Month,
      Year: filtersUntracked.Year,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        const siteVisitCardsData = response?.data;
        setPaginationUntracked({
          ...paginationUntracked,
          total: response.metaData.totalItemCount,
        });
        if (siteVisitCardsData.length > 0) {
          setSiteVisitCardsUntracked(siteVisitCardsData);
        } else {
          setSiteVisitCardsUntracked([]);
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onPageChangeUntracked = (pageNumber, pageSize) => {
    setPaginationUntracked({
      ...paginationUntracked,
      current: pageNumber,
      pageSize,
    });
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const handleFilterChangeUntracked = (key, value) => {
    setFiltersUntracked({
      ...filtersUntracked,
      [key]: value,
    });
    setPaginationUntracked({ ...paginationUntracked, current: 1 });
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <>
          <TableView
            onOk={() => handleOk()}
            tracked={false}
            siteVisitCards={siteVisitCardsUntracked}
            fetchSiteVisitCards={fetchSiteVisitCardsUntracked}
            loading={loading}
            setLoading={setLoading}
            setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
            onPageChange={onPageChangeUntracked}
            pagination={paginationUntracked}
            setPagination={setPaginationUntracked}
            handleFilterChange={handleFilterChangeUntracked}
            filters={filtersUntracked}
            setFilters={setFiltersUntracked}
          />

          <TableView
            onOk={() => handleOk()}
            tracked={true}
            siteVisitCards={siteVisitCards}
            fetchSiteVisitCards={fetchSiteVisitCards}
            loading={loading}
            setLoading={setLoading}
            setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
            onPageChange={onPageChange}
            pagination={pagination}
            setPagination={setPagination}
            handleFilterChange={handleFilterChange}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanView
          siteVisitCards={siteVisitCards}
          setSiteVisitCards={setSiteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <span className="tableViewHeaderText">Visits Board</span>
        <div className="tableViewHeaderButtons">
          {/* <Button
            className="inviteButtonTPM"
            onClick={showDeletedModal}
          >
            <img src={DeleteButton} alt="DeleteButton"/>
            See deleted cards
          </Button> */}
          <Button
            className={`exportButtonTPM addButtonTPM ${
              removeExportButton ? "d-none" : ""
            }`}
            onClick={() => {
              if (siteVisitCardsForExcel.length !== 0) {
                exportToExcel(siteVisitCardsForExcel, "SiteVisitCards");
              } else {
                setMessage({
                  trigger: true,
                  type: "danger",
                  icon: "",
                  title: "Oops! A problem has occurred!",
                  text: "Please select which site visits you want to export!",
                });
                setTimeout(() => {
                  setMessage({
                    trigger: false,
                    type: "",
                    icon: "",
                    title: "",
                    text: "",
                  });
                }, 3000);
              }
            }}
          >
            <img src={ExportButton} alt="ExportButton" />
            Export selected visits
          </Button>
          {/* <Button
            className="addButtonTPM" onClick={showModal}
          >
            <img src={AddButton} alt="AddButton"/>
            Add new site visit card
          </Button> */}
          <TrackingModal
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          />
          <DeletedModal
            visible={isDeletedModalVisible}
            onOk={showDeletedModal}
            onCancel={() => {
              setIsDeletedModalVisible(false);
            }}
            fetchSiteVisitCards={fetchSiteVisitCards}
          />
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default VisitBoard;
