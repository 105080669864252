import React, { useEffect, useState } from "react";
import "./IpSummary.scss";
import arrow1 from "../../../../Assets/Images/arrowRight.png";

const getPosponedStatus = (value) => (value ? "Yes" : "No");

const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  if (isNaN(dateObj)) {
    return "Invalid date";
  }
  const options = { year: "numeric", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObj
  );

  return formattedDate;
};

function SiteVisitCards({ projectData }) {
  const [selectedCardKeyInfo, setSelectedCardKeyInfo] = useState(null);
  const [flexBasis, setFlexBasis] = useState("50%");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1090px)");

    const handleResize = () => {
      setFlexBasis(mediaQuery.matches ? "100%" : "50%");
    };

    // Set initial value
    handleResize();

    // Add event listener
    mediaQuery.addEventListener("change", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  const handleCardClick = (keyInfo) => {
    setSelectedCardKeyInfo(keyInfo);
  };

  const cardsData = Array.isArray(projectData?.data?.ipSummarySiteVisitResponse)
    ? projectData.data.ipSummarySiteVisitResponse
    : [];

  return (
    <div style={{ width: "100%" }}>
      <div className="ipCardDiv">
        <h2 className="ipCardName">Site visit cards</h2>
        <div className="ipCards">
          {cardsData.map((card, index) => (
            <React.Fragment key={card?.svIdentifier}>
              <div className="ipCardCarousel" style={{ flexBasis: flexBasis }}>
                <div
                  className="cardIp"
                  onClick={() => handleCardClick(card.siteVisitKeyInfo)}
                >
                  <h6 className="ipCardHeadingName">{card?.svIdentifier}</h6>
                  <span className="ipCardTextName">{`Address: ${card?.address}`}</span>
                  <span className="ipCardTextName">{`Location: ${card?.location2}`}</span>
                  <span className="ipCardTextName">{`IP name: ${card?.ipName}`}</span>
                </div>
              </div>
            </React.Fragment>
          ))}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
            }}
          >
            <img src={arrow1} alt="arrow1" height={35} width={35} />
            <div className="ipCardList">
              {selectedCardKeyInfo ? (
                <>
                  <span className="ipCardTextName">
                    {`Planned Visit: `}{" "}
                    <span>{formatDate(selectedCardKeyInfo.plannedVisit)}</span>
                  </span>
                  <span className="ipCardTextName">
                    {`Postponed visit: `}{" "}
                    <span>
                      {getPosponedStatus(selectedCardKeyInfo?.posponedVisit)}
                    </span>
                  </span>
                </>
              ) : (
                <span className="ipCardTextName">
                  Select a card to see key info
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteVisitCards;
