import { Select, Button, Table, Spin, Input } from "antd";
import React, { useState, useEffect } from "react";
import "../../reporting/Reporting.scss";
import { httpService } from "../../../../services/httpService.service";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";
import editIcon from "../../../../Assets/svg/table-action-edit.svg";
import SearchIcon from "../../../../Assets/svg/searchIcon.svg";
import EditDataset from "./EditDataset";
import PreviewDataset from "./PreviewModal";
import previewIcon from "../../../../Assets/svg/table-action-preview.svg";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

let debounceTimeout = null;

function DatasetList() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [searchTerm, setSearchTerm] = useState("");
  const [showEditDatasetModal, setShowEditDatasetModal] = useState(false);
  const [showPreviewDatasetModal, setShowPreviewDatasetModal] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const projectId = localStorage.getItem("currentProjectID");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });

  const handleToggleEditDatasetModal = (record) => {
    setSelectedRowData(record);
    setShowEditDatasetModal(!showEditDatasetModal);
  };

  const handleTogglePreviewDatasetModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewDatasetModal(!showPreviewDatasetModal);
  };

  const handleAddEditDataset = async () => {
    try {
      console.log("Setting loading to true");
      setLoading(true);
      await fetchData(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error adding project:", error);
    } finally {
      console.log("Setting loading to false");
      // setLoading(false);
    }
  };

  const fetchData = () => {
    setLoading(true);
    const apiUrl = `/api/Site/getAllSiteVisits?projectId=${projectId}&pageSize=${
      pagination.pageSize
    }&pageNumber=${
      pagination.pageNumber
    }&SiteVisitStatus=6&IgnorePagination=true${
      searchTerm ? `&Search=${searchTerm}` : ""
    }`;

    httpService.get(
      apiUrl,
      (response) => {
        setData(response?.data || []);
        setPagination({
          ...pagination,
          total: response?.totalCount || 0,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.pageNumber, pagination.pageSize, searchTerm]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, pageNumber: page, pageSize });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setInputSearch(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      setSearchTerm(value.trim());
      setPagination({ ...pagination, pageNumber: 1 });
    }, 500);
  };

  const formatStatus = (status) => {
    const statusMap = {
      1: "Data Collection",
      2: "Post-data Collection",
      3: "Finalization",
      4: "Delivered",
      5: "IP Feedback",
    };
    return statusMap[status] || "No Status";
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };

  const formatData = (siteVisitCard) => ({
    key: siteVisitCard?.id,
    id: siteVisitCard?.id,
    siteVisitIdentifier: siteVisitCard?.identifier,
    actualDateOfVisit: formatDate(siteVisitCard?.tracking?.actualDateOfVisit),
    ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
    location: siteVisitCard?.locationDetails?.country,
    cluster: siteVisitCard?.cluster?.name,
    virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
    datasetList: siteVisitCard?.logistic?.fulcrumAccount,
  });

  const formattedData = data.map(formatData);

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Dataset List</span>,
      dataIndex: "datasetList",
      key: "datasetList",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldEnumerator,
            RolesEnum.Guest,
            RolesEnum.LimitedGuest,
          ]) && (
            <img
              src={previewIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleTogglePreviewDatasetModal(record)}
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleToggleEditDatasetModal(record)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Dataset List</span>
        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input
              placeholder="Search..."
              onChange={handleSearchChange}
              suffix={<img src={SearchIcon} alt="search icon" />}
              value={inputSearch}
            />
          </div>
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <Table
          dataSource={formattedData}
          columns={columns}
          loading={loading}
          pagination={{
            current: pagination.pageNumber,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: handlePageChange,
          }}
          className="tableTPM"
          scroll={{ x: "max-content" }}
        />
      </div>
      <EditDataset
        visible={showEditDatasetModal}
        onClose={handleToggleEditDatasetModal}
        record={selectedRowData}
        onSubmit={handleAddEditDataset}
        setMessage={setMessage}
      />
      <PreviewDataset
        visible={showPreviewDatasetModal}
        onClose={handleTogglePreviewDatasetModal}
        record={selectedRowData}
      />
    </>
  );
}

export default DatasetList;
