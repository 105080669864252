import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Input, message, Spin } from "antd";
import { httpService } from "../../../services/httpService.service";
import "./styles.scss";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import { number } from "yup";

const ConfirmationCode = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [confLoader, setConfLoader] = useState(false);
  const [roles, setRoles] = useState([]); // State for roles
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || localStorage.getItem("email");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  // Save email to local storage on initial render or when email is available
  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email, navigate]);

  const handleVerify = async () => {
    setLoading(true);

    httpService.post(
      "/api/Account/confirmCode",
      {
        email,
        code,
      },
      (response) => {
        const message = response.message;
        const {
          token,
          roles,
          lastLoggedInDate,
          fullName,
          id,
          profilePictureUrl,
        } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("profilePicture", profilePictureUrl);
        localStorage.setItem("username", fullName);
        localStorage.setItem("role", roles[0]?.roleName);
        localStorage.setItem("roleId", roles[0]?.id);
        localStorage.setItem("userID", id);

        setRoles(roles);

        setLoading(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Confirmation!",
          text: message || "Code verified!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);

        if (lastLoggedInDate === null) {
          navigate("/first-time");
        } else {
          navigate("/dashboard/contracts");
        }
      },
      (error) => {
        const errorMessage = error?.errors?.[0]?.message || "Invalid code!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
      }
    );
  };

  const handleResendCode = () => {
    setConfLoader(true);
    httpService.get(
      `/api/Account/resendCode?email=${email}`,
      () => {
        setConfLoader(false);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Confirmation!",
          text: "Verification code resent!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const errorMessage = error?.errors?.[0]?.message;

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage || "Failed to resend code.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setConfLoader(false);
      }
    );
  };

  const handleCodeChange = (value) => {
    const isAnumber = /^\d+$/.test(value);

    if (isAnumber) {
      if (value?.length < 7 || value?.length === 0) {
        setCode(value);
        console.log("Confirm code:", code);
      } else {
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "You can not add more than 6 characters!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      }
    } else {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "The confirmation code must have only numbers",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
  };

  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-md-6 left-column">
          <img
            src="/assets/svg/LoginImage.svg"
            alt="Login Visual"
            className="leftImage"
          />
        </div>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="col-md-6 right-column">
          <div className="form-wrapper1">
            <h2 className="verificationCodeHeader">Verification Code</h2>
            <p className="verificationCodeText">
              Please enter the verification code that we sent to {email}
            </p>
            <form className="w-100 d-flex flex-column align-items-center justify-content-center">
              <div className="OTP w-100 d-flex justify-content-center">
                <Input.OTP
                  type="text"
                  maxLength={6}
                  value={code}
                  onChange={handleCodeChange}
                  placeholder="Enter code"
                />
              </div>

              <div className="buttonsModal w-100 mt-4">
                <Button
                  className="addButtonTPM w-50"
                  key="submit"
                  type="primary"
                  onClick={handleVerify}
                  loading={loading}
                  disabled={loading}
                >
                  Verify code
                </Button>
              </div>
            </form>
            {/* Resend code link */}
            <p className="verificationCodeText">
              If you didn’t receive a verification code,{" "}
              <span
                style={{ color: "#E164A0", cursor: "pointer" }}
                onClick={handleResendCode}
              >
                click here to resend it
              </span>{" "}
              or send us an email at info@kaptechgroup.com
            </p>
            {confLoader ? <Spin style={{ width: "100%" }} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCode;
