import React, { useState, useEffect } from "react";
import KanbanBoardComponent from "../../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../TableView/Filters";
import { httpService } from "../../../../../../services/httpService.service";
import Preview from "../TableView/modals/Preview";
import { Pagination, Spin } from "antd";

const KanbanView = ({
  showAllTripData,
  setShowAllTripData,
  fetchTripLogistics,
  pagination,
  onPageChange,
  setPagination,
  filters,
  setFilters,
  loading,
  setLoading,
}) => {
  const [clickedTaskId, setClickedTaskId] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const projectID = localStorage.getItem("currentProjectID");

  const columns = [
    { id: 1, title: "Planned", color: "#AA1A5F" },
    { id: 2, title: "In progress", color: "#325899" },
    { id: 3, title: "Completed", color: "#FC9403" },
    { id: 4, title: "Canceled", color: "#3B81FE" },
  ];

  // useEffect(() => {
  //   setLoading(true);
  //   fetchTripLogistics(
  //     projectID,
  //     filters.startDate,
  //     filters.endDate,
  //     filters.status,
  //     filters.country,
  //     filters.city,
  //     filters.search,
  //     pagination.pageSize,
  //     pagination.current
  //   ).finally(() => setLoading(false));
  // }, [filters, pagination.current, pagination.pageSize]);

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPagination({ ...pagination, current: 1 });
  };

  const transformData = (data) => {
    return data.map((item) => ({
      id: item.id,
      content: {
        title: `${item.siteVisitCity}-${new Date(
          item.enumeratorInformation.tripDate
        ).toLocaleDateString()}`,
        statusUpdated: item.enumeratorInformation.tripDate,
        ip: "",
        state: item.siteVisitCountry,
        municipality: item.siteVisitCity,
      },
      column: item.tripStatus,
    }));
  };

  const changeStatus = async (task) => {
    const { id, column } = task;

    setLoading(true);

    const updatedSiteVisitCards = showAllTripData.map((card) => {
      if (card.id === id) {
        return {
          ...card,
          tripStatus: column, // Update the tripStatus based on the column value
        };
      }
      return card;
    });

    setShowAllTripData(updatedSiteVisitCards);

    httpService.post(
      "/api/TripLogistic/changeTripLogisticStatus",
      {
        tripLogisticId: id,
        tripStatus: column,
      },
      () => {
        fetchTripLogistics(false);
        setLoading(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem changing status of this trip logistic in your database, please try again!`;
        setLoading(false);
        console.log(messageError);
      }
    );
  };

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  return (
    <div>
      <Spin spinning={loading} size="medium">
        <Filters
          onChange={handleFiltersChange}
          setFilters={setFilters}
          pagination={pagination}
          setPagination={setPagination}
          fetchTripLogistics={fetchTripLogistics}
          projectID={projectID}
        />
        <div id="excludedStrictMode">
          <Preview
            visible={showPreviewModal}
            onClose={handleTogglePreviewModal}
            record={clickedTaskId}
          />
          <KanbanBoardComponent
            tasks={transformData(showAllTripData)}
            columns={columns}
            changeStatus={changeStatus}
            setClickedTaskId={handleTaskClick}
          />
          <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default KanbanView;
