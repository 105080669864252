import {
  Modal,
  Form,
  Input,
  Button,
  Divider,
  Typography,
  Spin,
  Tooltip,
} from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import moment from "moment";
import { useSiteVisitStore } from "./store/siteVisitStore";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import dayjs from "dayjs";
import axios from "axios";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";

const { TextArea } = Input;

const { Title } = Typography;

const EditDraftVisits = ({
  visible,
  onCancel,
  onOk,
  siteVisitCardId,
  setMessage,
}) => {
  const [form] = Form.useForm();
  const [siteContactsRequired, setSiteContactsRequired] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [clusters, setClusters] = useState([]);
  const [singleTeam, setSingleTeam] = useState({});
  const [allIPPlans, setAllIPPlans] = useState([]);
  const [postponed, setPostponed] = useState(false);
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [photosLimit, setPhotosLimit] = useState(0);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [videosLimit, setVideosLimit] = useState(0);
  const [observationList, setObservationList] = useState(false);
  const [selectedIpPlan, setSelectedIpPlan] = useState([]);
  const [showHideIpPlanDetails, setShowHideIpPlanDetails] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [existingFiles, setExistingFiles] = useState({});
  const [parishCheck, setParishCheck] = useState(false);
  const { setSiteVisitList, setOtherDocuments, setSiteVideos, siteVideos } =
    useSiteVisitStore();

  const [SiteLoader, setSiteLoader] = useState(false);
  const [kills, setKills] = useState(1);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onCancel();
    form.resetFields();
    setSiteVisitList([]);
    setOtherDocuments([]);
    setSiteVideos([]);
    setSingleTeam(null);
    setRequiredPhotos(false);
    setPhotosLimit(0);
    setRequiredVideos(false);
    setVideosLimit(0);
    setObservationList(false);
    setKills(1);
    setClusters([]);
    setSectors([]);
    setSelectedIpPlan("");
    setShowHideIpPlanDetails(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  useEffect(() => {
    if (visible && siteVisitCardId && form) {
      setSiteLoader(true);
      httpService.get(
        `/api/Draft/getDraftById?id=${siteVisitCardId}`,
        (res) => {
          setSiteLoader(false);

          const siteVisitCard = JSON.parse(res?.data?.jsonData);
          console.log("siteVisitCard", siteVisitCard);

          const KIIContacts = siteVisitCard?.Visit?.KIIcontacts || [];
          const pointContacts = siteVisitCard?.Visit?.pointContacts[0] || [];
          const siteContacts = siteVisitCard?.Visit?.siteContacts[0] || null;

          const newSiteContactsRequired = siteContacts !== null;
          const parishChecked =
            siteVisitCard?.parish === "false" ? false : true;
          setParishCheck(parishChecked);
          const initialData = {
            name: siteVisitCard.name,
            IPlaningCard: siteVisitCard?.IPPlan?.[0]?.id || null,
            cluster: siteVisitCard?.Cluster?.[0]?.id || null,
            sector: siteVisitCard?.SectorId || null,
            subSector: siteVisitCard?.SubSectorId || null,
            projectId: projectId,
            latitude: siteVisitCard?.LocationDetails?.Latitude || null,
            longitude: siteVisitCard?.LocationDetails?.Longitude || null,
            isVirtual: siteVisitCard?.LocationDetails?.isVirtual || null,
            dateOfPlannedVisit: dayjs(siteVisitCard?.plannedVisit || undefined),

            parishCheck: parishChecked,
            parish:
              siteVisitCard?.parish !== "false"
                ? siteVisitCard?.parish
                : undefined,
            observationChecklistType:
              siteVisitCard?.Visit?.observationChecklistType,
            ObservationListCheck:
              siteVisitCard?.Visit?.ObservationListCheck || false,
            beneficiariesCount:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.BeneficiariesCount || 0,
            kills: KIIContacts?.length || 0,
            observationChecklistCount:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.ObservationChecklist || 0,
            // mediaLimit:
            //   siteVisitCard?.Visit?.CreateInterviewDetails?.MediaLimit || 0,
            samplingInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.SamplingInstructions || "",
            clientInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails
                ?.ClientInstructions || "",
            otherInstructions:
              siteVisitCard?.Visit?.CreateInterviewDetails?.OtherInstructions ||
              "",
            visitPlanApproval:
              siteVisitCard?.Visit?.VisitPlanApproval || undefined,

            specificsOfOnSiteOperation:
              siteVisitCard?.SpecificsOfOnSiteOperation || "",

            tpmCoordinator: siteVisitCard?.Logistic?.TPMCoordinatorId || null,
            coordinationType:
              siteVisitCard?.Logistic?.CoordinationType || undefined,
            fulcrumAccount: siteVisitCard?.Logistic?.FulcrumAccount || "",
            activationDate: siteVisitCard?.Logistic?.ActivationDate || null,
            isTeamLocal: siteVisitCard?.Logistic?.IsTeamLocal || false,

            team: siteVisitCard?.Logistic?.TeamId || null,
            isPostponed: siteVisitCard?.Logistic?.IsPostponed || false,
            postponedReason: siteVisitCard?.Logistic?.PostponedReason || "",
            postponedDetails: siteVisitCard?.Logistic?.PostponedDetails || "",
            notes: siteVisitCard?.Logistic?.Notes || "",

            KIIcontacts: KIIContacts,
            pointContacts: pointContacts,
            siteContacts: siteContacts,

            photosRequired: siteVisitCard?.HasPhotos,
            videosRequired: siteVisitCard?.HasVideos,
            videosLimit: siteVisitCard?.VideoCount || 0,
            photosLimit: siteVisitCard?.PhotoCount || 0,
            siteContactsRequired: newSiteContactsRequired,
            // report_documents: siteVisitCard?.files?.report_documents || [],
            // other_files: siteVisitCard?.files?.other_files || [],
            // video_files: siteVisitCard?.files?.video_files || [],
          };

          setKills(KIIContacts?.length);
          setObservationList(
            siteVisitCard?.Visit?.ObservationListCheck || false
          );
          setSiteContactsRequired(newSiteContactsRequired);
          setRequiredPhotos(siteVisitCard?.HasPhotos);
          setPhotosLimit(siteVisitCard?.PhotoCount);
          setRequiredVideos(siteVisitCard?.HasVideos);
          setVideosLimit(siteVisitCard?.VideoCount);
          setPostponed(siteVisitCard?.Logistic?.IsPostponed);
          setSelectedSectorId(siteVisitCard?.SectorId);

          const teamId = siteVisitCard?.Logistic?.TeamId;

          if (teamId) {
            httpService.get(
              `/api/User/getTeamById?teamId=${teamId}`,
              (res) => {
                setSingleTeam(res?.data);
              },
              (err) => {
                console.log(err);
              }
            );
          }
          let report_documents = [];
          let other_files = [];
          let video_files = [];
          if (
            siteVisitCard?.files?.report_documents ||
            siteVisitCard?.files?.other_files ||
            siteVisitCard?.files?.video_files
          ) {
            report_documents = siteVisitCard?.files?.report_documents;
            other_files = siteVisitCard?.files?.other_files;
            video_files = siteVisitCard?.files?.video_files;
          } else {
            siteVisitCard?.files?.forEach((file) => {
              console.log(file?.entityId);
              if (file.entityId.endsWith("11111")) {
                report_documents.push(file);
              } else if (file.entityId.endsWith("22222")) {
                other_files.push(file);
              } else if (
                file.entityId.endsWith("33333") ||
                file.entityId.endsWith("44444")
              ) {
                video_files.push(file);
              }
            });
          }

          setExistingFiles({ report_documents, other_files, video_files });

          setInitialValues(initialData);
          form.setFieldsValue(initialData);
        },
        (err) => {
          setSiteLoader(false);
          console.log(err);
        }
      );
    }
  }, [visible]);

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = moment(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const postJsonData = (values, filesData) => {
    const files = existingFiles;
    if (filesData) {
      filesData?.forEach((file) => {
        if (file?.entityId.endsWith("11111")) {
          files?.report_documents.push(file);
        } else if (file?.entityId.endsWith("22222")) {
          files?.other_files.push(file);
        } else if (
          file?.entityId.endsWith("33333") ||
          file.entityId.endsWith("44444")
        ) {
          files?.video_files.push(file);
        }
      });
    }
    const selectedCluster = clusters?.filter(
      (cluster) => cluster.id === values.cluster
    );

    const selectedIpPlan = allIPPlans.filter(
      (ipPlan) => ipPlan.id === values.IPlaningCard
    );

    let siteContacts = [values.siteContacts] || [];
    let pointContacts = [values.pointContacts] || [];
    let KIIcontacts = values.KIIcontacts || [];

    siteContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 1;
      }
    });
    pointContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 2;
      }
    });
    KIIcontacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 3;
      }
    });

    setKills(KIIcontacts?.length);

    const siteVisitDraft = {
      ProjectId: projectId,
      name: values.name,
      plannedVisit: formatDate(values.dateOfPlannedVisit),
      trainingDate: formatDate(values.trainingDate),
      actualDateOfVisit: formatDate(values.actualDateOfVisit),
      activationDate: formatDate(values.dateOfPlannedVisit, "activation"),
      dateOfReport: formatDate(values.dateOfReport),
      dateOfDebrief: formatDate(values.dateOfDebrief),
      IPPlan: selectedIpPlan,
      Cluster: selectedCluster,
      SectorId: values.sector,
      SubSectorId: values.subSector,
      LocationDetails: {
        Latitude: values.latitude,
        Longitude: values.longitude,
        isVirtual: values.isVirtual || false,
      },
      parish: values?.parish || "false",
      Visit: {
        PlannedVisit: values.plannedVisit,
        siteContacts: siteContacts,
        pointContacts: pointContacts,
        KIIcontacts: KIIcontacts,
        ObservationListCheck: values.ObservationListCheck,
        observationChecklistType: values.observationChecklistType,
        CreateInterviewDetails: {
          BeneficiariesCount: values.beneficiariesCount,
          ClientsCount: values.ClientsCount,
          ObservationChecklist: values.observationChecklistCount,
          MediaLimit: values.MediaLimit,
          SamplingInstructions: values.samplingInstructions,
          ClientInstructions: values.clientInstructions,
          OtherInstructions: values.otherInstructions,
        },
        VisitPlanApproval: values.visitPlanApproval,
      },
      SpecificsOfOnSiteOperation: values.specificsOfOnSiteOperation,
      ObservationList: values.observationList,
      Logistic: {
        TPMCoordinatorId: values.tpmCoordinator,
        CoordinationType: values.coordinationType,
        FulcrumAccount: values.fulcrumAccount,
        ActivationDate: values.activationDate,
        IsTeamLocal: values.isTeamLocal,
        TeamId: values.team,
        IsPostponed: values.isPostponed,
        PostponedReason: values.postponedReason,
        PostponedDetails: values.postponedDetails,
        Notes: values.notes,
      },
      ...(files && {
        files: files,
      }),

      HasVideos: values?.videosRequired,
      VideoCount: values?.videosLimit,

      HasPhotos: values?.photosRequired,
      PhotoCount: values?.photosLimit,
    };

    const jsonData = JSON.stringify(siteVisitDraft);

    const params = {
      id: siteVisitCardId,
      projectId: projectId,
      jsonData: jsonData,
      type: 1,
    };

    httpService.post(
      "/api/Draft/createOrEditDraft",
      params,
      (res) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Draft site visit card created successfully!",
          text: "A new draft site visit card has been created and saved into the database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSubmitLoader(false);
        form.resetFields();

        onOk();
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to create draft site visit. Please try again later.";
        console.error("There was an error adding the project!", error);
        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleSaveProgress = async () => {
    setSubmitLoader(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value?.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this site visit card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 1000);
      setSubmitLoader(false);
      return;
    }

    const reportedDocuments = values.report_documents;
    const otherFiles = values.other_files;
    const videoFiles = values.video_files;

    if (
      reportedDocuments?.length > 0 ||
      otherFiles?.length > 0 ||
      videoFiles?.length > 0
    ) {
      const formData = new FormData();

      formData.append("FolderName", "TPM");
      formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

      reportedDocuments?.forEach((file, index) => {
        formData.append(
          `entityFormFileDtos[${index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f611111"
        );
        formData.append(
          `entityFormFileDtos[${index}].formFile`,
          file.originFileObj
        );
      });

      otherFiles?.forEach((file, index) => {
        const filesLength = reportedDocuments?.length;
        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f622222"
        );
        formData.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      const videosUploaded = [];
      const imagesUploaded = [];
      videoFiles?.forEach((file) => {
        if (file.type.startsWith("image")) {
          imagesUploaded.push(file);
        } else if (file.type.startsWith("video")) {
          videosUploaded.push(file);
        }
      });
      videosUploaded?.forEach((file, index) => {
        const filesLength =
          (reportedDocuments?.length || 0) + (otherFiles?.length || 0);

        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f633333"
        );
        formData.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      imagesUploaded?.forEach((file, index) => {
        const filesLength =
          videosUploaded?.length +
          (reportedDocuments?.length || 0) +
          (otherFiles?.length || 0);

        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f644444"
        );
        formData.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      axios
        .post(
          "https://kapfilemanager.kapsurvey.com/FileData/addFile",
          formData,
          {
            headers: {
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          const filesData = response.data.map((fileData) => fileData);
          postJsonData(values, filesData);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    } else {
      postJsonData(values);
    }
  };

  const handleDelete = async () => {
    if (siteVisitCardId) {
      const requestBody = [
        {
          id: siteVisitCardId,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  const handleSubmit = () => {
    setSubmitLoader(true);

    form
      .validateFields()
      .then((values) => {
        const plannedVisit = `${formatDate(values.dateOfPlannedVisit)}`;
        const trainingDate = formatDate(values.trainingDate);
        const actualDateOfVisit = formatDate(values.actualDateOfVisit);
        const activationDate = formatDate(
          values.dateOfPlannedVisit,
          "activation"
        );
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        let siteContacts = values.siteContacts ? [values.siteContacts] : [];
        let pointContacts = values.pointContacts ? [values.pointContacts] : [];
        let KIIcontacts = Array.isArray(values.KIIcontacts)
          ? values.KIIcontacts
          : [];

        siteContacts?.map((contact) => (contact.contactType = 1));
        pointContacts?.map((contact) => (contact.contactType = 2));
        KIIcontacts?.map((contact) => (contact.contactType = 3));

        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];

        const formData = new FormData();
        formData.append("Name", values.name);
        formData.append("IPPlanId", values.IPlaningCard);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector || "");

        formData.append("Perish", values.parish || false);
        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude);
        formData.append("LocationDetails.Longitude", values.longitude);
        formData.append("isVirtual", values.isVirtual || false);
        formData.append("Visit.PlannedVisit", plannedVisit);

        createContacts?.forEach((contact, index) => {
          formData.append(
            `visit.createContacts[${index}].name`,
            contact.contactName
          );
          formData.append(
            `visit.createContacts[${index}].email`,
            contact.email
          );
          formData.append(`visit.createContacts[${index}].role`, contact.role);
          formData.append(
            `visit.createContacts[${index}].phone`,
            contact.phone || ""
          );
          formData.append(
            `visit.createContacts[${index}].skype`,
            contact.skype || ""
          );
          formData.append(
            `visit.createContacts[${index}].organization`,
            contact.organization || ""
          );
          formData.append(
            `visit.createContacts[${index}].contactType`,
            contact.contactType
          );
        });

        formData.append(
          "ObservationList",
          values.ObservationListCheck || false
        );
        formData.append(
          "Visit.CreateInterviewDetails.ObservationChecklist",
          values.observationChecklistCount || 0
        );
        formData.append(
          "Visit.CreateInterviewDetails.ObservationChecklistType",
          values.observationChecklistType || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.BeneficiariesCount",
          values.beneficiariesCount || 0
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientsCount",
          values.kills || 0
        );
        formData.append(
          "Visit.CreateInterviewDetails.MediaLimit",
          values.mediaLimit
        );
        formData.append(
          "Visit.CreateInterviewDetails.SamplingInstructions",
          values.samplingInstructions || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientInstructions",
          values.clientInstructions || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.OtherInstructions",
          values.otherInstructions || ""
        );
        formData.append(
          "Visit.VisitPlanApproval",
          values.visitPlanApproval || ""
        );

        formData.append(
          "SpecificsOfOnSiteOperation",
          values.specificsOfOnSiteOperation
        );
        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount || "");
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed || false);
        formData.append(
          "Logistic.PostponedReason",
          values.postponedReason || ""
        );
        formData.append(
          "Logistic.PostponedDetails",
          values.postponedDetails || ""
        );
        formData.append("Logistic.Notes", values.notes || "");

        formData.append("Tracking.ReportingYear", values.reportingYear);
        formData.append("Tracking.ReportingMonth", values.reportingMonth);
        formData.append("Tracking.TrainingDate", trainingDate);
        formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
        formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
        formData.append("Tracking.DateOfReport", dateOfReport);
        formData.append("Tracking.DateOfDebrief", dateOfDebrief);
        formData.append(
          "Tracking.PercentageOfDataCollectionCompleted",
          values.percentageOfDataCollectionCompleted
        );
        formData.append("Tracking.SiteVisitStatus", 1);
        formData.append("Tracking.FieldTeamReportingStatus", 1);
        formData.append(
          "Tracking.TrainingIsCompleted",
          values.trainingIsCompleted || false
        );

        formData.append("HasVideos", values.videosRequired || false);
        formData.append("VideoCount", values.videosLimit || 0);
        formData.append("HasPhotos", values.photosRequired || false);
        formData.append("PhotoCount", values.photosLimit || 0);

        const reportDocs = values.report_documents;
        const otherFiles = values.other_files;
        const videoFiles = values.video_files;

        existingFiles?.report_documents?.forEach((file, index) => {
          formData.append(`Files[${index}].FileCategory`, 1);
          formData.append(`Files[${index}].EntityId`, file.entityId);
          formData.append(`Files[${index}].FileKey`, file.fileKey);
          formData.append(`Files[${index}].Id`, file.entityId);
        });

        existingFiles?.other_files?.forEach((file, index) => {
          const length = existingFiles?.report_documents?.length;
          formData.append(`Files[${length + index}].FileCategory`, 2);
          formData.append(`Files[${length + index}].Id`, file.entityId);
          formData.append(`Files[${length + index}].EntityId`, file.entityId);
          formData.append(`Files[${length + index}].FileKey`, file.fileKey);
        });

        const existingVides = [];
        const existingImages = [];

        existingFiles?.video_files?.forEach((file) => {
          if (file.entityId.endsWith("33333")) {
            existingVides.push(file);
          } else if (file.entityId.endsWith("44444")) {
            existingImages.push(file);
          }
        });

        const existingDocsLength =
          (existingFiles?.report_documents?.length || 0) +
          (existingFiles?.other_files?.length || 0);

        existingVides?.forEach((file, index) => {
          formData.append(
            `Files[${existingDocsLength + index}].FileCategory`,
            3
          );
          formData.append(
            `Files[${existingDocsLength + index}].EntityId`,
            file.entityId
          );
          formData.append(
            `Files[${existingDocsLength + index}].Id`,
            file.entityId
          );
          formData.append(
            `Files[${existingDocsLength + index}].FileKey`,
            file.fileKey
          );
        });

        existingImages?.forEach((file, index) => {
          formData.append(
            `Files[${
              existingDocsLength + existingVides?.length + index
            }].FileCategory`,
            4
          );
          formData.append(
            `Files[${
              existingDocsLength + existingVides?.length + index
            }].EntityId`,
            file.entityId
          );
          formData.append(
            `Files[${existingDocsLength + existingVides?.length + index}].Id`,
            file.entityId
          );
          formData.append(
            `Files[${
              existingDocsLength + existingVides?.length + index
            }].FileKey`,
            file.fileKey
          );
        });

        const existingFilesLength =
          existingDocsLength + existingVides?.length + existingImages?.length;

        reportDocs?.forEach((file, index) => {
          const newIndex = existingFilesLength + index;
          formData.append(`Files[${newIndex}].FileCategory`, 1);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        const otherFilesLength = otherFiles?.length || 0;
        otherFiles?.forEach((file, index) => {
          const newIndex = existingFilesLength + reportDocs.length + index;
          formData.append(`Files[${newIndex}].FileCategory`, 2);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        const videosUploaded = [];
        const imagesUploaded = [];
        videoFiles?.forEach((file) => {
          if (file.type.startsWith("image")) {
            imagesUploaded.push(file);
          } else if (file.type.startsWith("video")) {
            videosUploaded.push(file);
          }
        });

        const allFilesLength =
          existingFilesLength + (reportDocs?.length || 0) + otherFilesLength;

        videosUploaded?.forEach((file, index) => {
          const newIndex = allFilesLength + index;
          formData.append(`Files[${newIndex}].FileCategory`, 3);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        imagesUploaded?.forEach((file, index) => {
          const newIndex = allFilesLength + videosUploaded?.length + index;
          formData.append(`Files[${newIndex}].FileCategory`, 4);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        httpService.post(
          "/api/Site/createSiteVisitCard",
          formData,
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: "Site vist card created succesfully!",
              text: "A new site visit card has been created and saved into the database!",
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setSubmitLoader(false);
            form.resetFields();

            handleDelete();
            setTimeout(() => {
              onOk();
            }, 1000);
          },

          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "Failed to create site visit. Please try again later.";
            console.error("There was an error adding the project!", error);
            setSubmitLoader(false);
            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Site visit card updated successfully!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={handleCancel}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Site visit card</h2>
        </>
      }
      closable={false}
      open={visible}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button
          key="back"
          onClick={handleSaveProgress}
          loading={submitLoader}
          className="inviteButtonTPM"
        >
          Save Progress
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          className="addButtonTPM"
          loading={submitLoader}
        >
          Add Site Visit Card
        </Button>,
      ]}
    >
      <Spin spinning={SiteLoader}>
        <div className="draftHeaderText">
          <p>
            This is a draft of the Site visit card. Once you finish editing,
            click 'Add site visit card' to add it as a new Site visit card, or
            save your progress.
          </p>
        </div>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item label="Site visit card identifier" name="identifier">
            <div className="form-control-plaintext">
              <p
                style={{
                  border: "2px solid #d9d9d9",
                  borderRadius: "10px",
                  padding: "8px 12px",
                  fontSize: "14px",
                  color: "#495057",
                }}
              >
                <Tooltip title="This number will be generated automatically!">
                  <span>00X - </span>
                </Tooltip>
                <Tooltip title="Selected Implementing Partner!">
                  <span>
                    {selectedIpPlan?.implementingPartner?.name !== undefined
                      ? selectedIpPlan?.implementingPartner?.name
                      : "Implementing Partner"}
                  </span>
                </Tooltip>
                <Tooltip title="Selected location city!">
                  <span> - Location </span>
                </Tooltip>
                <Tooltip title="Created date!">
                  <span>{`- ${formattedDate}`}</span>
                </Tooltip>
              </p>
            </div>
          </Form.Item>
          <GeneralDetails
            form={form}
            selectedSectorId={selectedSectorId}
            setSelectedSectorId={setSelectedSectorId}
            clusters={clusters}
            setClusters={setClusters}
            allIPPlans={allIPPlans}
            setAllIPPlans={setAllIPPlans}
            selectedIpPlan={selectedIpPlan}
            setSelectedIpPlan={setSelectedIpPlan}
            sectors={sectors}
            setSectors={setSectors}
          />
          <Divider />
          <h3>Location details</h3>
          <LocationDetails
            form={form}
            isEditing={true}
            onClose={onCancel}
            parishCheck={parishCheck}
            setParishCheck={setParishCheck}
            showHideIpPlanDetails={showHideIpPlanDetails}
            setShowHideIpPlanDetails={setShowHideIpPlanDetails}
            selectedIpPlan={selectedIpPlan}
            setSelectedIpPlan={setSelectedIpPlan}
          />

          <Divider />
          <h3>Visit details</h3>

          <VisitDetails
            form={form}
            kills={kills}
            setKills={setKills}
            requiredPhotos={requiredPhotos}
            setRequiredPhotos={setRequiredPhotos}
            requiredVideos={requiredVideos}
            setRequiredVideos={setRequiredVideos}
            siteContactsRequired={siteContactsRequired}
            setSiteContactsRequired={setSiteContactsRequired}
            setVideosLimit={setVideosLimit}
            videosLimit={videosLimit}
            photosLimit={photosLimit}
            setPhotosLimit={setPhotosLimit}
            observationList={observationList}
            setObservationList={setObservationList}
          />

          <Divider />
          <h3>Logistics</h3>

          <Logistics />

          <Divider />
          <h3>Team</h3>
          <TeamDetails singleTeam={singleTeam} setSingleTeam={setSingleTeam} />

          <Divider />
          <h3>Postponement</h3>
          <Postponement postponed={postponed} setPostponed={setPostponed} />

          <Divider />
          <h3>Resources</h3>

          <ResourcesDetails
            form={form}
            existingFiles={existingFiles}
            setExistingFiles={setExistingFiles}
            requiredPhotos={requiredPhotos}
            photosLimit={photosLimit}
            requiredVideos={requiredVideos}
            videosLimit={videosLimit}
            setMessage={setMessage}
          />

          <Divider />
        </Form>
      </Spin>
    </Modal>
  );
};
export default EditDraftVisits;
