import "./App.scss";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Login from "./app/pages/auth/Login";
import ProtectedRoute from "./app/pages/auth/ProtectedRoute";
import Projects from "./app/pages/projects/Projects";
import ProjectLayout from "./app/ProjectLayout";
import Dashboard from "./app/pages/dashboard/Dashboard";
import Planning from "./app/pages/planning/Planning";
import Flags from "./app/pages/Flags/Flags";
import Completed from "./app/pages/completed/Completed";
import Reporting from "./app/pages/reporting/Reporting";
import Implementation from "./app/pages/implementation/Implementation";
import MainLayout from "./app/MainLayout";
import UserManagement from "./app/pages/UserManagement/UserManagement";
import Workspaces from "./app/pages/workspace/Workspaces";
import NotFound from "./app/components/NotFound/NotFound";
import IpManagement from "./app/pages/IpManagement/TableView";
import SettingsLayout from "./app/SettingsLayout";
import AccountSummary from "./app/pages/accountSettings//AccountSummary/AccountSummary";
import LoginInformation from "./app/pages/accountSettings/LoginInformation/LoginInformation";

import ProfileInformation from "./app/pages/accountSettings/ProfileInformation/ProfileInformation";
import PaymentInformation from "./app/pages/accountSettings/Payments/PaymentInformation";
import firstTime from "./app/pages/firstTimeLogIn/firstTime";
import FirstTimeSignIn from "./app/pages/firstTimeLogIn/firstTime";
import ConfirmationCode from "./app/pages/firstTimeLogIn/confirmationCode";
import ForgotPassword from "./app/pages/forgotPassword/forgotPassword";
import ForgotPasswordCode from "./app/pages/forgotPassword/code";
import NewPassword from "./app/pages/forgotPassword/NewPassword";
import MainFlags from "./app/pages/Flags/MainFlags";
import BudgetSummary from "./app/pages/BudgetSummary/BudgetSummary";
import Offices from "./app/pages/offices/Offices";
import { createSignalRContext } from "react-signalr/signalr";
import { HttpTransportType } from "@microsoft/signalr";

const SignalRContext = createSignalRContext();
const { REACT_APP_API_BASE_URL } = process.env;

function getRelativeTime(isoTimestamp) {
  const cleanedTimestamp = isoTimestamp.split(".")[0].replace("Z", "");

  const timestamp = new Date(cleanedTimestamp);
  if (isNaN(timestamp)) {
    return "Invalid date";
  }

  const now = new Date();
  const deltaSeconds = Math.floor((now - timestamp) / 1000);
  const deltaMinutes = Math.floor(deltaSeconds / 60);
  const deltaHours = Math.floor(deltaMinutes / 60);
  const deltaDays = Math.floor(deltaHours / 24);
  const deltaWeeks = Math.floor(deltaDays / 7);
  const deltaMonths = Math.floor(deltaDays / 30);
  const deltaYears = Math.floor(deltaDays / 365);

  if (deltaSeconds < 60) {
    return deltaSeconds === 1 ? "1 second ago" : `${deltaSeconds} seconds ago`;
  } else if (deltaMinutes < 60) {
    return deltaMinutes === 1 ? "1 minute ago" : `${deltaMinutes} minutes ago`;
  } else if (deltaHours < 24) {
    return deltaHours === 1 ? "1 hour ago" : `${deltaHours} hours ago`;
  } else if (deltaDays === 1) {
    return "yesterday";
  } else if (deltaDays < 7) {
    return `${deltaDays} days ago`;
  } else if (deltaWeeks === 1) {
    return "last week";
  } else if (deltaWeeks < 4) {
    return `${deltaWeeks} weeks ago`;
  } else if (deltaMonths === 1) {
    return "last month";
  } else if (deltaMonths < 12) {
    return `${deltaMonths} months ago`;
  } else if (deltaYears === 1) {
    return "last year";
  } else {
    return `${deltaYears} years ago`;
  }
}

function App() {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const url = `${REACT_APP_API_BASE_URL}notifications/`;
  const loggedUserId = localStorage.getItem("userID");

  SignalRContext.useSignalREffect("Notification", (data) => {
    if (data?.userId == loggedUserId) {
      pushNewNotificationData(data);
    }
  });

  const pushNewNotificationData = (notificationData) => {
    const data = {
      id: notificationData?.id,
      type: notificationData?.notificationType,
      description: notificationData?.message,
      read: notificationData?.isRead,
      // preview: "This is a preview text.",
      time: getRelativeTime(notificationData?.createdDate),
    };
    setUnreadNotifications(unreadNotifications + 1);

    setNotifications((prevNotifications) => {
      return {
        ...prevNotifications,
        data: [data, ...prevNotifications?.data],
      };
    });
  };

  return (
    <SignalRContext.Provider
      connectEnabled={true}
      // dependencies={[token]}
      url={url}
      skipNegotiation={true}
      transport={HttpTransportType.WebSockets}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route
              path="/forgotPassword/code"
              element={<ForgotPasswordCode />}
            />
            <Route path="/newPassword" element={<NewPassword />} />
            <Route path="confirmationCode" element={<ConfirmationCode />} />

            <Route path="/*" element={<ProtectedRoute />}>
              <Route
                path="dashboard/*"
                element={
                  <MainLayout
                    notifications={notifications}
                    setNotifications={setNotifications}
                    unreadNotifications={unreadNotifications}
                    setUnreadNotifications={setUnreadNotifications}
                  />
                }
              >
                <Route path="user-management" element={<UserManagement />} />
                <Route path="contracts" element={<Workspaces />} />
                <>
                  <Route path="budget-summary" element={<BudgetSummary />} />
                </>
                <Route path="offices" element={<Offices />} />

                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="projects"
                element={
                  <Projects
                    notifications={notifications}
                    setNotifications={setNotifications}
                    unreadNotifications={unreadNotifications}
                    setUnreadNotifications={setUnreadNotifications}
                  />
                }
              />
              <Route path="first-time" element={<FirstTimeSignIn />} />
              {/* <Route path="confirmationCode" element={<ConfirmationCode />} /> */}
              <Route
                path="project/*"
                element={
                  <ProjectLayout
                    notifications={notifications}
                    setNotifications={setNotifications}
                    unreadNotifications={unreadNotifications}
                    setUnreadNotifications={setUnreadNotifications}
                  />
                }
              >
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="planning" element={<Planning />} />
                <Route path="implementation" element={<Implementation />} />
                <Route path="reporting" element={<Reporting />} />
                <Route path="completed" element={<Completed />} />
                <Route path="flags" element={<MainFlags />} />{" "}
                <Route path="ip-management" element={<IpManagement />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              <Route
                path="settings/*"
                element={
                  <SettingsLayout
                    notifications={notifications}
                    setNotifications={setNotifications}
                    unreadNotifications={unreadNotifications}
                    setUnreadNotifications={setUnreadNotifications}
                  />
                }
              >
                {/* <Route path="account-summary" element={<AccountSummary />} /> */}
                <Route
                  path="login-information"
                  element={<LoginInformation />}
                />
                <Route path="payments" element={<PaymentInformation />} />
                <Route
                  path="profile-information"
                  element={<ProfileInformation />}
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </SignalRContext.Provider>
  );
}

export default App;
