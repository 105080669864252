import React, { useEffect, useState, useRef } from "react";
import { Modal, Input, Button, Select, Checkbox, Spin } from "antd";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../../services/httpService.service";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const AddTeamCardModal = ({ visible, onClose, fetchTeams, setMessage }) => {
  const [enumerators, setEnumerators] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [selectedEnumerators, setSelectedEnumerators] = useState([]);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountryForState, setSelectedCountryForState] = useState(null);
  const [states, setStates] = useState([]);
  const [selectedStateForCity, setSelectedStateForCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [city, setCity] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedFieldCoordinator, setSelectedFieldCoordinator] =
    useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [fieldCoordinators, setFieldCoordinators] = useState([]);
  const currentContractId = localStorage.getItem("currentContractId");
  const [drivers, setDrivers] = useState([]);
  const formikRef = useRef();

  const initialValues = {
    teamCardName: "",
    enumeratorIds: [], // Multiple values allowed
    teamLeaderId: null, // Single value allowed
    fieldCoordinatorId: null, // Single value allowed
    driverId: null, // Single value allowed
    isActive: true,
    countryId: null,
    cityId: null,
    stateId: null,
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

const handleLeave = () => {
  onClose();

  setCity([]);
  setStates([]);
  setSelectedCity(null);
  setSelectedStateForCity(null);
  setSelectedEnumerators([]);
  setSelectedTeamLeader(null);
  setSelectedFieldCoordinator(null);
  setSelectedDriver(null);

  // Reset only if multiple countries are available; otherwise, keep the selected one
  if (allCountries.length > 1) {
    setSelectedCountry(null);
    formikRef.current.setFieldValue("countryId", null);
  }
  
  resetForm(); // Keep this to reset other form fields
};

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const validateCity = (cities, cityIds) => {
    // If stateId is not provided, no need to validate city
    if (!cities || cities.length === 0) {
      return true;
    }
    // If cities are present, ensure at least one city is selected
    return cityIds && cityIds.length > 0;
  };

  const validationSchema = Yup.object().shape({
    teamCardName: Yup.string().required("Please enter team card name"),

    enumeratorIds: Yup.array()
      .of(Yup.string())
      .min(1, "Please select at least one enumerator"),

    teamLeaderId: Yup.string().required("Please select a team leader"),

    fieldCoordinatorId: Yup.string().required(
      "Please select a field coordinator"
    ),

    countryId: Yup.string().required("Please select a country"),

    stateId: Yup.string().required("Please select a state"),

    cityId: Yup.string().test(
      "is-valid-city",
      "Please select a city",
      function (cityId) {
        const { stateId } = this.parent; // Access other fields if necessary
        if (stateId) {
          // Validate only if stateId is present
          return validateCity(city, cityId);
        }
        return true; // If stateId is not present, validation for cityId is not required
      }
    ),
  });

  const resetForm = () => {
    setSelectedEnumerators([]);
    setSelectedTeamLeader(null);
    setSelectedFieldCoordinator(null);
    setSelectedDriver(null);
  };
  const fetchEnumerators = async () => {
    await httpService.get(
      `/api/User/getAllEnumerators?ContractId=${currentContractId}`,
      (res) => setEnumerators(res.data),
      (error) => console.error("Error fetching enumerators:", error)
    );
  };

  const fetchTeamLeaders = async () => {
    await httpService.get(
      `/api/User/getAllTeamLeadersByContract?contractId=${currentContractId}`,
      (res) => {
        setTeamLeaders(res.data);
      },
      (error) => {
        console.error("Error fetching team leaders:", error);
      }
    );
  };

  const fetchFieldCoordinators = async () => {
    await httpService.get(
      `/api/User/getAllusers?contractIds=${currentContractId}`,
      (res) => {
        const filteredFieldCoordinators = res.data.filter((user) =>
          user.userContractRoles.some(
            (role) => role.role.roleName === "Field Coordinator"
          )
        );
        setFieldCoordinators(filteredFieldCoordinators);
      },
      (error) => {
        console.error("Error fetching field coordinators:", error);
      }
    );
  };

  const fetchDrivers = async () => {
    await httpService.get(
      `/api/Driver/getAll?contractId=${currentContractId}`,
      (res) => {
        setDrivers(res.data);
      },
      (error) => {
        console.error("Error fetching drivers:", error);
      }
    );
  };

  useEffect(() => {
    fetchFieldCoordinators();
    fetchEnumerators();
    fetchTeamLeaders();
    fetchDrivers();
  }, [currentContractId]);

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/GeoData/getCountriesOfContract?contractId=${contractId}`,
      (res) => {
        setAllCountries(res.data);
  
        if (res.data.length === 1) {
          const countryId = res.data[0].id;
          setSelectedCountry(countryId); 
          setSelectedCountryForState(countryId); 
          formikRef.current.setFieldValue("countryId", countryId); 
        }
      },
      (error) => console.log(error)
    );
  }, [visible]);

  useEffect(() => {
    setLoadingCities(true);
    if (selectedCountryForState) {
      httpService.get(
        `/api/GeoData/states?countryIds=${[selectedCountryForState]}`,
        (res) => {
          setStates(res?.data);
          setLoadingCities(false);
        },
        (error) => {
          console.log(error);
          setLoadingCities(false);
        }
      );
    } else {
      setStates([]);
      setSelectedStateForCity(null);
      setCity([]);
      setLoadingCities(false);
    }
  }, [selectedCountryForState, visible]);

  useEffect(() => {
    setLoadingCities(true);
    if (selectedStateForCity) {
      httpService.get(
        `/api/GeoData/cities?stateIds=${[selectedStateForCity]}`,
        (res) => {
          setCity(res?.data);
          setLoadingCities(false);
        },
        (error) => {
          console.log(error);
          setLoadingCities(false);
        }
      );
    } else {
      setCity([]);
      setLoadingCities(false);
    }
  }, [selectedStateForCity]);
  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      name: values.teamCardName,
      contractId: currentContractId,
      memberIds: [
        ...values.enumeratorIds,
        values.teamLeaderId,
        values.fieldCoordinatorId,
      ],
      isActive: values.isActive,
      countryId: values.countryId,
      cityId: values.cityId,
      stateId: values.stateId,
    };
    if (values.driverId) {
      payload.driverIds = [values.driverId];
    }

    httpService.post(
      "/api/User/createTeam",
      payload,
      (response) => {
        setSubmitting(false);
        handleLeave();
        fetchTeams(currentContractId);
        resetForm();
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Team card added successfully!",
          text: "Team card has been added to your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem adding this team card to your database, please try again!`;
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Failed to add the team card",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSubmitting(false);
      }
    );
  };

  const CustomCheckboxGroup = ({ options, onChange, selectedValue, id }) => {
    return (
      <div>
        {options.map((option) => (
          <div key={option.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValue === option.id}
              onChange={() =>
                onChange(selectedValue === option.id ? null : option.id)
              }
            >
              <div
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: selectedValue === option.id ? "#AA1A5F" : "#777777",
                }}
              >
                {option.firstName && option.lastName
                  ? `${option.firstName} ${option.lastName}`
                  : option.userName || option.email || option.name}{" "}
                ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  const CustomMultipleCheckboxGroup = ({
    options = [],
    onChange,
    selectedValues = [],
    id,
  }) => {
    const handleChange = (value) => {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      onChange(newSelectedValues);
    };

    return (
      <div>
        {options.map((option) => (
          <div key={option.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValues.includes(option.id)}
              onChange={() => handleChange(option.id)}
            >
              <div
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: selectedValues.includes(option.id)
                    ? "#AA1A5F"
                    : "#777777",
                }}
              >
                {option.firstName && option.lastName
                  ? `${option.firstName} ${option.lastName}`
                  : option.userName || option.email}{" "}
                ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Enumerator Team Card</h2>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, handleChange, values }) => (
          <FormikForm layout="vertical">
            <p className="modalsHeader1">General details</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingBottom: "20px",
              }}
            >
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>Team
                card name
              </label>
              <Input
                name="teamCardName"
                onChange={handleChange}
                placeholder="Enter team card name"
                autoComplete="off"
              />
              <ErrorMessage
                name="teamCardName"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingBottom: "20px",
              }}
            >
              <label>
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                Country
              </label>
              <Select
                name="countryId"
                showSearch
                allowClear
                placeholder="Select a country"
                onChange={(value) => {
                  setSelectedCountry(value); // Update the selected country on change
                  setFieldValue("cityId", undefined);
                  setFieldValue("stateId", undefined);
                  setFieldValue("countryId", value);
                  setSelectedStateForCity(undefined);
                  setSelectedCity(undefined);
                  setSelectedCountryForState(value);
                }}
                value={selectedCountry || values.countryId} // Use the selected country or form value
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {allCountries.map((c) => (
                  <Option key={c.id} value={c.id}>
                    {c.stateName}
                  </Option>
                ))}
              </Select>
              <ErrorMessage
                name="countryId"
                component="div"
                className="error-message"
              />
            </div>
            <Spin spinning={loadingCities}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  {city.length < 1
                    ? "Governorate/State or city"
                    : "Governorate/State"}
                </label>
                <Select
  name="stateId"
  showSearch
  allowClear
  placeholder="Select a state"
  disabled={!selectedCountryForState || states.length < 1} // Ensure it considers preselected country
  onChange={(value) => {
    setSelectedStateForCity(value);
    setSelectedCity(null);
    setFieldValue("cityId", undefined);
    setFieldValue("stateId", value); // Ensure this is set correctly
  }}
  value={selectedStateForCity}
  filterOption={(input, option) =>
    option?.children.toLowerCase().startsWith(input.toLowerCase())
  }
>
  {states?.map((s) => (
    <Option key={s?.id} value={s?.id}>
      {s?.stateName}
    </Option>
  ))}
</Select>
                {/* <ErrorMessage name="countryId" component="div" className="error-message" /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
                className={
                  !selectedStateForCity || city.length < 1 ? "d-none" : ""
                }
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  City
                </label>
                <Select
                  name="cityId"
                  showSearch
                  allowClear
                  placeholder="Select a city"
                  disabled={!selectedStateForCity || city.length === 0}
                  onChange={(value) => {
                    setSelectedCity(value);
                    setFieldValue("cityId", value); // Ensure this is set correctly
                  }}
                  value={selectedCity}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {city?.map((c) => (
                    <Option key={c?.id} value={c?.id}>
                      {c?.name}
                    </Option>
                  ))}
                </Select>
                <ErrorMessage
                  name="cityId"
                  component="div"
                  className="error-message"
                />
              </div>
            </Spin>
            <div style={{ paddingBottom: "20px" }}>
              <label
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                Select your team members
              </label>
            </div>
            <label className="m-2">
              <span style={{ color: "red", paddingRight: "3px" }}>*</span>
              Select a field coordinator
            </label>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <CustomCheckboxGroup
                name="fieldCoordinatorId"
                id="Field Coordinator"
                options={fieldCoordinators}
                selectedValue={selectedFieldCoordinator?.id}
                onChange={(value) => {
                  const selected = fieldCoordinators.find(
                    (fc) => fc.id === value
                  );
                  setSelectedFieldCoordinator(selected);
                  setFieldValue("fieldCoordinatorId", value);
                }}
              />
              <ErrorMessage
                name="fieldCoordinatorId"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <label className="m-2">
              <span style={{ color: "red", paddingRight: "3px" }}></span>
              Select a driver
            </label>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <CustomCheckboxGroup
                name="driverId"
                id="Driver"
                options={drivers}
                selectedValue={selectedDriver?.id}
                onChange={(value) => {
                  const selected = drivers.find((d) => d.id === value);
                  setSelectedDriver(selected);
                  setFieldValue("driverId", value);
                }}
              />
              {/*  */}
            </div>
            <label className="m-2">
              <span style={{ color: "red", paddingRight: "3px" }}>*</span>
              Select enumerators
            </label>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <CustomMultipleCheckboxGroup
                options={enumerators}
                selectedValues={values.enumeratorIds}
                onChange={(values) => setFieldValue("enumeratorIds", values)}
                id="Enumerator"
              />
              <ErrorMessage
                name="enumeratorIds"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <label className="m-2">
              <span style={{ color: "red", paddingRight: "3px" }}>*</span>
              Select a team leader
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingBottom: "20px",
              }}
            >
              <CustomCheckboxGroup
                name="teamLeaderId"
                id="Team Leader"
                options={teamLeaders}
                selectedValue={selectedTeamLeader?.id}
                onChange={(value) => {
                  const selected = teamLeaders.find((tl) => tl.id === value);
                  setSelectedTeamLeader(selected);
                  setFieldValue("teamLeaderId", value);
                }}
              />
              <ErrorMessage
                name="teamLeaderId"
                component="div"
                style={{ color: "red" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingBottom: "20px",
              }}
            >
              <div
                style={{
                  borderRadius: "6px",
                  padding: "1px 0px 1px 10px",
                  gap: "10px",
                  background: "#FADCEA",
                }}
              >
                <h1
                  style={{
                    color: "var(--2, #AA1A5F)",
                    fontFamily: "Lato",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "22px",
                    margin: 0,
                  }}
                >
                  Note: You can only choose
                  <span style={{ fontWeight: 700 }}> one </span>field
                  coordinator.
                </h1>
              </div>
            </div>
            <div className="buttonsModal">
              <Button
                className="inviteButtonTPM"
                key="cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="addButtonTPM"
                key="submit"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Add team card
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTeamCardModal;
