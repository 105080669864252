import React, { useEffect, useState } from "react";
import { Modal, Input, Button, Select, Checkbox, Divider, Spin } from "antd";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../../../UserManagement/UserManagement.scss";
import cancelX from "../../../../../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../../../../../services/httpService.service";
import AddMemberIcon from "../../../../../../../Assets/svg/addMemberIcon.svg";
import showConfirm from "../../../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const Edit = ({ visible, onClose, fetchTeams, record, setMessage }) => {
  const [enumerators, setEnumerators] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEnumerators, setSelectedEnumerators] = useState([]);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [selectedCountryForState, setSelectedCountryForState] = useState(null);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedStateForCity, setSelectedStateForCity] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedFieldCoordinator, setSelectedFieldCoordinator] =
    useState(null);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [fieldCoordinators, setFieldCoordinators] = useState([]);
  const [initialValues, setInitialValues] = useState({
    teamCardName: "",
    enumeratorId: null,
    teamLeaderId: null,
    fieldCoordinatorId: null,
    driverId: null, // Single value allowed
    isActive: true,
    countryId: null,
    cityId: null,
    stateId: null,
  });
  const currentContractId = localStorage.getItem("currentContractId");

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onClose();
    resetForm();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const validationSchema = Yup.object().shape({
    // teamCardName: Yup.string().required("Please enter team card name"),

    enumeratorIds: Yup.array()
      .of(Yup.string())
      .min(1, "Please select at least one enumerator"),

    teamLeaderId: Yup.string().required("Please select a team leader"),

    fieldCoordinatorId: Yup.string().required(
      "Please select a field coordinator"
    ),

    // countryId: Yup.string().required("Please select a country"),

    // stateId: Yup.string().required("Please select a state"),

    // cityId: Yup.string().test(
    //   "is-valid-city",
    //   "Please select a city",
    //   function (cityId) {
    //     const { stateId } = this.parent; // Access other fields if necessary
    //     if (stateId) {
    //       // Validate only if stateId is present
    //       return validateCity(city, cityId);
    //     }
    //     return true; // If stateId is not present, validation for cityId is not required
    //   }
    // ),
  });

  const resetForm = () => {
    setSelectedEnumerators([]);
    setSelectedTeamLeader(null);
    setSelectedFieldCoordinator(null);
    setSelectedDriver(null);
  };

  useEffect(() => {
    if (record?.id) {
      fetchTeamById(record.id);
    }
  }, [record?.id]);

  const fetchEnumerators = async () => {
    await httpService.get(
      `/api/User/getAllEnumerators?ContractId=${currentContractId}`,
      (res) => setEnumerators(res.data),
      (error) => console.log("Error fetching enumerators:", error)
    );
  };

  const fetchTeamLeaders = async () => {
    await httpService.get(
      `/api/User/getAllTeamLeadersByContract?contractId=${currentContractId}`,
      (res) => {
        setTeamLeaders(res.data);
      },
      (error) => {
        console.error("Error fetching team leaders:", error);
      }
    );
  };

  const fetchFieldCoordinators = async () => {
    await httpService.get(
      `/api/User/getAllusers?contractIds=${currentContractId}`,
      (res) => {
        const filteredFieldCoordinators = res.data.filter((user) =>
          user.userContractRoles.some(
            (role) => role.role.roleName === "Field Coordinator"
          )
        );
        setFieldCoordinators(filteredFieldCoordinators);
      },
      (error) => {
        console.error("Error fetching field coordinators:", error);
      }
    );
  };

  const fetchDrivers = async () => {
    await httpService.get(
      `/api/Driver/getAll?contractId=${currentContractId}`,
      (res) => {
        setDrivers(res.data);
      },
      (error) => {
        console.error("Error fetching drivers:", error);
      }
    );
  };

  useEffect(() => {
    fetchDrivers();
    fetchFieldCoordinators();
    fetchEnumerators();
    fetchTeamLeaders();
  }, [currentContractId]);

  useEffect(() => {
    httpService.get(
      "/api/GeoData/countries",
      (res) => setAllCountries(res.data),
      (error) => console.log(error)
    );
  }, []);

  useEffect(() => {
    if (selectedCountryForState) {
      httpService.get(
        `/api/GeoData/states?countryIds=${[selectedCountryForState]}`,
        (res) => {
          setStates(res?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setStates([]);
      setSelectedStateForCity(null);
      setCity([]);
    }
  }, [selectedCountryForState]);

  useEffect(() => {
    if (selectedStateForCity) {
      httpService.get(
        `/api/GeoData/cities?stateIds=${[selectedStateForCity]}`,
        (res) => {
          setCity(res?.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setCity([]);
    }
  }, [selectedStateForCity]);

  const fetchTeamById = async (teamId) => {
    setLoading(true);
    await httpService.get(
      `/api/User/getTeamById?teamId=${teamId}`,
      (res) => {
        const team = res.data;

        const enumerators = team.members.filter(
          (member) => member.roleName === "Field Enumerator"
        );
        const teamLeader = team.members.find(
          (member) => member.roleName === "Field Team Leader"
        );
        const fieldCoordinator = team.members.find(
          (member) => member.roleName === "Field Coordinator"
        );

        const driver = team?.drivers[0];

        setInitialValues({
          teamCardName: team.name || "",
          enumeratorIds: enumerators.map((enumerator) => enumerator.id) || [], // Set all enumerator IDs
          teamLeaderId: teamLeader ? teamLeader.id : null,
          fieldCoordinatorId: fieldCoordinator ? fieldCoordinator.id : null,
          driverId: driver ? driver.id : null,
          isActive: team.isActive,
          countryId: team.state?.country.id || null,
          cityId: team.location?.id || null,
          stateId: team.state?.id || null,
        });

        setSelectedDriver(driver);
        setSelectedCountryForState(team?.state?.country?.id);
        setSelectedEnumerators(enumerators); // Set selected enumerators
        setSelectedTeamLeader(teamLeader);
        setSelectedFieldCoordinator(fieldCoordinator);
        setSelectedStateForCity(team?.state?.id);
        setSelectedCity(team?.location?.id);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching team details:", error);
        setLoading(false);
      }
    );
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const payload = {
      id: record.id,
      name: values.teamCardName,
      contractId: currentContractId,
      memberIds: [
        ...values.enumeratorIds,
        values.teamLeaderId,
        values.fieldCoordinatorId,
      ],
      isActive: values.isActive,
      countryId: values.countryId,
      cityId: values.cityId,
      stateId: values.stateId,
    };
    if (values.driverId) {
      payload.driverIds = [values.driverId];
    }
    httpService.put(
      "/api/User/updateTeam",
      payload,
      (response) => {
        onClose();
        fetchTeams(currentContractId);
        resetForm();
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: "Team card updated successfully!",
          text: "The team card has been updated to your database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSubmitting(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem updating this team card in your database, please try again!`;
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Failed to update this team card",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSubmitting(false);
      }
    );
  };

  const CustomMultipleCheckboxGroup = ({
    options = [],
    onChange,
    selectedValues = [],
    id,
  }) => {
    const handleChange = (value) => {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((v) => v !== value)
        : [...selectedValues, value];
      onChange(newSelectedValues);
    };

    return (
      <div>
        {options.map((option) => (
          <div key={option.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValues.includes(option.id)}
              onChange={() => handleChange(option.id)}
            >
              <div
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: selectedValues.includes(option.id)
                    ? "#AA1A5F"
                    : "#777777",
                }}
              >
                {option.firstName && option.lastName
                  ? `${option.firstName} ${option.lastName}`
                  : option.userName || option.email}{" "}
                ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };
  const CustomCheckboxGroup = ({ options, onChange, selectedValue, id }) => {
    return (
      <div>
        {options.map((option) => (
          <div key={option.id} style={{ marginBottom: "14px" }}>
            <Checkbox
              className="custom-checkbox"
              checked={selectedValue === option.id}
              onChange={() =>
                onChange(selectedValue === option.id ? null : option.id)
              }
            >
              <div
                style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: selectedValue === option.id ? "#AA1A5F" : "#777777",
                }}
              >
                {option.firstName && option.lastName
                  ? `${option.firstName} ${option.lastName}`
                  : option.userName || option.email || option.name}{" "}
                ({id})
              </div>
            </Checkbox>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      destroyOnClose
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt=""
        />
      </div>
      <h2 className="modalHeader">Edit Team Card</h2>
      <Spin spinning={loading}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue, handleChange, values }) => (
            <FormikForm layout="vertical">
              <p className="modalsHeader1">General details</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  Team card name
                </label>
                <Input
                  name="teamCardName"
                  onChange={handleChange}
                  value={values.teamCardName}
                  placeholder="Enter team card name"
                  autoComplete="off"
                  disabled={true}
                />
                <ErrorMessage
                  name="teamCardName"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  Country
                </label>
                <Select
                  name="countryId"
                  showSearch
                  allowClear
                  disabled={true}
                  placeholder="Select a country"
                  onChange={(value) => {
                    setFieldValue("cityId", undefined);
                    setFieldValue("stateId", undefined);
                    setFieldValue("countryId", value);
                    setSelectedStateForCity(undefined);
                    setSelectedCity(undefined);
                    setSelectedCountryForState(value);
                  }}
                  value={values.countryId}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {allCountries.map((c) => (
                    <Option key={c.id} value={c.id}>
                      {c.stateName}
                    </Option>
                  ))}
                </Select>
                <ErrorMessage
                  name="countryId"
                  component="div"
                  className="error-message"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  {city.length < 1
                    ? "Governorate/State or city"
                    : "Governorate/State"}
                </label>
                <Select
                  name="stateId"
                  showSearch
                  allowClear
                  disabled={true}
                  placeholder="Select a state"
                  onChange={(value) => {
                    setSelectedStateForCity(value);
                    setSelectedCity(undefined);
                    setFieldValue("cityId", undefined);
                    setFieldValue("stateId", value);
                  }}
                  value={values.stateId}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {states?.map((s) => (
                    <Option key={s?.id} value={s?.id}>
                      {s?.stateName}
                    </Option>
                  ))}
                </Select>
                {/* <ErrorMessage name="countryId" component="div" className="error-message" /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
                className={
                  !selectedStateForCity || city.length < 1 ? "d-none" : ""
                }
              >
                <label>
                  <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                  City
                </label>
                <Select
                  name="cityId"
                  showSearch
                  allowClear
                  disabled={true}
                  placeholder="Select a city"
                  onChange={(value) => {
                    setSelectedCity(value);
                    setFieldValue("cityId", value); // Ensure this is set correctly
                  }}
                  value={selectedCity}
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {city?.map((c) => (
                    <Option key={c?.id} value={c?.id}>
                      {c?.name}
                    </Option>
                  ))}
                </Select>
                {/* <ErrorMessage name="cityId" component="div" className="error-message" /> */}
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "22px",
                  }}
                >
                  Select your team members
                </label>
              </div>
              <label className="m-2">
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                Select a field coordinator
              </label>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <CustomCheckboxGroup
                  name="fieldCoordinatorId"
                  id="Field Coordinator"
                  options={fieldCoordinators}
                  selectedValue={selectedFieldCoordinator?.id}
                  onChange={(value) => {
                    const selected = fieldCoordinators.find(
                      (fc) => fc.id === value
                    );
                    setSelectedFieldCoordinator(selected);
                    setFieldValue("fieldCoordinatorId", value);
                  }}
                />
                <ErrorMessage
                  name="fieldCoordinatorId"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <label className="m-2">
                <span style={{ color: "red", paddingRight: "3px" }}></span>
                Select a driver
              </label>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <CustomCheckboxGroup
                  name="driverId"
                  id="Driver"
                  options={drivers}
                  selectedValue={selectedDriver?.id}
                  onChange={(value) => {
                    const selected = drivers.find((d) => d.id === value);
                    setSelectedDriver(selected);
                    setFieldValue("driverId", value); // Pass only the ID, not the entire object
                  }}
                />
              </div>
              <label className="m-2">
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                Select enumerators
              </label>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <CustomMultipleCheckboxGroup
                  options={enumerators}
                  selectedValues={values.enumeratorIds}
                  onChange={(values) => setFieldValue("enumeratorIds", values)}
                  id="Enumerator"
                />
                <ErrorMessage
                  name="enumeratorIds"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <label className="m-2">
                <span style={{ color: "red", paddingRight: "3px" }}>*</span>
                Select a team leader
              </label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <CustomCheckboxGroup
                  name="teamLeaderId"
                  id="Team Leader"
                  options={teamLeaders}
                  selectedValue={selectedTeamLeader?.id}
                  onChange={(value) => {
                    const selected = teamLeaders.find((tl) => tl.id === value);
                    setSelectedTeamLeader(selected);
                    setFieldValue("teamLeaderId", value);
                  }}
                />
                <ErrorMessage
                  name="teamLeaderId"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "20px",
                }}
              >
                <div
                  style={{
                    borderRadius: "6px",
                    padding: "1px 0px 1px 10px",
                    gap: "10px",
                    background: "#FADCEA",
                  }}
                >
                  <h1
                    style={{
                      fontFamily: "Lato",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "22px",
                      margin: 0,
                    }}
                  >
                    Note: You can only choose
                    <span style={{ fontWeight: 900 }}> one </span>field
                    coordinator and you can only edit team members.
                  </h1>
                </div>
              </div>
              <Divider />
              <div className="buttonsModal">
                <Button
                  className="inviteButtonTPM"
                  key="cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="addButtonTPM"
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </Spin>
    </Modal>
  );
};

export default Edit;
