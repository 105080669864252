import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Upload,
  Spin,
} from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import dayjs from "dayjs";
import { httpService } from "../../../../../services/httpService.service";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Not addressed" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const Edit = ({
  visible,
  onClose,
  record,
  setMessage,
  fetchRedFlags,
  pagination,
}) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState();
  const [users, setUsers] = useState();
  const [form] = Form.useForm();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [RedFlagFiles, setRedFlagFiles] = useState([]);
  const [initialName, setInitialName] = useState("");
  const [lastUpdated, setlastUpdated] = useState("");
  useEffect(() => {
    setPageLoading(true);
    if (record?.key) {
      const redFlagId = record?.key;

      httpService
        .get(`/api/RedFlag/getRedFlag?id=${redFlagId}`, (res) => {
          const redFlag = res.data;
          const implementingPartner = redFlag?.implementingPartner;

          const filesUploaded =
            redFlag?.redFlagFiles?.map((file) => file) || [];
          if (redFlag?.updatedDate) {
            const updatedDate = redFlag?.updatedDate.split("T")[0];
            setlastUpdated(updatedDate);
          }
          setRedFlagFiles(filesUploaded);
          setInitialName(redFlag?.name);
          form.setFieldsValue({
            name: redFlag?.name,
            ImplementingPartner: implementingPartner?.name,
            description: redFlag?.description,
            siteVisitCard: redFlag?.siteVisitIdentifier,
            dateOfSiteVisit: dayjs(redFlag?.dateOfSiteVisit),
            city: implementingPartner?.cities?.map((city) => city.id),
            state: implementingPartner?.states?.map((state) => state.id),
            office: redFlag?.office?.id,
            dueDate: dayjs(redFlag?.dueDate),
            assignedTo: redFlag?.assignedTo?.id,
            flagStatus: redFlag?.flagStatus,
            followUpNotes: redFlag?.followUpNotes,
          });

          setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, [record, form]);

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";

    return date.format("YYYY-MM-DD");
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    setFileList([]);
    setRedFlagFiles([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
      const dueDate = formatDate(values.dueDate);
      const formData = new FormData();
      formData.append("Id", record?.key);
      if (values.name !== initialName) {
        formData.append("Name", values.name);
      }
      formData.append("Description", values.description);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);
      RedFlagFiles?.forEach((file, index) => {
        formData.append(`UpdateFiles[${index}].id`, file.id);
      });
      fileList?.forEach((file, index) => {
        const countFiles = RedFlagFiles.length + index;
        formData.append(`UpdateFiles[${countFiles}].file`, file.originFileObj);
        formData.append(
          `UpdateFiles[${countFiles}].id`,
          "00000000-0000-0000-0000-000000000000"
        );
      });

      if (fileList.length === 0 && RedFlagFiles.length === 0) {
        const emptyFile = new Blob([], { type: "application/json" });
        formData.append("UpdateFiles[0].fileStatus", 1);
        formData.append("UpdateFiles[0].file", emptyFile, "empty.json");
      }

      await httpService.put(
        "/api/RedFlag",
        formData,
        (response) => {
          const message =
            response.message ||
            `A new red flag has been created and added to your database!`;
          setMessage({
            trigger: true,
            type: "success",
            icon: AddMemberIcon,
            title: `Red flag edited successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          form.resetFields();
          onClose();
          setRedFlagFiles([]);
          setFileList([]);
          fetchRedFlags(pagination.current, pagination.pageSize);
        },
        (error) => {
          const messageError =
            error?.errors?.[0]?.message ||
            `We had a problem adding this red flag to your database, please try again!`;
          setMessage({
            trigger: true,
            type: "danger",
            icon: AddMemberIcon,
            title: "Oops! A problem has occurred!",
            text: messageError,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        },
        {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundaryTf4LfzAOGWPtcNQl",
          },
        }
      );
    } catch (errorInfo) {
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Validation Error",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
    } finally {
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      setLoading(false);
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleResourceRedFlagRemove = (fileToRemove) => {
    setRedFlagFiles((prevFiles) =>
      prevFiles.filter((file) => file.fileName !== fileToRemove.fileName)
    );
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {lastUpdated && <span>Last update:  {formatDate(lastUpdated)}</span>}
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Red flag card</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical">
          {" "}
          <Form.Item
            className="fullWidthInput"
            name="name"
            label="Name (type of red flag)"
            rules={[
              { required: true, message: "Enter the name of the red flag" },
            ]}
          >
            <Input
              placeholder="Enter the name of the red flag"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="siteVisitCard"
            label="Select the site visit card"
            disabled
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="<###> - SV - <Sitename> - <Location 2> - <date created>"
            />
          </Form.Item>
          <Form.Item
            name="ImplementingPartner"
            label="Implementing partner"
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="Implementing partner of the selected site visit card"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Enter the description of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter the description of the flag"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dateOfSiteVisit"
              label="Date of the red flag"
              rules={[{ required: true, message: "Pick date of red flag" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Enter text here" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dueDate"
              label="Due date for action"
              rules={[{ required: true, message: "Pick due date" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Select date"></DatePicker>
            </Form.Item>
          </div>
          <Form.Item
            name="assignedTo"
            label="Assigned to a field coordinator"
            rules={[
              {
                required: true,
                message: "Select assigned to a field coordinator",
              },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select assigned to a field coordinator">
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.userDetails?.firstName && user.userDetails?.lastName
                      ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                      : user.userName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="flagStatus"
            label="Flag status"
            rules={[
              { required: true, message: "Select the status of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select the status of the flag">
              {flagStatuses &&
                flagStatuses.map((flagStatus) => (
                  <Option key={flagStatus.id} value={flagStatus.id}>
                    {flagStatus.status}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label="Follow-up notes"
            rules={[{ required: true, message: "Enter your follow up notes" }]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter your follow up notes" />
          </Form.Item>
          <div className="resourcesElement">
            <div className="resourcesLabel">
              <div className="resourcesUploadElement">
                <Form.Item
                  name="files"
                  label={
                    <div>
                      <p
                        className="modalsHeader1"
                        style={{
                          color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "144%",
                        }}
                      >
                        Evidence
                      </p>
                      <p
                        style={{
                          color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                        }}
                      >
                        Please attach any relevant evidence if needed
                      </p>
                    </div>
                  }
                >
                  <Upload.Dragger
                    multiple
                    listType="text"
                    accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={(file) => handleFileSize(file)}
                  >
                    <div className="d-flex justify-content-center pb-3">
                      <img src={uploadIcon} alt="" />
                    </div>
                    <Button className="uploadButton">
                      Drag and drop files here, or click to select files
                    </Button>
                    <div className="d-flex justify-content-center bottomText ">
                      <span className="span3">
                        PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                      </span>
                      <span className="span4">Max file size: 5MB</span>
                    </div>
                  </Upload.Dragger>
                  <div className="uploadsContainer">
                    {fileList.map((file, index) => (
                      <div className="uploadContent" key={file.uid}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="uploadsContainer">
                    {RedFlagFiles.map((file, index) => (
                      <div className="uploadContent" key={index}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.fileName}
                        />
                        <div>
                          <h1>{file.fileName}</h1>
                        </div>
                        <img
                          onClick={() => handleResourceRedFlagRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              Save changes
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default Edit;
