import React, { useContext, useState, useEffect } from "react";
import { Table, Menu, Dropdown } from "antd";
import { DataContext } from "../DataContext";
import Filters from "../Filters";
import EditTaskModal from "../Modals/EditTaskModal";
import PreviewTaskModal from "../Modals/PreviewTaskModal";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import IconStrategy from "../../../../../Assets/svg/task.svg";

const taskTypes = [
  { id: 1, name: "Ops preparation" },
  { id: 2, name: "Data Collection" },
  { id: 3, name: "Data check" },
  { id: 4, name: "Translation" },
  { id: 5, name: "Field report" },
  { id: 6, name: "Data cleaning" },
  { id: 7, name: "Data analysis" },
  { id: 8, name: "Data translation" },
  { id: 9, name: "IP report" },
  { id: 10, name: "Donor report" },
  { id: 11, name: "Other" },
];

const TableView = ({ dataUpdated, setDataUpdated }) => {
  const context = useContext(DataContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  if (!context) {
    return <></>;
  }
  const {
    data,
    loading,
    setLoading,
    fetchData,
    changeTaskStatus,
    filters,
    pagination,
    setPagination,
  } = context;

  if (dataUpdated) {
    fetchData();
    setDataUpdated(false);
  }
  const handleStatusChange = (record, key) => {
    if (
      isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.FieldTeamLeader,
        RolesEnum.FieldEnumerator,
        RolesEnum.FieldLogistics,
      ])
    ) {
      changeTaskStatus(record.id, key);
    } else {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Unauthorized!",
        text: "You do not have permission to change the status.",
      });
    }
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );
    return formattedDate;
  };

  const columns = [
    {
      title: <span className="styledTitle">Task Identifier</span>,
      dataIndex: ["taskSite", "identifier"],
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
      render: (text, record) => (
        <span className="styledDataIndex3">{record.identifier}</span>
      ),
    },
    {
      title: <span className="styledTitle">Task Name</span>,
      dataIndex: ["taskSite", "name"],
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Status</span>,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => {
        const statusA = a.status ? a.status.toString() : "";
        const statusB = b.status ? b.status.toString() : "";
        return statusA.localeCompare(statusB, undefined, {
          sensitivity: "base",
        });
      },
      render: (text, record) => {
        const statusMap = {
          1: "Assigned",
          2: "Accepted",
          3: "Completed",
          4: "On hold",
          5: "Archived",
        };
        const statusText = statusMap[text] || "Unknown Status";
        let className;
        let fill;

        if (statusText === "Archived") {
          className = "archived";
          fill = "#555555";
        } else if (statusText === "Completed") {
          className = "completed";
          fill = "var(--system-green-sucess, #0E9349)";
        } else if (statusText === "On hold") {
          className = "onHold";
          fill = "#D46600";
        } else if (statusText === "Accepted") {
          className = "accepted";
          fill = "#3B81FE";
        } else if (statusText === "Assigned") {
          className = "assigned";
          fill = "var(--system-yellow-warning, #FC9403)";
        }

        // Check if the user is authorized to change status
        const isUserAuthorized = isAuthorized([
          RolesEnum.ProjectManager,
          RolesEnum.SystemAdmin,
          RolesEnum.FieldCoordinator,
          RolesEnum.FieldTeamLeader,
          RolesEnum.FieldEnumerator,
          RolesEnum.FieldLogistics,
        ]);

        return (
          <Dropdown
            overlay={statusMenu(record)}
            trigger={["click"]}
            disabled={!isUserAuthorized} // Disable if the user is not authorized
          >
            <div
              className={className}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                cursor: isUserAuthorized ? "pointer" : "not-allowed", // Change cursor based on authorization
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8px"
                height="8px"
                viewBox="0 0 9 9"
              >
                <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
              </svg>
              <span className="styledDataIndex3">{statusText}</span>
            </div>
          </Dropdown>
        );
      },
    },
    {
      title: <span className="styledTitle">Deadline</span>,
      dataIndex: "deadline",
      key: "deadline",
      width: 250,
      sorter: (a, b) => a.deadline.localeCompare(b.deadline),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "taskUsers",
      key: "assignedTo",
      sorter: (a, b) => {
        const aUserNames = Array.isArray(a.taskUsers)
          ? a.taskUsers.map((user) => user.userName).join(", ")
          : "";
        const bUserNames = Array.isArray(b.taskUsers)
          ? b.taskUsers.map((user) => user.userName).join(", ")
          : "";
        return aUserNames.localeCompare(bUserNames);
      },
      render: (taskUsers) => {
        return (
          <span className="styledDataIndex3">
            {Array.isArray(taskUsers) && taskUsers.length > 0
              ? taskUsers.map((user) => user.userName).join(", ")
              : "unassigned"}
          </span>
        );
      },
    },
    {
      title: <span className="styledTitle">Location</span>,
      dataIndex: "taskLocation",
      key: "location",
      sorter: (a, b) =>
        a.taskLocation?.country?.stateName.localeCompare(
          b.taskLocation?.country?.stateName
        ),
      render: (taskLocation) => (
        <span className="styledDataIndex3">
          {taskLocation?.country?.stateName || "N/A"}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Type</span>,
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        const typeA = a.type ? a.type.toString() : "";
        const typeB = b.type ? b.type.toString() : "";
        return typeA.localeCompare(typeB, undefined, { sensitivity: "base" });
      },
      render: (typeId) => {
        const taskType = taskTypes.find((task) => task.id === typeId);
        return (
          <span className="styledDataIndex3">
            {taskType ? taskType.name : "Unknown Type"}
          </span>
        );
      },
    },
    {
      key: "action",
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handlePreview(record)}
              style={{ cursor: "pointer" }}
              alt="Preview"
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleEdit(record)}
              style={{ cursor: "pointer" }}
              alt="Edit"
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              onClick={() => handleDelete(record)}
              style={{ cursor: "pointer" }}
              alt="Delete"
            />
          )}
        </div>
      ),
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
    },
  ];

  const statusMenu = (record) => (
    <Menu onClick={({ key }) => handleStatusChange(record, key)}>
      <Menu.Item key={1}>Assigned</Menu.Item>
      <Menu.Item key={2}>Accepted</Menu.Item>
      <Menu.Item key={3}>Completed</Menu.Item>
      <Menu.Item key={4}>On hold</Menu.Item>
      <Menu.Item key={5}>Archived</Menu.Item>
    </Menu>
  );

  const handlePreview = (record) => {
    setSelectedTaskId(record.id);
    setShowPreviewModal(true);
  };

  const handleEdit = (record) => {
    setSelectedTaskId(record.id);
    setShowEditModal(true);
  };

  const handleDelete = async (record) => {
    setSelectedTaskId(record.id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (selectedTaskId) {
      const requestBody = [{ id: selectedTaskId }];
      const url = `/api/Tasks/deleteTasks?includeIsDeleted=false`;

      httpService.delete(
        url,
        requestBody,
        (response) => {
          const message =
            response.data?.message ||
            "The task was successfully deleted from your database.";

          setMessage({
            trigger: true,
            type: "success",
            icon: IconStrategy,
            title: "Task deleted successfully!",
            text: message,
          });
          fetchData();
          setLoading(false);
          setShowDeleteModal(false);
        },
        (error) => {
          console.error("Error deleting record:", error);
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem deleting this task, please try again.";

          setMessage({
            trigger: true,
            type: "danger",
            icon: IconStrategy,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          fetchData();
          setLoading(false);
          setShowDeleteModal(false);
        }
      );
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  const handleTableChange = (current, pageSize) => {
    setPagination({
      ...pagination,
      current: current,
      pageSize: pageSize,
    });
  };

  return (
    <div className="d-flex gap-3 flex-column">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="mb-4 mt-5">
        <Filters setPagination={setPagination} />
      </div>
      <Table
        loading={loading}
        dataSource={data}
        rowSelection={{ ...rowSelection }}
        columns={columns}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          onChange: handleTableChange,
        }}
      />

      {selectedTaskId && (
        <>
          <EditTaskModal
            visible={showEditModal}
            setShowEditModal={setShowEditModal}
            taskId={selectedTaskId}
            setMessage={setMessage}
          />
          <PreviewTaskModal
            isVisible={showPreviewModal}
            onClose={() => setShowPreviewModal(false)}
            taskId={selectedTaskId}
          />
          <DeleteComponent
            visible={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            text={
              "You're about to delete this task. This action can’t be undone. "
            }
            header={"Delete "}
            onConfirm={handleConfirmDelete}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default TableView;
