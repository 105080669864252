import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import { Button, Table, Spin, Tooltip } from "antd";
import "./UserManagement.scss";
import invite from "../../../Assets/svg/inviteButton.svg";
import add from "../../../Assets/svg/addButton.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../Assets/svg/table-action-preview.svg";
import numberOfMembers from "../../../Assets/svg/numberOfMembers.svg";
import AddMember from "./AddMember";
import EditMember from "./EditMember";
import PreviewMember from "./Preview";
import InviteMember from "./InviteMember";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import { httpService } from "../../../services/httpService.service";
import Filters from "./Filters";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import DeleteMemberIcon from "../../../Assets/svg/deleteMemberIcon.svg";
import MembersFailed from "./MembersFailed";
import { RolesEnum } from "../../../services/rolesEnum";

const UserManagement = () => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const [showPreviewMemberModal, setShowPreviewMemberModal] = useState(false);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFailedMembersModal, setShowFailedMembersModal] = useState(false);
  const [messageResponse, setMessageResponse] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState({
    Search: "",
    RoleIds: [],
    CountryIds: [],
    LanguageIds: [],
    ContractIds: [],
    Gender: [],
  });
  const [totalUsers, setTotalUsers] = useState("");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleEditMemberModal = (record) => {
    setSelectedRowData(record);
    setShowEditMemberModal(!showEditMemberModal);
  };

  const handleTogglePreviewMemberModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewMemberModal(!showPreviewMemberModal);
  };

  const handleToggleAddMemberModal = () => {
    setShowAddMemberModal(!showAddMemberModal);
  };

  const handleToggleInviteMemberModal = () => {
    setShowInviteMemberModal(!showInviteMemberModal);
  };

  const handleAddMember = async () => {
    try {
      setLoading(true);
      await fetchUsers(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error adding project:", error);
    } finally {
      setLoading(false);
    }
  };

  const roleId = localStorage.getItem("roleId");

  const columns = [
    {
      title: <span className="styledTitle">Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        const nameA = a.name ? a.name.toLowerCase() : "";
        const nameB = b.name ? b.name.toLowerCase() : "";
        return nameA.localeCompare(nameB);
      },
      render: (_, record) => (
        <div className="rowName">
          <div className="circleName">
            <span className="circleLetter">{record.email.charAt(0)}</span>
          </div>
          <div>
            <span className="styledDataIndex1">
              {record.name} {record.lastName}
            </span>
            <br />
            <span className="styledDataIndex2">{record.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: <span className="styledTitle">Gender</span>,
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => a.gender - b.gender,
      render: (gender) => {
        const genderMapping = {
          1: "Male",
          2: "Female",
          3: "Other",
        };
        return (
          <span className="styledDataIndex3">{genderMapping[gender]}</span>
        );
      },
    },
    {
      title: <span className="styledTitle">Languages</span>,
      dataIndex: "languages",
      key: "languages",
      sorter: (a, b) => a.languages.localeCompare(b.languages),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },

    {
      title: <span className="styledTitle">Office Location</span>,
      dataIndex: "office",
      key: "office",
      sorter: (a, b) => a.office.localeCompare(b.office),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Role</span>,
      dataIndex: "roles",
      key: "roles",
      sorter: (a, b) => a.roles.localeCompare(b.roles),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Contract</span>,
      dataIndex: "contracts",
      key: "contract",
      sorter: (a, b) => {
        const contractsA = a.contracts?.join(", ") || "";
        const contractsB = b.contracts?.join(", ") || "";
        return contractsA.localeCompare(contractsB);
      },
      render: (contracts) => (
        <span className="styledDataIndex3">
          {Array.isArray(contracts) ? contracts.join(", ") : ""}
        </span>
      ),
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      render: (text, record) => {
        // Check if the user's role is System Admin
        const isSystemAdmin = record.roles === "System Admin";

        // Only show Edit and Delete if the logged-in user is not a Project Manager or if the user is not System Admin
        if (roleId === RolesEnum.ProjectManager && isSystemAdmin) {
          return null; // Return nothing for System Admin members when logged in as Project Manager
        }

        return (
          <div className="actionDiv">
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewMemberModal(record)}
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={editIcon}
              onClick={() => handleToggleEditMemberModal(record)}
              style={{ cursor: "pointer" }}
            />
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteMember(record.id)}
            ></img>
          </div>
        );
      },
      fixed: isLargeScreen && users.length > 0 ? "right" : undefined,
    },
  ];

  const handleDeleteMember = (id) => {
    setSelectedRowData({ id });
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);

    const params = [
      {
        id: selectedRowData.id,
        isDeleted: true,
      },
    ];

    httpService.delete(
      `/api/User/deleteMembers?isDeleted=true`,
      JSON.stringify(params),
      (response) => {
        const userEmail = Object.keys(response?.data)[0]; // Get the first key (email)
        const message =
          response?.data[userEmail]?.message ||
          "Member has been deleted from your database!";

        setMessage({
          trigger: true,
          type: "success",
          icon: DeleteMemberIcon,
          title: `Member deleted successfully!`,
          text: message,
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        fetchUsers(pagination.current, pagination.pageSize).then(() => {
          setLoading(false);
          setShowDeleteModal(false);
        });
      },
      (error) => {
        console.error("Error deleting member:", error);
        const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem deleting this member from your database, please try again.";
        setMessage({
          trigger: true,
          type: "danger",
          icon: DeleteMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);

        setLoading(false);
        setShowDeleteModal(false);
      }
    );
  };

  const handleTableChange = (newPagination) => {
    fetchUsers(newPagination.current, newPagination.pageSize);
  };

  const fetchUsers = async (current, pageSize) => {
    const params = new URLSearchParams({
      pageNumber: current,
      pageSize: pageSize,
    });

    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        value.forEach((val) => params.append(key, val));
      } else if (value) {
        params.append(key, value);
      }
    }

    const apiUrl = `/api/User/getAllusers?${params.toString()}`;

    setLoading(true);
    httpService.get(
      apiUrl,
      (response) => {
        const usersData = response.data.map((user) => {
          const roles =
            Array.isArray(user.userContractRoles) &&
            user.userContractRoles.length > 0
              ? user.userContractRoles.find((role) => role?.role?.roleName)
                  ?.role?.roleName || ""
              : "";

          const contracts = Array.isArray(user.userContractRoles)
            ? user.userContractRoles
                .filter((ws) => ws?.contract?.name)
                .map((ws) => ws.contract.name)
            : [];

          return {
            id: user.id,
            name: user?.userDetails?.firstName,
            lastName: user?.userDetails?.lastName,
            gender: user?.userDetails?.gender,
            email: user.email,
            office: user?.offices?.map((office) => office.name).join(", "),
            languages: user?.languages
              ?.map((language) => language.name)
              .join(", "),
            roles: roles,
            contracts: contracts,
          };
        });

        setTotalUsers(response.metaData.totalItemCount);

        setUsers(usersData);
        setPagination((prevPagination) => ({
          ...prevPagination,
          current: response.metaData.pageNumber,
          pageSize: response.metaData.pageSize,
          total: response.metaData.totalItemCount,
        }));

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize);
  }, [filters]);

  const handleMembersAdded = (response) => {
    setMessageResponse(response);
    setShowInviteMemberModal(false);
    setShowFailedMembersModal(true);
    fetchUsers(1, pagination.pageSize);
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner text="User Management"></Banner>
      <div className="mainDivUserManagement p-5">
        <div className="userManagementHeader">
          <div className="userManagementHeaderFirst">
            <span className="userManagementHeaderText">Contract members </span>
            <div className="numberOfMembers">
              {loading ? (
                <Spin />
              ) : (
                <>
                  <img src={numberOfMembers}></img>
                  <span style={{ marginLeft: "7px" }}>{totalUsers}</span>
                </>
              )}
            </div>
          </div>
          <div className="userManagementHeaderSecond">
            <Tooltip title="Invite members.">
              <Button
                className="inviteButtonTPM"
                onClick={handleToggleInviteMemberModal}
              >
                <img src={invite}></img>Invite Members
              </Button>
            </Tooltip>
            <Button
              className="addButtonTPM"
              onClick={handleToggleAddMemberModal}
            >
              <img src={add}></img>Add new member
            </Button>
          </div>
        </div>
        <div className="membersText">
          Here’s the list of the members in your contract
        </div>
        <Filters
          filters={filters}
          setFilters={setFilters}
          setPagination={setPagination}
        />
        <div style={{ overflowX: "auto", marginTop: "20px" }}>
          <Table
            // rowSelection={{ ...rowSelection, checkStrictly }}
            dataSource={users}
            columns={columns}
            className="tableTPM"
            loading={loading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: true,
            }}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <AddMember
        visible={showAddMemberModal}
        onClose={handleToggleAddMemberModal}
        onSubmit={handleAddMember}
        setMessage={setMessage}
      />
      <MembersFailed
        visible={showFailedMembersModal}
        onClose={() => setShowFailedMembersModal(false)}
        response={messageResponse}
      />
      <EditMember
        visible={showEditMemberModal}
        onClose={handleToggleEditMemberModal}
        record={selectedRowData}
        onSubmit={handleAddMember}
        setMessage={setMessage}
      />
      <PreviewMember
        visible={showPreviewMemberModal}
        onClose={handleTogglePreviewMemberModal}
        record={selectedRowData}
      />
      <InviteMember
        visible={showInviteMemberModal}
        onClose={handleToggleInviteMemberModal}
        setMessage={setMessage}
        handleMembersAdded={(response) => handleMembersAdded(response)}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        text={
          "You're about to delete this member. This action can’t be undone."
        }
        header={"Delete Member"}
        loading={loading}
      />
    </div>
  );
};

export default UserManagement;
