import { Table } from "antd";
import React, { useEffect, useState } from "react";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import Filters from "./Filters";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";
import EditSiteVisitCardModal from "../../../planning/VisitBoard/Modals/EditSiteVisitCardModal";

function TableView({
  data,
  handleFilterChange,
  loading,
  filters,
  setFilters,
  setDataForExcel,
  onPageChange,
  pagination,
  setPagination,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };

  const statusMappingSiteVisitStatus = (status) => {
    // Ensure status is a number
    const numericStatus = Number(status);
    switch (numericStatus) {
      case 1:
        return {
          text: "No status",
          className: "noStatus",
          fill: "rgba(172, 12, 12, 0.777)",
        };
      case 2:
        return {
          text: "Pre Planning Phase",
          className: "prePlanningPhase",
          fill: "#325899",
        };
      case 3:
        return { text: "Planning", className: "planning", fill: "#964B00" };
      case 4:
        return { text: "Data Collection", className: "dataCollection", fill: "#3B81FE" };
      case 5:
        return { text: "Data Check", className: "dataCheck", fill: "#045B46" };
      case 6:
        return { text: "Visit Completed", className: "visitCompleted", fill: "#0F7507" };
      default:
        return {
          text: "No status",
          className: "noStatus",
          fill: "rgba(172, 12, 12, 0.777)",
        };
    }
  };

  function getMonth(monthNumber) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthIndex = parseInt(monthNumber, 10) - 1;

    return months[monthIndex] || "";
  }

  const columns = [
    {
      title: <span className="styledTitle">Site visit card identifier</span>,
      dataIndex: "identifier",
      key: "identifier",
      ellipsis: true,
      width: 250,
      sorter: (a, b) => (a.identifier || "").localeCompare(b.identifier || ""),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Site name</span>,
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 250,
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">Site visit status</span>,
      dataIndex: "tracking.siteVisitStatus",
      key: "siteVisitStatus",
      width: 250,
      sorter: (a, b) => {
        const statusA = a.tracking?.siteVisitStatus || "";
        const statusB = b.tracking?.siteVisitStatus || "";
        return String(statusA).localeCompare(String(statusB));
      },
      render: (text, record) => {
        const status = record?.tracking?.siteVisitStatus;
        console.log("siteVisitStatus:", status); // Debugging line
        const {
          text: statusText,
          className,
          fill,
        } = statusMappingSiteVisitStatus(status);
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{statusText}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "ipPlan.implementingPartner.name",
      key: "implementingPartner",
      sorter: (a, b) =>
        (a.ipPlan?.implementingPartner?.name || "").localeCompare(
          b.ipPlan?.implementingPartner?.name || ""
        ),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.ipPlan?.implementingPartner?.name}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },

    {
      title: <span className="styledTitle">Cluster</span>,
      dataIndex: "cluster.name",
      key: "cluster",
      sorter: (a, b) =>
        (a.cluster?.name || "").localeCompare(b.cluster?.name || ""),
      render: (text, record) => (
        <span className="styledDataIndex3">{record.cluster?.name}</span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      title: <span className="styledTitle">Date of planned visit</span>,
      dataIndex: "visit.plannedVisit",
      key: "plannedVisit",
      sorter: (a, b) =>
        (a.visit?.plannedVisit || "").localeCompare(
          b.visit?.plannedVisit || ""
        ),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {formatDate(record?.visit?.plannedVisit)}
        </span>
      ),
      ellipsis: true,
      width: 250,
    },
    {
      key: "actions",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      width: 120,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            style={{ cursor: "pointer" }}
            alt="previewicon"
            onClick={() => handleTogglePreviewModal(record.id)}
          />
          {/* <img
            src={editIcon}
            style={{  cursor: "pointer" }}
            alt="editicon"
            onClick={() => handleToggleEditModal(record.id)}
          /> */}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    const formattedData = selectedRows.map((item) => ({
      "Field Team Reporting Status":
        item?.ipPlan?.ipDonorReportingEnum || "N/A",
      "Site visit card identifier": item?.identifier || "N/A",
      IP: item?.ipPlan?.implementingPartner?.name || "N/A",
      Location: item?.locationDetails?.country || "N/A",
      "Site name": item?.identifier || "N/A",
      Cluster: item?.cluster?.name || "N/A",

      "Team name": item?.logistic?.team?.name || "N/A",
      "Reporting Month-Year":
        item?.tracking?.reportingMonth - item?.tracking?.reportingYear || "N/A",
      "Date of planned visit": item?.visit?.plannedVisit || "N/A",
      "Site visit status": item?.siteVisitStatus || "N/A",
      "Training completed":
        (item?.tracking?.trainingIsCompleted ? "YES" : "NO") || "N/A",
      Postponed: (item.logistic?.isPostponed ? "YES" : "NO") || "N/A",
    }));
    setDataForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  return (
    <>
      <Filters onChange={handleFilterChange} setFilters={setFilters} />
      <div>
        <Table
          rowKey="id"
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={loading}
          className="tableTPM"
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
        />
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
      <EditSiteVisitCardModal
        visible={showEditModal}
        onCancel={handleToggleEditModal}
        siteVisitCardId={selectedSiteVisit}
      />
    </>
  );
}

export default TableView;
