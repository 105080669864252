import { Checkbox, Select, Table, Input, Button, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";

import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../../../services/httpService.service";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import Preview from "./modals/Preview";

function TableView({
  redFlags,
  fetchRedFlags,
  loading,
  setLoading,
  pagination,
  onPageChange,
  filters,
}) {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const projectID = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleTogglePreviewModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleDeleteModal = (record) => {
    setSelectedRowData(record);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    const projectID = localStorage.getItem("currentProjectID");
    fetchRedFlags(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.Month,
      filters.Year
    );
  }, [filters, pagination.current, pagination.pageSize]);

  const handleDelete = () => {
    setLoading(true);
    const payload = [
      {
        id: selectedRowData.id,
        isDeleted: false,
      },
    ];
    httpService.delete(
      "/api/RedFlag/deletePostVisits",
      payload,
      () => {
        setShowDeleteModal(false);
        fetchRedFlags(projectID);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Post-visit card deleted successfully!`,
          text: `This post visit card has been deleted and removed from your database!`,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem removing this red flag from your database, please try again!`;
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return `${formattedDate}`;
  };

  const columns = [
    {
      title: <span className="styledTitle">Visit date</span>,
      dataIndex: "visitDate",
      key: "visitDate",
      sorter: (a, b) => a.visitDate.localeCompare(b.visitDate),
      render: (text) => {
        return <span className="styledDataIndex3">{formatDate(text)}</span>;
      },
    },

    {
      title: <span className="styledTitle">Site visit</span>,
      dataIndex: "siteVisitId",
      key: "siteVisitId",
      sorter: (a, b) => a.siteVisitId.localeCompare(b.siteVisitId),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "ip",
      key: "ip",
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Staff</span>,
      dataIndex: "staff",
      key: "staff",
      sorter: (a, b) => a.staff.localeCompare(b.staff),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },

    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && redFlags.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
              alt="previewIcon"
            />
          )}

          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteModal(record)}
              alt="deleteIcon"
            />
          )}
        </div>
      ),
    },
  ];

  const formatData = (redFlag) => {
    return {
      visitDate: redFlag?.date,
      siteVisitId: redFlag?.siteVisit?.identifier,
      ip: redFlag?.implementingPartner?.name,
      staff: redFlag?.staffName,
      id: redFlag?.id,
    };
  };

  const formattedData = redFlags.map(formatData);
  console.log("selected", selectedRowData);
  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      {/* <Filters
        onChange={handleFilterChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        fetchRedFlags={fetchRedFlags}
      /> */}
      <div>
        <Table
          // rowSelection={{ ...rowSelection, checkStrictly }}
          dataSource={formattedData}
          columns={columns}
          className="tableTPM"
          loading={loading}
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
        />
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        record={selectedRowData}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. You can revert it from deleted. "}
        header={"Delete "}
        onConfirm={handleDelete}
        loading={loading}
      />
    </>
  );
}

export default TableView;
