import React, { useEffect, useState } from "react";
import { Button, Tabs, Spin } from "antd";
import DeleteButton from "../../../../../Assets/svg/table-action-delete.svg";
import ExportButton from "../../../../../Assets/svg/exportButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import KanbanView from "./KanbanView/KanbanVIew";
import TableView from "./TableView/TableView";
import DeletedModal from "./TableView/modals/DeletedModal";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import DraftView from "./Drafts/DraftView";
import { exportToExcel } from "../../../../components/ExportToExcel/exportToExcel";

function TripLogistics({
  showAllTripData,
  setShowAllTripData,
  showAllTripDataDrafts,
  fetchTripLogistics,
  fetchTripLogisticsDrafts,
  siteVisits,
  page,
  pagination,
  onPageChange,
  onDraftPageChange,
  draftPagination,
  setPagination,
  setDraftPagination,
  loadingTripLogistic,
  setLoadingTripLogistic,
  handleFilterChange,
  filters,
  setFilters,
  draftCount,
}) {
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleShowDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const handleExportSelected = () => {
    if (selectedRows.length > 0) {
      // Flatten data and remove IDs
      const flattenedRows = selectedRows.map((row) => {
        const { enumeratorInformation, ...rest } = row;

        // Merge enumeratorInformation fields, excluding all "id" fields
        const filteredRow = {
          ...rest,
          ...(enumeratorInformation || {}),
        };

        // Remove all keys that include "id" (case-insensitive)
        Object.keys(filteredRow).forEach((key) => {
          if (key.toLowerCase().includes("id")) {
            delete filteredRow[key];
          }
        });

        return filteredRow;
      });

      // Export the cleaned, flattened data
      exportToExcel(flattenedRows, "Selected_Cards");
    } else {
      alert("Please select rows to export!");
    }
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    fetchTripLogisticsDrafts();
  }, []);

  useEffect(() => {
    if (activeTab === "3") {
      fetchTripLogistics(true);
    } else if (activeTab === "4") {
    } else {
      fetchTripLogistics(false);
    }
  }, [activeTab, filters, pagination.current, pagination.pageSize]);
  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableView
          showAllTripData={showAllTripData}
          fetchTripLogistics={fetchTripLogistics}
          siteVisits={siteVisits}
          pagination={pagination}
          onPageChange={onPageChange}
          setPagination={setPagination}
          loadingTripLogistic={loadingTripLogistic}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanView
          showAllTripData={showAllTripData}
          setShowAllTripData={setShowAllTripData}
          fetchTripLogistics={fetchTripLogistics}
          page={page}
          pagination={pagination}
          onPageChange={onPageChange}
          setPagination={setPagination}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarView
          showAllTripData={showAllTripData}
          fetchTripLogistics={fetchTripLogistics}
          pagination={pagination}
          onDraftPageChange={onDraftPageChange}
          setPagination={setPagination}
          filters={filters}
          setFilters={setFilters}
        />
      ),
    },
    {
      key: "4",
      label: (
        <span className="custom-tab-label">
          Trip Logistics Drafts{" "}
          {loading ? (
            <span className="loader">
              <Spin />
            </span>
          ) : (
            <span className="draft-count">{draftCount}</span>
          )}
        </span>
      ),
      children: (
        <DraftView
          showAllTripDataDrafts={showAllTripDataDrafts}
          fetchTripLogisticsDrafts={fetchTripLogisticsDrafts}
          pagination={pagination}
          onDraftPageChange={onDraftPageChange}
          setDraftPagination={setDraftPagination}
          loadingTripLogistic={loadingTripLogistic}
          draftPagination={draftPagination}
          filters={filters}
          setFilters={setFilters}
          siteVisits={siteVisits}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent p-5">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Trip Logistics</span>
        <div className="tableViewHeaderButtons">
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <>
              <Button
                className="inviteButtonTPM"
                onClick={handleShowDeletedModal}
              >
                <img src={DeleteButton} alt="DeleteButton" />
                See deleted cards
              </Button>
              <Button className="addButtonTPM" onClick={handleExportSelected}>
              <img src={ExportButton} alt="ExportButton" />
                Export Selected Cards
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="tabsHeaderFieldLogistics">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
      <DeletedModal
        visible={isDeletedModalVisible}
        onCancel={() => {
          setIsDeletedModalVisible(false);
        }}
        onOk={handleShowDeletedModal}
        fetchTripLogistics={fetchTripLogistics}
      />
    </div>
  );
}

export default TripLogistics;
