import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import { Button, Spin, Tabs } from "antd";
import PlanningTableView from "./PlanningTableView";
import AddModal from "./modals/AddModal";
import { useEffect, useState } from "react";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import KanbanView from "./KanbanView/KanbanView";
import CalendarViewPlanning from "./CalendarView/CalendarView";
import AddSiteVisitCardModal from "./VisitBoard/Modals/AddSiteVisitCardModal";
import { exportToExcel } from "../../components/ExportToExcel/exportToExcel";
import IpPlanDraftsTable from "./IpPlanDraftsTable";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import { httpService } from "../../../services/httpService.service";

const IpPlanningBoard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showSiteVisitModal, setShowSiteVisitModal] = useState(false);
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [ipPlansData, setIpPlansData] = useState([]);
  const [ipPlanDraftsData, setIpPlansDraftsData] = useState([]);
  const [dataFromChild, setDataFromChild] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draftCount, setDraftCount] = useState(0);
  const [draftsPagination, setDraftsPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Options for formatting
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format; set to true for 12-hour format
    };

    // Format the date
    return date.toLocaleString("en-US", options);
  }

  const fetchData = async (
    pageNumber = draftsPagination.current,
    pageSize = draftsPagination.pageSize
  ) => {
    const projectId = localStorage.getItem("currentProjectID");

    let apiUrl = `/api/Draft/getAll?Type=2&ProjectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

    setLoading(true);
    httpService.get(
      apiUrl,
      (response) => {
        const formattedData = response.data.map((item) => {
          const ipPlan = JSON.parse(item.jsonData);
          return {
            key: item.id,
            ip: ipPlan?.implementingPartner?.name || "N/A",
            assignedTo: ipPlan?.assignedToId?.email || "N/A",
            ipPlanId: ipPlan?.ipPlanId || "N/A",
            clusters:
              ipPlan?.clusterId?.map((cluster) => cluster.name).join(", ") ||
              "N/A",
            currentIpStatus: ipPlan?.currentIpStatus || 0,
            office: ipPlan?.name || "N/A",
            month: ipPlan?.planningRound?.month || "N/A", // Fix mapping here
            year: ipPlan?.planningRound?.year || "N/A", // Fix mapping here
            pointOfContactName: ipPlan?.pointOfContact?.name || "N/A",
            pointOfContactEmail: ipPlan?.pointOfContact?.email || "N/A",
            pointOfContactRole: ipPlan?.pointOfContact?.role || "N/A",
            pointOfContactOrganization:
              ipPlan?.pointOfContact?.organization || "N/A",
            pointOfContactPhone: ipPlan?.pointOfContact?.phone || "N/A",
            lastUpdateDate: item.updatedDate
              ? formatDate(item.updatedDate)
              : formatDate(item.createdDate) || "No Date Available",
          };
        });
        setIpPlansDraftsData(formattedData);

        setDraftCount(response.metaData.totalItemCount);

        setDraftsPagination((prevPagination) => ({
          ...prevPagination,
          current: response?.metaData?.pageNumber,
          pageSize: response?.metaData?.pageSize,
          total: response?.metaData?.totalItemCount,
        }));

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    );
  };

  const handleDataLoaded = (data) => {
    console.log(data);
    setDataFromChild(data);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddIpPlan = (newPlan) => {
    setIpPlansData((prevPlans) => [...prevPlans, newPlan]);
    fetchData();
    if (newPlan === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="ip-planning-wrapper">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <div className="text-title">
        <h3 className="">IP Planning Board</h3>
        <div className="ip-planning-buttons">
          <Button
            className={`export-selectedBtn ${
              removeExportButton ? "d-none" : ""
            }`}
            onClick={() => {
              if (dataFromChild.length !== 0) {
                const filteredData = dataFromChild.map((item) => {
                  const {
                    ipplan,
                    ip,
                    assignedTo,
                    clusters,
                    initialMeeting,
                    generalInfo,
                    office,
                    month,
                    year,
                    pointOfContactName,
                    pointOfContactEmail,
                    pointOfContactRole,
                    pointOfContactOrganization,
                    pointOfContactPhone,
                  } = item;

                  return {
                    ipplan,
                    ip,
                    assignedTo,
                    clusters,
                    initialMeeting,
                    office,
                    generalInfo,
                    month,
                    year,
                    pointOfContactName,
                    pointOfContactEmail,
                    pointOfContactRole,
                    pointOfContactOrganization,
                    pointOfContactPhone,
                  };
                });

                console.log(filteredData); // Inspect this output to check if fields are populated correctly
                exportToExcel(filteredData, "IP-planning");
              } else {
                setMessage({
                  trigger: true,
                  type: "danger",
                  icon: "",
                  title: "Oops! A problem has occurred!",
                  text: "Please select which IP plan's you want to export!",
                });
                setTimeout(() => {
                  setMessage({
                    trigger: false,
                    type: "",
                    icon: "",
                    title: "",
                    text: "",
                  });
                }, 3000);
              }
            }}
          >
            <UploadOutlined />
            Export selected cards
          </Button>

          {isAuthorized([
            RolesEnum.SystemAdmin,
            RolesEnum.ProjectManager,
            RolesEnum.FieldCoordinator,
          ]) && (
            <Button className="add-new-ipBtn" onClick={showModal}>
              <PlusOutlined />
              Add new IP plan card
            </Button>
          )}
          {/* <Button
            className="addButtonTPM" onClick={handleAddSiteVisitModal}
          >
            <PlusOutlined />
            Add new site visit card
          </Button> */}
          <AddModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            setMessage={setMessage}
            onAdd={handleAddIpPlan}
          />
          <AddSiteVisitCardModal
            visible={showSiteVisitModal}
            onCancel={() => setShowSiteVisitModal(false)}
          />
        </div>
      </div>
      <div className="tab-container">
        <Tabs
          className="tableviewTabs"
          defaultActiveKey="1"
          onChange={handleAddIpPlan}
          items={[
            {
              label: "Table view",
              key: "1",
              children: (
                <PlanningTableView
                  ipPlansData={ipPlansData}
                  onDataLoaded={handleDataLoaded}
                />
              ),
            },
            {
              label: "Kanban view",
              key: "2",
              children: <KanbanView ipPlansData={ipPlansData}></KanbanView>,
            },
            {
              label: "Calendar view",
              key: "3",
              children: <CalendarViewPlanning ipPlansData={ipPlansData} />,
            },

            {
              label: (
                <span className="custom-tab-label">
                  IP Plan drafts{" "}
                  {loading ? (
                    <span className="loader">
                      <Spin />
                    </span>
                  ) : (
                    <span className="draft-count">{draftCount}</span>
                  )}
                </span>
              ),
              key: "4",
              children: (
                <IpPlanDraftsTable
                  data={ipPlanDraftsData}
                  setData={setIpPlansDraftsData}
                  loading={loading}
                  setLoading={setLoading}
                  draftsPagination={draftsPagination}
                  setDraftsPagination={setDraftsPagination}
                  fetchData={fetchData}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default IpPlanningBoard;
