import { Card, Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import { useState } from "react";

const { Option } = Select;

//months
const months = [
  {
    id: "01",
    name: "January",
  },
  {
    id: "02",
    name: "February",
  },
  {
    id: "03",
    name: "March",
  },
  {
    id: "04",
    name: "April",
  },
  {
    id: "05",
    name: "May",
  },
  {
    id: "06",
    name: "June",
  },
  {
    id: "07",
    name: "July",
  },
  {
    id: "08",
    name: "August",
  },
  {
    id: "09",
    name: "September",
  },
  {
    id: "10",
    name: "October",
  },
  {
    id: "11",
    name: "November",
  },
  {
    id: "12",
    name: "December",
  },
];

//years
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 11 }, (_, i) => `${currentYear + i}`);

const TrackingDetails = ({ enumerators, setEnumerators }) => {
  const handleScoreChange = (id, value) => {
    setEnumerators((prevEnumerators) =>
      prevEnumerators.map((enumerator) =>
        enumerator.id === id
          ? { ...enumerator, trainingScore: value ? parseFloat(value) : "" }
          : enumerator
      )
    );
  };

  const handlePercentageChange = (id, value) => {
    setEnumerators((prevEnumerators) =>
      prevEnumerators.map((enumerator) =>
        enumerator.id === id
          ? {
              ...enumerator,
              percentageCompleted: value ? parseFloat(value) : "",
            }
          : enumerator
      )
    );
  };

  return (
    <div>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="reportingYear" label="Reporting year" required>
            <Select placeholder="Select reporting year" showSearch>
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="reportingMonth" label="Reporting month" required>
            <Select placeholder="Select reporting month" showSearch>
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Training Completed"}
            name="trainingIsCompleted"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="trainingDate" label="Training date" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8} />
      </Row>

      <div className="d-flex flex-column gap-3 mb-5 mt-4">
        <h3 className="m-0 p-0">Training scores</h3>
        {enumerators?.map((enumerator, index) => (
          <div
            key={enumerator?.id}
            className="d-flex justify-content-between align-items-center w-100"
            style={{
              padding: "10px 16px",
              alignSelf: "stretch",
              borderRadius: "8px",
              border: "1px solid var(--5, #F5B0D2)",
              background: "var(--7, #FFF5FA)",
            }}
          >
            <div className="d-flex justify-content-start align-items-center h-100 w-100">
              <p
                className="m-0"
                style={{
                  color: "var(--Neutrals-Black, #3A3737)",
                  fontFamily: "Lato",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "22px",
                }}
              >
                {enumerator?.firstName && enumerator?.lastName
                  ? enumerator?.firstName + " " + enumerator?.lastName
                  : enumerator?.email}
              </p>
            </div>

            <Form.Item
              name={`trainingScore-${index}`}
              className="m-0 p-0"
              label="Training score"
              style={{ width: "178px" }}
              required
            >
              <Input
                placeholder="1"
                min={0}
                type="number"
                value={parseInt(enumerator?.trainingScore)}
                onChange={(e) => {
                  handleScoreChange(enumerator.id, e.target.value);
                }}
              />
            </Form.Item>
          </div>
        ))}
      </div>

      <Row gutter={24}>
        <Col span={16}>
          <Form.Item
            name="actualDateOfVisit"
            label="Actual date of visit"
            required
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>

      <div className="d-flex flex-column gap-3 mb-5 mt-4">
        <h3 className="m-0 p-0">% of data collection completed</h3>
        {enumerators?.map((enumerator, index) => (
          <div
            key={enumerator?.id}
            className="d-flex justify-content-between align-items-center w-100"
            style={{
              padding: "10px 16px",
              alignSelf: "stretch",
              borderRadius: "8px",
              border: "1px solid var(--5, #F5B0D2)",
              background: "var(--7, #FFF5FA)",
            }}
          >
            <div className="d-flex justify-content-start align-items-center h-100 w-100">
              <p
                className="m-0"
                style={{
                  color: "var(--Neutrals-Black, #3A3737)",
                  fontFamily: "Lato",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "22px",
                }}
              >
                {enumerator?.firstName && enumerator?.lastName
                  ? enumerator?.firstName + " " + enumerator?.lastName
                  : enumerator?.email}
              </p>
            </div>

            <Form.Item
              name={`percentageCompleted-${index}`}
              className="m-0 p-0"
              label="% Completed"
              style={{ width: "178px" }}
              required
            >
              <Input
                placeholder="1"
                min={0}
                type="number"
                value={parseInt(enumerator?.percentageCompleted)}
                onChange={(e) => {
                  handlePercentageChange(enumerator.id, e.target.value);
                }}
              />
            </Form.Item>
          </div>
        ))}
      </div>

      <Row gutter={24}></Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="dateOfReport" label="Date of report" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfDebrief" label="Date of debrief" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="siteVisitStatus" label="Site visit status" required>
            <Select placeholder="Select status">
              <Option value={1}>No status</Option>
              <Option value={2}>Pre-planning phase</Option>
              <Option value={3}>Planning</Option>
              <Option value={4}>Data collection</Option>
              <Option value={5}>Post data collection/Data check</Option>
              <Option value={6}>Visit completed</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Col span={8}>
        <Form.Item
          name="fieldTeamReportingStatus"
          label="Reporting Status"
          required
        >
          <Select placeholder="Select a status">
            <Option value={1}>Data collection</Option>
            <Option value={2}>Data check</Option>
            <Option value={3}>Field report writing</Option>
            <Option value={4}>Translation</Option>
            <Option value={5}>Field reporting completed</Option>
            <Option value={6}>Field report approved</Option>
            <Option value={7}>Field report submitted to client</Option>
          </Select>
        </Form.Item>
      </Col>
    </div>
  );
};
export default TrackingDetails;
