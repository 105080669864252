import React, { useEffect, useState } from "react";
import "./Flags.scss";
import { Button, Spin, Tabs, Tooltip } from "antd";
import AddButton from "../../../Assets/svg/addButton.svg";
import DeleteButton from "../../../Assets/svg/table-action-delete.svg";
import CalendarView from "./CalendarView/CalendarView";
import TableView from "./TableView/TableView";
import PostVisitCardModal from "./Modals/PostVisitCardModal";
import AddRedFlagCardModal from "./Modals/AddRedFlagCardModal";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import KanbanView from "./KanbanView/KanbanVIew";
import { httpService } from "../../../services/httpService.service";
import DeletedModal from "./Modals/DeletedModal";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import DraftTableView from "./Drafts/TableView/TableView";

function Flags() {
  const [data, setData] = useState([]);
  const [showRedFlagModal, setshowRedFlagModal] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redFlags, setRedFlags] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [activeTab, setActiveTab] = useState("");
  const [draftCount, setDraftCount] = useState(0);
  const [draftPagination, setDraftPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    Search: "",
    State: "",
    ImplementingPartnerId: "",
  });

  const handleShowDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const handleShowRedFlagModal = () => {
    setshowRedFlagModal(!showRedFlagModal);
  };

  const handleCloseRedFlagModal = () => {
    setshowRedFlagModal(false);
  };

  const [showVisitCardModal, setshowVisitCardModal] = useState(false);

  const handleShowVisitCard = () => {
    setshowVisitCardModal(!showVisitCardModal);
  };

  const handleCloseVisitCardModal = () => {
    setshowVisitCardModal(false);
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (activeTab === "3") {
      fetchRedFlags(true);
    } else {
      fetchRedFlags(false);
    }
  }, [activeTab, filters, pagination.current, pagination.pageSize]);

  const fetchRedFlags = (ignorePagination) => {
    setLoading(true);

    const params = {
      Search: filters.Search,
      State: filters.State,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      IgnorePagination: ignorePagination,
    };
    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    httpService.get(
      `/api/RedFlag/getAllRedFlags?${queryString}`,

      (response) => {
        const redFlagsData = response?.data;

        if (redFlagsData.length > 0) {
          setRedFlags(redFlagsData);
        } else {
          setRedFlags([]);
        }
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching red flags:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [key]: value };
      return updatedFilters;
    });
    setPagination({ ...pagination, current: 1 });
  };

  const fetchDraftData = async (current, pageSize) => {
    const projectId = localStorage.getItem("currentProjectID");

    let apiUrl = `/api/Draft/getAll?Type=3&ProjectId=${projectId}&pageNumber=${current}&pageSize=${pageSize}`;

    const activeFilters = filters || {};

    for (const [key, value] of Object.entries(activeFilters)) {
      if (value) {
        apiUrl += `&${key}=${value}`;
      }
    }

    setLoading(true);
    httpService.get(
      apiUrl,
      (response) => {
        const formattedData = response.data.map((item) => {
          const redFlag = JSON.parse(item.jsonData);
          return {
            key: item.id,
            ipTag: redFlag?.ImplementingPartner?.name || "N/A",
            name: redFlag?.Name || "N/A",
            description: redFlag?.Description || "N/A",
            siteVisitID: redFlag?.SiteVisitId?.identifier || "N/A",
            dateOfSiteVisit: redFlag?.DateOfSiteVisit || "N/A",
            office: redFlag?.OfficeId?.name || "N/A",
            dueDate: redFlag?.DueDate || "N/A",
            assignedTo:
              `${redFlag?.AssignedTo?.userDetails?.firstName} ${redFlag?.AssignedTo?.userDetails?.lastName}` ||
              redFlag?.AssignedTo?.userName ||
              "N/A",
            status: redFlag?.FlagStatus || "N/A",
          };
        });
        setData(formattedData);

        setDraftPagination((prevDraftPagination) => ({
          ...prevDraftPagination,
          current: response?.metaData?.pageNumber,
          pageSize: response?.metaData?.pageSize,
          total: response?.metaData?.totalItemCount,
        }));

        setDraftCount(response?.metaData?.totalItemCount);

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    );
  };

  const handleDraftPageChange = (page, pageSize) => {
    setDraftPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));

    // Fetch data for the new page and pageSize
    fetchDraftData(page, pageSize);
  };

  // This will return which page is going to be rendered
  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableView
          redFlags={redFlags}
          setRedFlags={setRedFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanView
          redFlags={redFlags}
          setRedFlags={setRedFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarView
          redFlags={redFlags}
          fetchRedFlags={fetchRedFlags}
          loading={loading}
          setLoading={setLoading}
          pagination={pagination}
          setPagination={setPagination}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          handleFilterChange={handleFilterChange}
        />
      ),
    },
    {
      key: "4",
      label: (
        <span className="custom-tab-label">
          Red Flag drafts{" "}
          {loading ? (
            <span className="loader">
              <Spin />
            </span>
          ) : (
            <span className="draft-count">{draftCount}</span>
          )}
        </span>
      ),
      children: (
        <DraftTableView
          data={data}
          loading={loading}
          setLoading={setLoading}
          draftPagination={draftPagination}
          setDraftPagination={setDraftPagination}
          onDraftPageChange={handleDraftPageChange}
          handleFilterChange={handleFilterChange}
          fetchRedFlags={fetchRedFlags}
          fetchDraftData={fetchDraftData}
        />
      ),
    },
  ];

  return (
    <div className="flagsPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      {/* <div className="banner-container">
        <div className="banner-text">Flags</div>
        <div className="banner-photo"></div>
      </div> */}
      <div className="tableViewcontainer">
        <div className="tableHeader">
          <span className="tableViewHeaderText">Red Flags Board</span>
          <div className="tableViewHeaderButtons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Button
                className="inviteButtonTPM"
                onClick={handleShowDeletedModal}
              >
                <img src={DeleteButton} alt="DeleteButton" />
                See deleted cards
              </Button>
            )}
            {/* {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldTeamLeader,
            ]) && (
              <Button
                className="exportButtonTPM addButtonTPM text-white"
                onClick={handleShowVisitCard}
              >
                <img src={AddButton} alt="AddButton" />
                Add new post-visit card
              </Button>
            )} */}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldTeamLeader,
            ]) && (
              <Button className="addButtonTPM" onClick={handleShowRedFlagModal}>
                <img src={AddButton} alt="addButtonTPM" />
                Add new red flag
              </Button>
            )}
            <PostVisitCardModal
              visible={showVisitCardModal}
              onClose={handleCloseVisitCardModal}
              setMessage={setMessage}
            />
            <AddRedFlagCardModal
              visible={showRedFlagModal}
              onClose={handleCloseRedFlagModal}
              setMessage={setMessage}
              fetchRedFlags={fetchRedFlags}
              // fetchRedFlagsDrafts={fetchRedFlagsDrafts}
              fetchDraftData={fetchDraftData}
            />
            <DeletedModal
              visible={isDeletedModalVisible}
              onCancel={() => {
                setIsDeletedModalVisible(false);
              }}
              onOk={handleShowDeletedModal}
              fetchRedFlags={fetchRedFlags}
            />
          </div>
        </div>
        <div className="tabsHeader">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="customTabs"
          />
        </div>
      </div>
    </div>
  );
}

export default Flags;
