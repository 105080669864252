import React, { useState, useEffect } from "react";
import { Table, Button, Spin } from "antd";
import "./IpManagement.scss";
import Banner from "../../components/Banner/Banner";
import invite from "../../../Assets/svg/inviteButton.svg";
import add from "../../../Assets/svg/addButton.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../Assets/svg/table-action-preview.svg";
import numberOfMembers from "../../../Assets/svg/numberOfMembers.svg";
import deleteMemberIcon from "../../../Assets/svg/deleteMemberIcon.svg";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import PreviewIp from "./PreviewIp";
import EditIp from "./EditIp";
import AddNewIp from "./AddNewIp";
import TableFilters from "./TableFilters";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import { httpService } from "../../../services/httpService.service";
import { isAuthorized } from "../../../services/authService";
import { RolesEnum } from "../../../services/rolesEnum";

const IpManagement = () => {
  const [showAddIpModal, setShowAddIpModal] = useState(false);
  const [showEditIpModal, setShowEditIpModal] = useState(false);
  const [showPreviewIpModal, setShowPreviewIpModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const workspaceId = localStorage.getItem("currentContractId");
  const [loading, setLoading] = useState(false);
  const [ips, setIPs] = useState([]);
  const [totalIPs, setTotalIPs] = useState(0);
  const [filters, setFilters] = useState({
    search: "",
    CityId: "",
    StateId: "",
    CountryId: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleEditIpModal = (record) => {
    setSelectedRowData(record);
    setShowEditIpModal(!showEditIpModal);
  };

  const handleTogglePreviewIpModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewIpModal(!showPreviewIpModal);
  };

  const handleToggleAddIpModal = () => {
    setShowAddIpModal(!showAddIpModal);
  };

  const fetchIP = (current, pageSize) => {
    setLoading(true);
    const params = {
      contractId: workspaceId,
      search: filters.search,
      StateId: filters.StateId,
      CityId: filters.CityId,
      CountryId: filters.CountryId,
      pageNumber: current,
      pageSize: pageSize,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    httpService.get(
      `/api/ImplementingPartner/getAllImplementingPartners?${queryString}`,
      (response) => {
        console.log("RESPONSEEE: ", response);
        const data = response.data?.map((item) => ({
          key: item?.id,
          id: item?.id,
          name: item?.name,
          cities: item?.cities?.map((city) => city.name).join(", ") || "N/A",
          states:
            item?.states?.map((state) => state.stateName).join(", ") || "N/A",
          country: item?.cities?.[0]?.country
            ? item?.cities?.[0]?.country?.stateName
            : item?.states?.[0]?.country.stateName,
        }));

        setIPs(data);
        setPagination((prevPagination) => ({
          ...prevPagination,
          current: response.metaData?.pageNumber,
          pageSize: response.metaData?.pageSize,
          total: response.metaData?.totalItemCount,
        }));
        setTotalIPs(response.metaData?.totalItemCount);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching IPs:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchIP(pagination.current, pagination.pageSize);
  }, [filters, pagination.current, pagination.pageSize]);

  const columns = [
    {
      title: <span className="styledTitle">IP Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <span className="styledDataIndex">{text}</span>,
    },
    {
      title: <span className="styledTitle">Country</span>,
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => {
        const countryA = a.country ? a.country.toString() : "";
        const countryB = b.country ? b.country.toString() : "";
        return countryA.localeCompare(countryB, undefined, {
          sensitivity: "base",
        });
      },
      render: (text) => <span className="styledDataIndex">{text}</span>,
    },
    {
      title: <span className="styledTitle">Governorate/State</span>,
      dataIndex: "states",
      key: "states",
      sorter: (a, b) => a.states.localeCompare(b.states),
      render: (text) => <span className="styledDataIndex">{text}</span>,
    },
    {
      title: <span className="styledTitle">City/Disctrict/Municipality</span>,
      dataIndex: "cities",
      key: "cities",
      sorter: (a, b) => a.cities.localeCompare(b.cities),
      render: (text) => <span className="styledDataIndex">{text}</span>,
    },
    {
      key: "action",
      fixed: isLargeScreen && ips?.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewIpModal(record)}
              style={{ cursor: "pointer" }}
              alt="Preview"
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditIpModal(record)}
              style={{ cursor: "pointer" }}
              alt="Edit"
            />
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteIP(record.id)}
              alt="Delete"
            />
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    }));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  const [checkStrictly, setCheckStrictly] = useState(false);

  const handleAddEditIp = async () => {
    try {
      console.log("Setting loading to true");
      setLoading(true);
      await fetchIP(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error adding project:", error);
    } finally {
      console.log("Setting loading to false");
      // setLoading(false);
    }
  };

  const handleDeleteIP = (id) => {
    setSelectedRowData({ id }); // Store the ID of the member to be deleted
    setShowDeleteModal(true); // Show the delete confirmation modal
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    const itemToDeleteId = selectedRowData.id;
    const params = [
      {
        id: itemToDeleteId,
        isDeleted: true,
      },
    ];

    await httpService.delete(
      `/api/ImplementingPartner/deleteImplementingPartners`,
      params,
      (response) => {
        const message =
          response?.data?.message ||
          response?.message ||
          "You have successfully deleted this implementing partner from your database!";

        console.log("Message: ", message);
        setMessage({
          trigger: true,
          type: "success",
          icon: deleteMemberIcon,
          title: "Implementing partner deleted successfully",
          text: message,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setShowDeleteModal(false);
        fetchIP(pagination.current, pagination.pageSize);
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem deleting this implementing partner! Please try again later!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: deleteMemberIcon,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        console.error("Error deleting member:", error);
        setLoading(false);
        setShowDeleteModal(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner text="IP Management"></Banner>
      <div className="mainDivUserManagement px-5">
        <div className="userManagementHeader">
          <div className="userManagementHeaderFirst">
            <span className="userManagementHeaderText">Linked IPs</span>
            <div className="numberOfMembers">
              <img src={numberOfMembers} alt="Members" />
              <span style={{ marginLeft: "7px" }}>
                {loading ? <Spin size="small" /> : totalIPs}
              </span>
            </div>
          </div>
          <div className="userManagementHeaderSecond">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldLogistics,
            ]) && (
              <Button className="addButtonTPM" onClick={handleToggleAddIpModal}>
                <img src={add} alt="Add" />
                Add new IP
              </Button>
            )}
          </div>
        </div>
        <div className="membersText">
          Here’s the list of the IPs in your workspace
        </div>
        <TableFilters
          filters={filters}
          setFilters={setFilters}
          setPagination={setPagination}
        />
        <div style={{ overflowX: "auto", marginTop: "20px" }}>
          <Table
            // rowSelection={{ ...rowSelection, checkStrictly }}
            dataSource={ips}
            columns={columns}
            className="tableTPM"
            loading={loading}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: true,
            }}
            onChange={handleTableChange} // Add this line
          />
        </div>
      </div>
      <AddNewIp
        visible={showAddIpModal}
        onClose={handleToggleAddIpModal}
        onSubmit={handleAddEditIp}
        setMessage={setMessage}
      />
      <EditIp
        visible={showEditIpModal}
        onClose={handleToggleEditIpModal}
        record={selectedRowData}
        onSubmit={handleAddEditIp}
        setMessage={setMessage}
      />
      <PreviewIp
        visible={showPreviewIpModal}
        onClose={handleTogglePreviewIpModal}
        record={selectedRowData}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
        text={"You're about to delete this IP. This action can’t be undone."}
        header={"Delete IP"}
      />
    </div>
  );
};

export default IpManagement;
