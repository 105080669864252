import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Divider,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../../services/httpService.service";

const { Option } = Select;

const Preview = ({ visible, onClose, driverId }) => {
  const [form] = Form.useForm();
  const [loadingPage, setLoadingPage] = useState(false);
  const [driver, setDriver] = useState([]);
  useEffect(() => {
    setLoadingPage(true);
    if (driverId) {
      const params = { driverId };
      const queryParams = new URLSearchParams(params).toString();

      httpService.get(
        `/api/Driver?${queryParams}`,
        (response) => {
          const driver = response?.data;
          setDriver({
            driverName: driver?.name,
            driverPhoneNumber: driver?.phoneNumber,
            vehicleInfo: driver?.vehicleInfo,
            driverBaseLocation: driver?.baseLocation?.stateName,
            cities: driver?.cities,
            states: driver?.states,
          });
          setLoadingPage(false);
        },
        (error) => {
          console.error("Error fetching driver data:", error);
          setLoadingPage(false);
        }
      );
    }
  }, [driverId, form]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Form form={form} layout="vertical">
        {" "}
        <Spin spinning={loadingPage}>
          <p className="modalsHeader1">Personal Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="driverName"
              label={<span style={{ fontWeight: "bold" }}>Driver Name</span>}
            >
              <p className="previewText">{driver.driverName}</p>
            </Form.Item>
            <Form.Item
              name="driverPhone"
              label={<span style={{ fontWeight: "bold" }}>Driver Phone</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">+{driver.driverPhoneNumber}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="driverVehicleInfo"
              label={
                <span style={{ fontWeight: "bold" }}>Driver Vehicle Info</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{driver.vehicleInfo}</p>
            </Form.Item>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="mainLocation"
              label={
                <span style={{ fontWeight: "bold" }}>Driver base country</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{driver.driverBaseLocation}</p>
            </Form.Item>
            {driver?.states?.length > 0 && (
              <Form.Item
                name="driverVehicleInfo"
                label={
                  <span style={{ fontWeight: "bold" }}>
                    Governorate/State or city
                  </span>
                }
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {driver?.states?.map((state) => state?.stateName).join(", ")}
                </p>
              </Form.Item>
            )}
          </div>

          {driver?.cities?.length > 0 && (
            <Form.Item
              name="mainLocation"
              label={
                <span style={{ fontWeight: "bold" }}>
                  City/Disctrict/Municipality
                </span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {driver?.cities?.map((city) => city?.name).join(", ")}
              </p>
            </Form.Item>
          )}
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default Preview;
