import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Divider,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { httpService } from "../../../../../../services/httpService.service";
import showConfirm from "../../../../../components/ExitConfirm/ExitConfirm";

const { Option } = Select;

const Edit = ({ visible, onClose, driverId, setMessage, fetchDrivers }) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    const contractId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/GeoData/getCountriesOfContract?contractId=${contractId}`,
      (response) => {
        setCountries(response.data);
      },
      (error) => {
        console.error("Error fetching teams:", error);
      }
    );
  }, []);

  useEffect(() => {
    setLoadingPage(true);
    if (driverId) {
      const params = { driverId };
      const queryParams = new URLSearchParams(params).toString();

      httpService.get(
        `/api/Driver?${queryParams}`,
        (response) => {
          const driver = response?.data;
          const countryId = driver?.baseLocation?.id;
          const stateIds = driver?.states?.map((state) => state?.id) || [];
          const cityIds = driver?.cities?.map((city) => city?.id) || [];
          form.setFieldsValue({
            driverName: driver?.name,
            driverPhoneNumber: driver?.phoneNumber,
            vehicleInfo: driver?.vehicleInfo,
            driverBaseLocation: countryId,
            state: stateIds,
            city: cityIds,
          });
          fetchStates(countryId);
          fetchCities(stateIds);
          setLoadingPage(false);
        },
        (error) => {
          console.error("Error fetching driver data:", error);
          setLoadingPage(false);
        }
      );
    }
  }, [driverId, form]);

  const handleOk = () => {
    setIsSubmitting(true);
    form.validateFields().then((values) => {
      const payload = {
        id: driverId,
        name: values.driverName,
        phoneNumber: values.driverPhoneNumber,
        vehicleInfo: values.vehicleInfo,
        countryId: values.driverBaseLocation,
        stateIds: values.state,
        cityIds: values.city,
        license: 1,
      };
      httpService.put(
        "/api/Driver",
        payload,
        (response) => {
          const message = response.message || "Driver updated successfully!";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Driver added successfully!",
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          form.resetFields();
          onClose();
          fetchDrivers();
          setIsSubmitting(false);
        },
        (error) => {
          const messageError = error?.errors?.[0]?.message;
          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text:
              messageError ||
              "We had a problem deleting this driver from your database, please try again.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          setIsSubmitting(false);
        }
      );
    });
  };

  const fetchStates = (countryId) => {
    setCitiesLoading(true);
    httpService.get(
      `/api/GeoData/states?countryIds=${[countryId]}`,
      (res) => {
        setStates(res?.data);
        setCitiesLoading(false);
      },
      (error) => {
        console.log(error);
        setCitiesLoading(false);
      }
    );
  };

  const handleCountryChange = (countryId) => {
    form.resetFields(["city", "state"]);
    if (countryId) {
      fetchStates(countryId);
    } else {
      setStates([]);
      setCities([]);
    }
  };

  const fetchCities = (stateIds) => {
    const params = new URLSearchParams();
    stateIds.forEach((id) => params.append("stateIds", id));
    if (stateIds?.length > 0) {
      httpService.get(
        `/api/GeoData/cities?${params}`,
        (res) => {
          const citiesData = res.data;
          setCities(citiesData);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      setCities([]);
    }
  };

  const handleStateChange = (stateId) => {
    form.resetFields(["city"]);
    if (stateId) {
      fetchCities(stateId);
    } else {
      setCities([]);
    }
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit driver information</h2>
      <Form form={form} layout="vertical">
        {" "}
        <Spin spinning={loadingPage}>
          <p className="modalsHeader1">General details</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              name="driverName"
              label="Driver Name"
              rules={[{ required: true, message: "Please enter name" }]}
            >
              <Input placeholder="Enter driver name" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="driverPhoneNumber"
              label="Driver phone number"
              rules={[{ required: true, message: "Enter the phone number" }]}
              style={{ width: "48%" }}
            >
              <PhoneInput country={"us"} />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              name="vehicleInfo"
              label="Driver Vehicle Info"
              rules={[{ required: true, message: "Enter the vehicle info" }]}
            >
              <Input placeholder="Enter Name" autoComplete="off" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              name="driverBaseLocation"
              label="Driver base country"
              rules={[{ required: true, message: "Select a country" }]}
            >
              <Select
                placeholder="Select a country"
                allowClear
                showSearch
                onChange={handleCountryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().startsWith(input.toLowerCase())
                }
              >
                {countries?.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Spin spinning={citiesLoading}>
              <Form.Item
                name="state"
                label={
                  (cities?.length ?? 0) < 1
                    ? "Governorate/State or city"
                    : "Governorate/State"
                }
                rules={[
                  {
                    required: true,
                    message:
                      cities?.length > 0
                        ? "Please select at least a state"
                        : "Please select at least a state or a city!",
                  },
                ]}
              >
                {/* <Input placeholder="Enter Name" autoComplete="off" /> */}
                <Select
                  placeholder={
                    cities?.length > 0
                      ? "Please select at least a state"
                      : "Please select at least a state or a city!"
                  }
                  allowClear
                  mode="multiple"
                  onChange={handleStateChange}
                  showSearch
                  disabled={states?.length < 1}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {states?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.stateName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Spin>
          </div>
          {cities?.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Form.Item
                name="city"
                label="City/Disctrict/Municipality"
                rules={[
                  {
                    required: cities?.length > 0,
                    message: "Please select at least a city!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select a City"
                  allowClear
                  showSearch
                  disabled={cities?.length < 1}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {cities?.map((city) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={isSubmitting}
            >
              Save Progress
            </Button>
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default Edit;
