import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Divider,
  Upload,
  Spin,
} from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import dayjs from "dayjs";
import { httpService } from "../../../../../services/httpService.service";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";

const { Option } = Select;

const flagStatuses = [
  { id: 1, status: "Not addressed" },
  { id: 2, status: "In progress" },
  { id: 3, status: "On hold" },
  { id: 4, status: "Resolved" },
  { id: 5, status: "Archived" },
];

const EditDraft = ({
  visible,
  onClose,
  record,
  setMessage,
  fetchDraftData,
  fetchRedFlags,
}) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState();
  const [users, setUsers] = useState();
  const [form] = Form.useForm();
  const [siteVisits, setSiteVisits] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existingFiles, setExistingFiles] = useState("");
  const [savingPorgres, setSavingPorgres] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [filesToRemoveKeys, setFilesToRemoveKeys] = useState([]);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");

  const handleDelete = async () => {
    if (record) {
      const requestBody = [
        {
          id: record?.key,
        },
      ];

      const url = `/api/Draft/deleteDrafts`;

      httpService.delete(url, JSON.stringify(requestBody));
    }
  };

  useEffect(() => {
    setPageLoading(true);
    if (record?.key) {
      const redFlagId = record?.key;

      httpService
        .get(`/api/Draft/getDraftById?id=${redFlagId}`, (res) => {
          const redFlag = JSON.parse(res?.data?.jsonData);
          console.log("redFlag2222", redFlag);

          fetchSiteVisits();
          if (res?.data?.updatedDate) {
            const lastUpdatedDate = res?.data?.updatedDate?.split("T")?.[0];
            setLastUpdatedDate(lastUpdatedDate);
          }
          const initialData = {
            ImplementingPartner: redFlag?.ImplementingPartner || undefined,
            name: redFlag?.Name || undefined,
            description: redFlag?.Description || undefined,
            siteVisitCard: redFlag?.SiteVisitId || undefined,
            dateOfSiteVisit: dayjs(redFlag?.DateOfSiteVisit) || undefined,
            dueDate: dayjs(redFlag?.DueDate) || undefined,
            assignedTo: redFlag?.AssignedTo?.id || undefined,
            flagStatus: redFlag?.FlagStatus || undefined,
            followUpNotes: redFlag?.FollowUpNotes || undefined,
          };
          setExistingFiles(redFlag?.fileList);
          setInitialValues(initialData);
          form.setFieldsValue(initialData);
          setPageLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, [record, form]);

  const deleteFilesFromDatabase = async (filesToRemove) => {
    if (filesToRemove?.length > 0) {
      try {
        await axios.delete(
          `https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey`,
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
            },
            data: filesToRemove,
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const postJsonData = (values, filesData) => {
    deleteFilesFromDatabase(filesToRemoveKeys);

    let slectedUsers;
    if (values.assignedTo) {
      slectedUsers = users?.find((user) => user.id === values.assignedTo);
    }

    const RedFlagDraftData = {
      Name: values.name,
      ImplementingPartner: values.ImplementingPartner,
      Description: values.description,
      SiteVisitId: values?.siteVisitCard,
      ProjectId: localStorage.getItem("currentProjectID"),
      DateOfSiteVisit: values?.dateOfSiteVisit,
      DueDate: values?.dueDate,
      AssignedTo: slectedUsers,
      FlagStatus: values.flagStatus,
      FollowUpNotes: values.followUpNotes,
      ...(filesData?.length || existingFiles?.length
        ? { fileList: [...(filesData || []), ...(existingFiles || [])] }
        : {}),
    };
    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(RedFlagDraftData);
    const payload = {
      id: record.key,
      ProjectId: projectId,
      jsonData: jsonData,
      type: 3,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          fetchDraftData();
          fetchRedFlags();
          handleLeave();
          setFileList([]);
          form.resetFields();
          setSavingPorgres(false);
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setSavingPorgres(false);
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 1000);
      });
  };

  const handleSaveProgress = async () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    let redFlagFiles = fileList;
    if (redFlagFiles?.length > 0) {
      const formData = new FormData();

      formData.append("FolderName", "TPM");
      formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

      redFlagFiles?.forEach((file, index) => {
        formData.append(
          `entityFormFileDtos[${index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f611111"
        );
        formData.append(
          `entityFormFileDtos[${index}].formFile`,
          file.originFileObj
        );
      });

      axios
        .post(
          "https://kapfilemanager.kapsurvey.com/FileData/addFile",
          formData,
          {
            headers: {
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          const filesData = response.data.map((fileData) => fileData);
          postJsonData(values, filesData);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    } else {
      postJsonData(values);
    }
  };

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}&IgnorePagination=true`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    httpService.get(
      `/api/Site/getAllSiteVisits?ProjectId=${projectId}`,
      (res) => {
        setSiteVisits(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&RoleIds=615a8dc6-ca07-4f82-8d0d-7d82dd4d3ef8&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const formatDate = (date) => {
    if (!date || !date.$isDayjsObject) return "";

    return date.format("YYYY-MM-DD");
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList);

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      const dateOfSiteVisit = formatDate(values.dateOfSiteVisit);
      const dueDate = formatDate(values.dueDate);
      const projectId = localStorage.getItem("currentProjectID");

      const formData = new FormData();
      formData.append("Name", values.name);
      formData.append("Description", values.description);
      formData.append("SiteVisitId", values.siteVisitCard);
      formData.append("ProjectId", projectId);
      formData.append("DateOfSiteVisit", dateOfSiteVisit);
      formData.append("DueDate", dueDate);
      formData.append("AssignedTo", values.assignedTo);
      formData.append("FlagStatus", values.flagStatus);
      formData.append("FollowUpNotes", values.followUpNotes);

      existingFiles?.forEach((file, index) => {
        formData.append(`Files[${index}].FileStatus`, 1);
        formData.append(`Files[${index}].EntityId`, file.entityId);
        formData.append(`Files[${index}].FileKey`, file.fileKey);
      });

      fileList?.forEach((file, index) => {
        const countFiles = existingFiles.length + index;
        formData.append(`Files[${countFiles}].FileStatus`, 1);
        formData.append(`Files[${countFiles}].file`, file.originFileObj);
      });

      if (fileList?.length === 0 && existingFiles?.length === 0) {
        const emptyFile = new Blob([], { type: "application/json" });
        formData.append("UpdateFiles[0].fileStatus", 1);
        formData.append("UpdateFiles[0].file", emptyFile, "empty.json");
      }

      await httpService.upload(
        "/api/RedFlag",
        formData,
        (response) => {
          const message =
            response.message ||
            `A new red flag has been created and added to your database!`;
          setMessage({
            trigger: true,
            type: "success",
            icon: AddMemberIcon,
            title: `Red flag added successfully!`,
            text: message,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
          handleDelete();
          form.resetFields();
          onClose();
          setExistingFiles([]);

          setFileList([]);
          setTimeout(() => {
            fetchDraftData();

            fetchRedFlags();
          }, 2000);
        },
        (error) => {
          const messageError =
            error?.errors?.[0]?.message ||
            `We had a problem adding this red flag to your database, please try again!`;
          setMessage({
            trigger: true,
            type: "danger",
            icon: AddMemberIcon,
            title: "Oops! A problem has occurred!",
            text: messageError,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
              type: "",
              icon: "",
              title: "",
              text: "",
            });
          }, 3000);
        }
      );
    } catch (errorInfo) {
      console.log(errorInfo);
      scrollToField(errorInfo);
      setMessage({
        trigger: true,
        type: "danger",
        icon: AddMemberIcon,
        title: "Validation Error",
        text:
          errorInfo.errorFields?.length > 0
            ? "Please fill all the required fields."
            : "An unexpected error occurred.",
      });
    } finally {
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      setLoading(false);
    }
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields?.[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name?.[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const handleLeave = () => {
    form.resetFields();
    setFileList([]);
    onClose();
    setFilesToRemoveKeys([]);
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleResourceRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleResourceRedFlagRemove = (fileToRemove) => {
    const fileKey = fileToRemove.fileKey;
    setFilesToRemoveKeys([...filesToRemoveKeys, fileKey]);
    setExistingFiles((prevFiles) =>
      prevFiles.filter((item) => item.fileDataId !== fileToRemove.fileDataId)
    );
  };

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString?.split(pattern)[1];

    if (match) {
      return match;
    } else {
      return null;
    }
  };
  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    }
    return isValidSize;
  };

  const handleSiteVisitChange = (id) => {
    const siteVisit = siteVisits?.find((siteVisit) => siteVisit?.id === id);
    const ipOfSiteVisit = siteVisit?.ipPlan?.implementingPartner?.name;
    form.setFieldValue("ImplementingPartner", ipOfSiteVisit);
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
      className="tripLogs"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        {lastUpdatedDate && <span>Last update: {lastUpdatedDate}</span>}
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit Draft</h2>
      <Spin spinning={pageLoading}>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          {" "}
          <Form.Item
            className="fullWidthInput"
            name="name"
            label="Name (type of red flag)"
            rules={[
              { required: true, message: "Enter the name of the red flag" },
            ]}
          >
            <Input
              placeholder="Enter the name of the red flag"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            name="siteVisitCard"
            label="Select the site visit card"
            rules={[{ required: true, message: "Select the site visit card" }]}
            style={{ width: "100%" }}
          >
            <Select
              onChange={handleSiteVisitChange}
              placeholder="<###> - SV - <Sitename> - <Location 2> - <date created>"
            >
              {siteVisits &&
                siteVisits.map((siteVisit) => (
                  <Option key={siteVisit.id} value={siteVisit.id}>
                    {siteVisit.identifier}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="ImplementingPartner"
            label="Implementing partner"
            style={{ width: "100%" }}
          >
            <Input
              disabled
              placeholder="Implementing partner of the selected site visit card"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Enter the description of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <TextArea
              rows={4}
              placeholder="Enter the description of the flag"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dateOfSiteVisit"
              label="Date of red flag"
              rules={[{ required: true, message: "Pick date of red flag" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Enter text here" />
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="dueDate"
              label="Due date for action"
              rules={[{ required: true, message: "Pick due date" }]}
              style={{ width: "48%" }}
            >
              <DatePicker placeholder="Select date"></DatePicker>
            </Form.Item>
          </div>
          <Form.Item
            name="assignedTo"
            label="Select a person to assign the flag"
            rules={[
              { required: true, message: "Select a person to assign the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select a person to assign the flag">
              {users &&
                users.map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.userDetails?.firstName && user.userDetails?.lastName
                      ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                      : user.userName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="flagStatus"
            label="Flag status"
            rules={[
              { required: true, message: "Select the status of the flag" },
            ]}
            style={{ width: "100%" }}
          >
            <Select placeholder="Select the status of the flag">
              {flagStatuses &&
                flagStatuses.map((flagStatus) => (
                  <Option key={flagStatus.id} value={flagStatus.id}>
                    {flagStatus.status}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label="Follow-up notes"
            rules={[{ required: true, message: "Enter your follow up notes" }]}
            style={{ width: "100%" }}
          >
            <TextArea rows={4} placeholder="Enter your follow up notes" />
          </Form.Item>
          <div className="resourcesElement">
            <div className="resourcesLabel">
              <div className="resourcesUploadElement">
                <Form.Item
                  name="files"
                  label={
                    <div>
                      <p
                        className="modalsHeader1"
                        style={{
                          color: "var(--2, #AA1A5F)", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "144%",
                        }}
                      >
                        Evidence
                      </p>
                      <p
                        style={{
                          color: "var(--Neutrals-Black, #3A3737);", // Corrected syntax for CSS variable
                          fontFamily: "Poppins", // Use camelCase for properties in React
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                        }}
                      >
                        Please attach any relevant evidence if needed
                      </p>
                    </div>
                  }
                >
                  <Upload.Dragger
                    multiple
                    listType="text"
                    accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg"
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={(file) => handleFileSize(file)}
                  >
                    <div className="d-flex justify-content-center pb-3">
                      <img src={uploadIcon} alt="" />
                    </div>
                    <Button className="uploadButton">
                      Drag and drop files here, or click to select files
                    </Button>
                    <div className="d-flex justify-content-center bottomText ">
                      <span className="span3">
                        PDF, XLS, XLSX, DOC, DOCX, PNG, JPEG or JPG
                      </span>
                      <span className="span4">Max file size: 5MB</span>
                    </div>
                  </Upload.Dragger>
                  <div className="uploadsContainer">
                    {fileList.map((file, index) => (
                      <div className="uploadContent" key={file.uid}>
                        <img
                          className="uploadIcon"
                          src={uploadIcon}
                          alt={file.name}
                        />
                        <div>
                          <h1>{file.name}</h1>
                          <p>
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        </div>
                        <img
                          onClick={() => handleResourceRemove(file)}
                          src={RemoveIcon}
                          alt="RemoveIcon"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="uploadsContainer">
                    {existingFiles &&
                      existingFiles?.map((file, index) => (
                        <div className="uploadContent" key={file?.fileDataId}>
                          <img
                            className="uploadIcon"
                            src={uploadIcon}
                            alt={extractName(file?.fileKey)}
                          />
                          <div>
                            <h1>{extractName(file?.fileKey)}</h1>
                          </div>
                          <img
                            onClick={() => handleResourceRedFlagRemove(file)}
                            src={RemoveIcon}
                            alt="RemoveIcon"
                          />
                        </div>
                      ))}
                  </div>
                </Form.Item>
              </div>
            </div>
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleSaveProgress}
              loading={loading || savingPorgres}
            >
              Save Progress
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading || savingPorgres}
            >
              Add red flag
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditDraft;
