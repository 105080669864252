import { Table } from "antd";
import React, { useEffect, useState } from "react";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import Filters from "./Filters";
import PreviewModal from "../../../planning/modals/PreviewModal";
import EditModal from "../../../planning/modals/EditModal";

function TableViewIpDonor({
  data,
  handleFilterChange,
  filters,
  setFilters,
  loading,
  setDataForExcel,
  fetchIpPlan,
  onPageChange,
  setPagination,
  pagination,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPreviewModalVisible, setPreviewModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handlePreview = (record) => {
    setCurrentRecord(record);
    setPreviewModalVisible(true);
  };

  const handlePreviewOk = () => {
    setPreviewModalVisible(false);
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    setEditModalVisible(true);
  };

  const handleEditOk = () => {
    setEditModalVisible(false);
  };

  const statusMappingDonorReportingStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Discussion",
          className: "discussion",
          fill: "var(--system-yellow-warning, #AA1A5F)",
        };
      case 2:
        return {
          text: "Document Review",
          className: "documentReview",
          fill: "var(--system-yellow-warning, #FC9403)",
        };
      case 3:
        return {
          text: "Pre Planning Phase",
          className: "prePlanningPhase",
          fill: "var(--Primary-Blueberry-700, #325899)",
        };
      case 4:
        return {
          text: "Data Collection",
          className: "dataCollection",
          fill: "#3B81FE",
        };
      case 5:
        return { text: "Closed", className: "closed", fill: "#555555" };
      default:
        return {
          text: "No status",
          className: "noStatus",
          fill: "rgba(172, 12, 12, 0.777)",
        };
    }
  };

  const statusMappingCurrentIpStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Data collection",
          className: "dataCollection",
          fill: "#3B81FE",
        };
      case 2:
        return {
          text: "Data cleaning",
          className: "dataCleaning",
          fill: "#06768E",
        };
      case 3:
        return {
          text: "Data translation",
          className: "dataTranslation",
          fill: "#918d27",
        };
      case 4:
        return {
          text: "Data analysis",
          className: "dataAnalysis",
          fill: "#045B46",
        };
      case 5:
        return {
          text: "IP/Client report",
          className: "ipClientReport",
          fill: "var(--Primary-Blueberry-700, #325899)",
        };
      case 6:
        return {
          text: "Donor Report",
          className: "donorReport",
          fill: "#964B00",
        };
      case 7:
        return {
          text: "Completed",
          className: "completed",
          fill: "#0F7507",
        };
      default:
        return {
          text: "No status",
          className: "noStatus",
          fill: "rgba(172, 12, 12, 0.777)",
        };
    }
  };

  function getMonth(monthNumber) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthIndex = parseInt(monthNumber, 10) - 1;

    return months[monthIndex] || "";
  }
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };

  const columns = [
    {
      title: <span className="styledTitle">IP Plan card identifier</span>,
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a?.identifier.localeCompare(b?.identifier),
      render: (text, record) => (
        <span className="styledDataIndex3">{record?.identifier}</span>
      ),
    },{
      title: <span className="styledTitle">IP/Donor Reporting Status </span>,
      dataIndex: "ipDonorReportingEnum",
      key: "ipDonorReportingEnum",
      sorter: (a, b) => a?.ipDonorReportingEnum - b?.ipDonorReportingEnum,
      render: (value, record) => {
        const { text, className, fill } = statusMappingCurrentIpStatus(
          record.ipDonorReportingEnum
        );
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle">Current IP Status</span>,
      dataIndex: "currentIPStatus",
      key: "currentIPStatus",
      sorter: (a, b) => a?.currentIPStatus - b?.currentIPStatus,
      render: (value, record) => {
        const { text, className, fill } = statusMappingDonorReportingStatus(
          record.currentIPStatus
        );
        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    
    {
      title: <span className="styledTitle">IP</span>,
      dataIndex: "implementingPartner.name",
      key: "implementingPartner.name",
      sorter: (a, b) =>
        a?.implementingPartner?.name.localeCompare(
          b?.implementingPartner?.name
        ),
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record?.implementingPartner?.name}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "assignee",
      key: "assignee",
      sorter: (a, b) =>
        a.assignee?.userName.localeCompare(b.assignee?.userName),
      render: (record) => (
        <span className="styledDataIndex3">{record?.userName}</span>
      ),
    },
    {
      title: <span className="styledTitle">Round Month-Year</span>,
      dataIndex: "planningRound",
      key: "roundMonthYear",
      sorter: (a, b) => {
        const dateA = new Date(a?.planningRound?.year, a?.planningRound?.month);
        const dateB = new Date(b?.planningRound?.year, b?.planningRound?.month);
        return dateA - dateB;
      },
      render: (text) => (
        <span className="styledDataIndex3">{`${getMonth(text?.month)}, ${
          text?.year
        }`}</span>
      ),
    },
    {
      title: <span className="styledTitle">Date of planned meeting with IP</span>,
      dataIndex: "initialMeeting",
      key: "initialMeeting",
      sorter: (a, b) =>
        new Date(a?.initialMeeting) - new Date(b?.initialMeeting),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Postponed</span>,
      dataIndex: "isPostponed",
      key: "postponed",
      sorter: (a, b) => a?.isPostponed - b?.isPostponed,
      render: (text) => (
        <span className="styledDataIndex3">{text ? "Yes" : "No"}</span>
      ),
    },
    // {
    //   title: <span className="styledTitle">Data Collection Completed</span>,
    //   dataIndex: "ipTracking",
    //   key: "ipTracking",
    //   sorter: (a, b) => new Date(a.ipTracking?.dataCollectionCompletion) - new Date(b?.ipTracking?.dataCollectionCompletion),
    //   render: (record) => <span className="styledDataIndex3">{record?.dataCollectionCompletion}</span>,
    // },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={() => handlePreview(record.id)}
          />
          {/* <img src={editIcon} style={{ cursor: "pointer" }} alt=""
            onClick={() => handleEdit(record.id)}
          /> */}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    const formattedData = selectedRows.map((item) => ({
      "IP Plan card identifier": item?.implementingPartner?.name || "N/A",
      "IP/Donor Reporting Status":
        item?.ipTracking?.ipDonorReportingEnum || "N/A",
      "Current IP Status": item?.currentIPStatus || "N/A",
      IP: item?.implementingPartner?.name || "N/A",
      "Assign To": item?.assignee?.userName || "N/A",
      "Main Sector": item?.mainSector?.name || "N/A",
      "Round Month-Year":
        item?.planningRound?.month - item?.planningRound?.year || "N/A",
      "Initial Meeting": item?.initialMeeting || "N/A",
      Postponed: (item?.isPostponed ? "YES" : "NO") || "N/A",
      "Data Collection Completed": item?.dataCollectionCompletion || "N/A",
    }));
    setDataForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  return (
    <>
      <Filters
        handleFilterChange={handleFilterChange}
        setFilters={setFilters}
      />
      <div>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          dataSource={data}
          columns={columns}
          loading={loading}
          className="tableTPM"
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
        />
      </div>
      <PreviewModal
        visible={isPreviewModalVisible}
        onOk={handlePreviewOk}
        onCancel={() => setPreviewModalVisible(false)}
        record={currentRecord}
      />
      <EditModal
        visible={isEditModalVisible}
        onOk={handleEditOk}
        onClose={() => setEditModalVisible(false)}
        setMessage={setMessage}
        record={currentRecord}
        onEdit={fetchIpPlan}
      />
    </>
  );
}

export default TableViewIpDonor;
