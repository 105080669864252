import { Form, message, Upload, Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import { useEffect, useState } from "react";
import RemoveIcon from "../../../../../Assets/svg/removeIcon.svg";
import { useSiteVisitStore } from "./store/siteVisitStore";

const ResourcesDetails = ({
  form,
  existingFiles,
  setExistingFiles,
  requiredPhotos,
  photosLimit,
  requiredVideos,
  videosLimit,
  setMessage,
}) => {
  const { otherDocuments = [], setOtherDocuments } = useSiteVisitStore();

  const extractName = (fileString) => {
    const pattern = /\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{2}:\d{2} [APM]{2}-/;

    const match = fileString?.split(pattern)[1];

    if (match) {
      return match;
    } else {
      return null;
    }
  };

  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => setMessage({ trigger: false }), 3000);
    }
    return isValidSize;
  };

  const handleOtherDocuments = (info) => {
    const { fileList } = info;
    setOtherDocuments(fileList);
    form.setFieldsValue({
      other_files: fileList,
    });
  };

  const handleDeleteOtherDocuments = (itemToRemove) => {
    const newFiles = otherDocuments?.filter(
      (item) => item?.uid !== itemToRemove?.uid
    );
    setOtherDocuments(newFiles);
  };

  const handleDeleteExistingOtherDocuments = (file) => {
    if (existingFiles) {
      setExistingFiles((prevFiles) => ({
        report_documents: prevFiles.report_documents,
        other_files: prevFiles.other_files?.filter(
          (item) => item.fileDataId !== file.fileDataId
        ),
        video_files: prevFiles.video_files,
      }));
    }
  };

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
      }}
    >
      <Form.Item
        name="other_files"
        label="Upload other document(s) - Security plan"
        style={{ fontWeight: "500" }}
      >
        <Upload.Dragger
          listType="text"
          accept=".pdf,.doc,.docx,.xls,.xlsx"
          fileList={otherDocuments}
          multiple={true}
          onChange={handleOtherDocuments}
          beforeUpload={(file) => handleFileSize(file)}
        >
          <div className="d-flex justify-content-center pb-3">
            <img src={uploadIcon} alt="" />
          </div>
          <Button className="uploadButton">
            Drag and drop other document here, or click to select files
          </Button>
          <div className="d-flex justify-content-center bottomText ">
            <span className="span3">
              Allowed formats: .pdf,.doc,.docx,.xls,.xlsx
            </span>
            <span className="span4">Max file size: 5MB</span>
          </div>
        </Upload.Dragger>
        <div className="uploadsContainer">
          {Array.isArray(otherDocuments) &&
            otherDocuments.map((file) => (
              <div className="uploadContent" key={file.uid}>
                <img className="uploadIcon" src={uploadIcon} alt={file.name} />
                <div>
                  <h1>{file.name}</h1>
                  {file.size && (
                    <p>{(parseInt(file.size) / (1024 * 1024)).toFixed(1)} MB</p>
                  )}
                </div>
                <img
                  onClick={() => handleDeleteOtherDocuments(file)}
                  src={RemoveIcon}
                  alt="RemoveIcon"
                />
              </div>
            ))}

          {existingFiles?.other_files?.map((file) => (
            <div className="uploadContent" key={file?.fileDataId}>
              <img
                className="uploadIcon"
                src={uploadIcon}
                alt={extractName(file?.fileKey) || file?.fileKey}
              />
              <div>
                <h1>{extractName(file?.fileKey) || file?.fileKey}</h1>
              </div>
              <img
                onClick={() => handleDeleteExistingOtherDocuments(file)}
                src={RemoveIcon}
                alt="RemoveIcon"
              />
            </div>
          ))}
        </div>
      </Form.Item>
    </div>
  );
};
export default ResourcesDetails;
