import { Button, Divider, Form, Modal, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import "./index.scss";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

const EditProfilePicture = ({ visible, onClose }) => {
  const [loadingPage, setLoadingPage] = useState(false);
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const userId = localStorage.getItem("userID");
  const userEmail = localStorage.getItem("userEmail");
  const userName = localStorage.getItem("username");
  const profilePicture = localStorage.getItem("profilePicture"); // Fetch from localStorage
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    // Initially load the profile picture from localStorage
    if (profilePicture && !image) {
      setImage(profilePicture);
    }
  }, [profilePicture]);

  const handleCancel = () => {
    onClose();
  };

  const handleFileChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result); // Display the new uploaded image
        };
        reader.readAsDataURL(file);
        setFileList(info.fileList); // Keep file list intact
      }
    }
  };

  const removeProfilePicture = () => {
    setFileList([]);
    setImage(""); // Reset the image state
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("userId", userId);
    if (image) {
      formData.append("ProfilePicture", fileList[0].originFileObj);
    } else {
      formData.append("RemoveProfilePicture", true);
    }

    setLoadingPage(true);

    httpService.post(
      "/api/User/manageProfilePicture",
      formData,
      (response) => {
        console.log("Profile picture uploaded successfully", response);
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Profile picture updated successfully!",
          text: "Your profile picture has been updated.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
          onClose();
        }, 3000);
        setLoadingPage(false);
        const profilePictureUrl = response?.message;
        localStorage.setItem("profilePicture", profilePictureUrl);
        window.location.reload();
      },
      (error) => {
        console.error("Profile picture upload failed", error);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: "Failed to upload profile picture. Please try again.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoadingPage(false);
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };
  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Edit profile picture</h2>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Form form={form} autoComplete="off" layout="vertical">
        <Spin spinning={loadingPage}>
          <div className="userInfo mb-3">
            {!image ||
            image == "Profile picture removed successfully" ||
            image == "" ? (
              // Display initials if no image is available
              <div className="logo">
                <span>
                  {userName && userName !== undefined
                    ? userName.charAt(0)
                    : userEmail.charAt(0)}
                </span>
              </div>
            ) : (
              // Display either profile picture from localStorage or the uploaded image
              <div className="profilePicture">
                <img src={image} alt="Profile Picture" />
                <p onClick={removeProfilePicture}>Remove</p>
              </div>
            )}
            <h2>Upload a file to change your profile picture</h2>
          </div>
          <Form.Item name="files" style={{ cursor: "pointer" }}>
            <Upload
              listType="picture"
              accept=".png,.jpeg,.jpg"
              beforeUpload={() => false} // Prevent automatic upload
              fileList={fileList} // Maintain file list
              onChange={handleFileChange} // Handle file selection
              multiple={false} // Only allow one file
            >
              <div className="w-100 d-flex justify-content-center my-3">
                <img src={uploadIcon} alt="" />
              </div>
              <Button className="uploadButton">
                Drag and drop files here, or click to select files
              </Button>
              <div className="d-flex justify-content-center bottomText mb-3">
                <span className="span3 text-bold">JPG or PNG</span>
                <span className="span4">Max file size: 20MB</span>
              </div>
            </Upload>
          </Form.Item>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              disabled={fileList?.length < 1 && image}
            >
              Change profile picture
            </Button>
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default EditProfilePicture;
