import { Checkbox, Select, Table, Input, Button, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import AddMemberIcon from "../../../../../Assets/svg/addMemberIcon.svg";
import { httpService } from "../../../../../services/httpService.service";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import EditDraft from "./EditDraft";

function DraftTableView({
  fetchRedFlags,
  loading,
  data,
  setLoading,
  draftPagination,
  setDraftPagination,
  onDraftPageChange,
  filters,
  setFilters,
  handleFilterChange,
  fetchDraftData,
}) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const projectID = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const handleToggleEditModal = (record) => {
    setSelectedRowData(record);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    setSelectedRowData(record);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleDeleteModal = (record) => {
    setSelectedRowData(record);
    setShowDeleteModal(true);
  };

  // const fetchDraftData = async (current, pageSize) => {
  //   const projectId = localStorage.getItem("currentProjectID");

  //   let apiUrl = `/api/Draft/getAll?Type=3&ProjectId=${projectId}&pageNumber=${current}&pageSize=${pageSize}`;

  //   const activeFilters = filters || {};

  //   for (const [key, value] of Object.entries(activeFilters)) {
  //     if (value) {
  //       apiUrl += `&${key}=${value}`;
  //     }

  //   }

  //   setLoading(true);
  //   httpService.get(
  //     apiUrl,
  //     (response) => {
  //       const formattedData = response.data.map((item) => {
  //         const redFlag = JSON.parse(item.jsonData);
  //         return {
  //           key: item.id,
  //           ipTag: redFlag?.ImplementingPartner?.name || "N/A",
  //           name: redFlag?.Name || "N/A",
  //           description: redFlag?.Description || "N/A",
  //           siteVisitID: redFlag?.SiteVisitId?.identifier || "N/A",
  //           dateOfSiteVisit: redFlag?.DateOfSiteVisit || "N/A",
  //           office: redFlag?.OfficeId?.name || "N/A",
  //           dueDate: redFlag?.DueDate || "N/A",
  //           assignedTo: redFlag?.AssignedTo?.userName || "N/A",
  //           status: redFlag?.FlagStatus || "N/A",
  //         };
  //       });
  //       setData(formattedData);

  //       setDraftPagination((prevDraftPagination) => ({
  //         ...prevDraftPagination,
  //         current: response?.metaData?.pageNumber,
  //         pageSize: response?.metaData?.pageSize,
  //         total: response?.metaData?.totalItemCount,
  //       }));

  //       setLoading(false);
  //     },
  //     (error) => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     }
  //   );
  // };

  useEffect(() => {
    fetchDraftData(draftPagination.current, draftPagination.pageSize);
  }, []);

  const handleDelete = () => {
    setLoading(true);
    const payload = [
      {
        id: selectedRowData.key,
        isDeleted: false,
      },
    ];
    httpService.delete(
      "/api/Draft/deleteDrafts",
      payload,
      () => {
        setShowDeleteModal(false);
        fetchDraftData(projectID);
        setMessage({
          trigger: true,
          type: "success",
          icon: AddMemberIcon,
          title: `Red flag draft deleted successfully!`,
          text: `This red flag draft has been deleted and removed from your database!`,
        });
        // Clear the message after 3 seconds
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        fetchDraftData(projectID);
        setLoading(false);
      },
      (error) => {
        const messageError =
          error?.errors?.[0]?.message ||
          `We had a problem removing this red flag draft from your database, please try again!`;
        setMessage({
          trigger: true,
          type: "danger",
          icon: AddMemberIcon,
          title: "Oops! A problem has occurred!",
          text: messageError,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return `${formattedDate}`;
  };

  const columns = [
    {
      title: <span className="styledTitle">Name</span>,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">IP tag</span>,
      dataIndex: "ipTag",
      key: "ipTag",
      sorter: (a, b) => a.ipTag.localeCompare(b.ipTag),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Description</span>,
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site visit id</span>,
      dataIndex: "siteVisitID",
      key: "siteVisitID",
      sorter: (a, b) => a.siteVisitID.localeCompare(b.siteVisitID),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Date of site visit</span>,
      dataIndex: "dateOfSiteVisit",
      key: "dateOfSiteVisit",
      sorter: (a, b) => a.dateOfSiteVisit.localeCompare(b.dateOfSiteVisit),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Due date</span>,
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate.localeCompare(b.dueDate),
      render: (text) => (
        <span className="styledDataIndex3">{formatDate(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">Assigned to</span>,
      dataIndex: "assignedTo",
      key: "assignedTo",
      sorter: (a, b) => a.assignedTo.localeCompare(b.assignedTo),
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Status</span>,
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status, // Sorting by number directly
      render: (status) => {
        let className;
        let fill;
        let text;

        // Map numbers to their corresponding status and styles
        switch (status) {
          case 1:
            text = "Not addressed";
            className = "notAddressed";
            fill = "#AA1A5F";
            break;
          case 2:
            text = "In progress";
            className = "resolved";
            fill = "#3B81FE";
            break;
          case 3:
            text = "On Hold";
            className = "onHold";
            fill = "#D46600";
            break;
          case 4:
            text = "Resolved";
            className = "inProgress";
            fill = "#045B46";
            break;
          case 5:
            text = "Archived";
            className = "assigned";
            fill = "#555";
            break;
          default:
            text = "Unknown";
            className = "noStatus";
            fill = "rgba(172, 12, 12, 0.777)";
        }

        return (
          <div className={className}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8px"
              height="8px"
              viewBox="0 0 9 9"
            >
              <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
            </svg>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
              alt="editIcon"
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteModal(record)}
              alt="deleteIcon"
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />

      <div>
        {isAuthorized([
          RolesEnum.ProjectManager,
          RolesEnum.SystemAdmin,
          RolesEnum.FieldCoordinator,
          RolesEnum.FieldLogistics,
        ]) && (
          <Table
            dataSource={data}
            columns={columns}
            className="tableTPM"
            loading={loading}
            pagination={{
              current: draftPagination.current,
              pageSize: draftPagination.pageSize,
              total: draftPagination.total,
              onChange: onDraftPageChange,
              showSizeChanger: true,
            }}
          />
        )}
      </div>
      <EditDraft
        visible={showEditModal}
        onClose={handleToggleEditModal}
        record={selectedRowData}
        setMessage={setMessage}
        fetchRedFlags={fetchRedFlags}
        fetchDraftData={fetchDraftData}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={"You're about to delete this. You can revert it from deleted. "}
        header={"Delete "}
        onConfirm={handleDelete}
        loading={loading}
      />
    </>
  );
}

export default DraftTableView;
