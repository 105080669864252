import { Button, Select, Spin } from "antd";
import React, { useState, useEffect } from "react";
import LogButton from "../../../../Assets/svg/logButton.svg";
import TeamButton from "../../../../Assets/svg/teamButton.svg";
import AddButton from "../../../../Assets/svg/addButton.svg";
import "./FieldLogisticsBoard.scss";
import "../Implementation.scss";
import VisitCards from "./header/VisitCards";
import { VisitCardFilters } from "./header/VisitCardFilters";
import TripLogistics from "./TripLogistics/TripLogistics";
import TeamCards from "./TeamCards/TeamCards";
import DriverCards from "./DriverCards/DriverCards";
import AddTeamCardModal from "./modals/AddTeamCardModal";
import AddDriverCardModal from "./modals/AddDriverCardModal";
import TripLogCardModal from "./modals/TripLogCardModal";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import { isAuthorized } from "../../../../services/authService";
import { RolesEnum } from "../../../../services/rolesEnum";

function FieldLogisticsBoard() {
  const { Option } = Select;
  const [page, setPage] = useState("Trip logistics cards");
  const pageOptions = ["Trip logistics cards", "Team cards", "Driver cards"];
  const [showAddTeamCardModal, setShowAddTeamCardModal] = useState(false);
  const [showAddDriverCardModal, setShowAddDriverCardModal] = useState(false);
  const [showTripLogCardModal, setShowTripLogCardModal] = useState(false);
  const [showAllTripData, setShowAllTripData] = useState([]);
  const [showAllTripDataDrafts, setShowAllTripDataDrafts] = useState([]);
  const [siteVisits, setSiteVisits] = useState([]);
  const [teams, setTeams] = useState([]);
  const [cards, setCards] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [draftCount, setDraftCount] = useState(0);
  const [filters, setFilters] = useState({
    state: "",
    monthYear: null,
  });
  const contractId = localStorage.getItem("currentContractId");
  const projectID = localStorage.getItem("currentProjectID");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [draftPagination, setDraftPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const [loadingTripLogistic, setLoadingTripLogistic] = useState(false);
  const [loadingSiteVisits, setLoadingSiteVisits] = useState(false);

  const fetchTripLogistics = async (ignorePagination) => {
    setLoadingTripLogistic(true);
    const params = {
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      StartDate: filters.startDate,
      EndDate: filters.endDate,
      Status: filters.status,
      Search: filters.search,
      Country: filters.country,
      City: filters.city,

      IgnorePagination: ignorePagination,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    httpService.get(
      `/api/TripLogistic/getAllTripLogistics?${queryString}`,
      (response) => {
        const tripLogistics = response?.data || [];
        setShowAllTripData(tripLogistics);

        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoadingTripLogistic(false);
      },
      (error) => {
        console.error("Error fetching trip logistics:", error);
        setLoadingTripLogistic(false);
      }
    );
  };

  const fetchTripLogisticsDrafts = async () => {
    setLoadingTripLogistic(true);
    const params = {
      ProjectId: projectID,
      pageNumber: draftPagination.current,
      pageSize: draftPagination.pageSize,
      StartDate: filters.startDate,
      EndDate: filters.endDate,
      Status: filters.status,
      Search: filters.search,
      Country: filters.country,
      City: filters.city,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryParams = new URLSearchParams(filteredParams).toString();
    const projectId = localStorage.getItem("currentProjectID");

    httpService.get(
      `/api/Draft/getAll?Type=5&ProjectId=${projectId}&${queryParams}`, // Include pagination parameters in the query
      (response) => {
        const tripLogisticsDrafts = response?.data || [];
        const parsedTripLogisticsDrafts = tripLogisticsDrafts.map((draft) => ({
          ...draft,
          jsonData: JSON.parse(draft?.jsonData || "{}"),
        }));

        setShowAllTripDataDrafts(parsedTripLogisticsDrafts);
        setDraftCount(response.metaData.totalItemCount);
        setDraftPagination({
          ...draftPagination,
          total: response.metaData.totalItemCount, // Ensure to set the total count
        });
        setLoadingTripLogistic(false);
      },
      (error) => {
        console.error("Error fetching trip logistics drafts:", error);
        setLoadingTripLogistic(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const onDraftPageChange = (pageNumber, pageSize) => {
    setDraftPagination({ ...draftPagination, current: pageNumber, pageSize });
  };

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const fetchTeams = (contractId) => {
    httpService.get(
      `/api/User/getAllTeams?contractId=${contractId}`,
      (data) => {
        const teamsData = data?.data;
        setTeams(teamsData);
      },
      (error) => {
        console.error("Error fetching teams:", error);
      }
    );
  };

  useEffect(() => {
    if (page === "Trip logistics cards") {
      fetchTeams(contractId);
    } else if (page === "Driver cards") {
      fetchDrivers();
    }
  }, [page]);

  const fetchSiteVisits = async (filters) => {
    setLoadingSiteVisits(true);
    const params = {
      ProjectId: projectID,
    };

    if (
      filters.monthYear &&
      filters.monthYear.isValid &&
      filters.monthYear.isValid()
    ) {
      params.Month = filters.monthYear.format("M");
      params.Year = filters.monthYear.format("YYYY");
    }

    if (filters.state) {
      params.State = filters.state;
    }

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryParams = new URLSearchParams(filteredParams).toString();

    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryParams}`,
      (response) => {
        const siteVisitsData = response?.data || [];

        const filteredSiteVisits = filters.state
          ? siteVisitsData.filter(
              (visit) => visit.locationDetails.country === filters.state
            )
          : siteVisitsData;

        setSiteVisits(filteredSiteVisits);
        const transformedCards = filteredSiteVisits.map((visit) => ({
          id: visit?.id,
          title: visit?.identifier,
          statusUpdated: formatDate(visit?.createdDate),
          ip: visit?.ipPlan?.implementingPartner?.name,
          state: visit?.locationDetails?.country,
          municipality: visit?.locationDetails?.municipality,
        }));
        setCards(transformedCards);
        setLoadingSiteVisits(false);
      },
      (error) => {
        console.error("Error fetching site visits:", error);
        setLoadingSiteVisits(false);
      }
    );
  };
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    // Check if the date is valid
    if (isNaN(date)) {
      return "Invalid date"; // or return an empty string, or any fallback value
    }

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return `${formattedDate}`;
  };

  const fetchDrivers = () => {
    setLoadingDrivers(true);
    const contractId = localStorage.getItem("currentContractId");
    const params = {
      contractId: contractId,
    };

    const queryParams = new URLSearchParams(params).toString();
    httpService.get(
      `/api/Driver/getAll?${queryParams}`,
      (response) => {
        const drivers = response?.data?.map((driver) => ({
          key: driver?.id,
          driverName: driver?.name,
          driverPhone: driver?.phoneNumber,
          mainLocation: driver?.baseLocation?.stateName,
        }));

        setDrivers(drivers);
        setLoadingDrivers(false);
      },
      (error) => {
        console.log(error);
        setLoadingDrivers(false);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits(filters);
  }, [filters]);

  const handleCloseTripLogCardModalModal = () => {
    setShowTripLogCardModal(!showTripLogCardModal);
  };

  const handleCloseAddTeamCardModal = () => {
    setShowAddTeamCardModal(!showAddTeamCardModal);
  };

  const handleCloseAddDriverCardModal = () => {
    setShowAddDriverCardModal(!showAddDriverCardModal);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  return (
    <div className="FieldLogisticsBoard">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />

      <div className="headerContainer m-5">
        <div className="tableHeader">
          <span className="tableViewHeaderText">Field Logistics Board</span>
          <div className="tableViewHeaderButtons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
            ]) && (
              <Button
                className="inviteButtonTPM"
                onClick={() => {
                  setShowTripLogCardModal(true);
                }}
              >
                <img src={LogButton} alt="LogButton" />
                Create Trip Log Card
              </Button>
            )}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldLogistics,
            ]) && (
              <Button
                className="exportButtonTPM addButtonTPM"
                onClick={() => {
                  setShowAddTeamCardModal(true);
                }}
              >
                <img src={TeamButton} alt="TeamButton" />
                Create Team Card
              </Button>
            )}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldLogistics,
            ]) && (
              <Button
                style={{ background: "var(--1, #6A113C)" }}
                className="addButtonTPM"
                onClick={() => {
                  setShowAddDriverCardModal(true);
                }}
              >
                <img src={AddButton} alt="AddButton" />
                Create Driver Card
              </Button>
            )}
            <TripLogCardModal
              fetchTripLogistics={fetchTripLogisticsDrafts}
              visible={showTripLogCardModal}
              onClose={handleCloseTripLogCardModalModal}
              setMessage={setMessage}
            />
            <AddTeamCardModal
              visible={showAddTeamCardModal}
              onClose={handleCloseAddTeamCardModal}
              fetchTeams={fetchTeams}
              setMessage={setMessage}
            />
            <AddDriverCardModal
              visible={showAddDriverCardModal}
              onClose={handleCloseAddDriverCardModal}
              setMessage={setMessage}
              fetchDrivers={fetchDrivers}
            />
          </div>
        </div>
        <div>
          <Spin spinning={loadingSiteVisits}>
            <div className="headerSubContainer">
              <span className="tableViewHeaderText">
                Most recent site visit cards
              </span>

              <VisitCardFilters filters={filters} setFilters={setFilters} />
              <VisitCards cards={cards} />
            </div>
          </Spin>
        </div>
      </div>
      <div className="logisticsController">
        <h1>Select the logistic cards you want to review</h1>
        <Select
          className="cardInputs"
          placeholder="Select logistic cards"
          onChange={handlePageChange}
          value={page}
        >
          {pageOptions.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>

      {page === "Trip logistics cards" ? (
        <TripLogistics
          showAllTripData={showAllTripData}
          showAllTripDataDrafts={showAllTripDataDrafts}
          setShowAllTripData={setShowAllTripData}
          fetchTripLogistics={fetchTripLogistics}
          fetchTripLogisticsDrafts={fetchTripLogisticsDrafts}
          siteVisits={siteVisits}
          page={page}
          pagination={pagination}
          onPageChange={onPageChange}
          setPagination={setPagination}
          loadingTripLogistic={loadingTripLogistic}
          setLoadingTripLogistic={setLoadingTripLogistic}
          handleFilterChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          draftPagination={draftPagination}
          setDraftPagination={setDraftPagination}
          onDraftPageChange={onDraftPageChange}
          draftCount={draftCount}
        />
      ) : null}
      {page === "Team cards" ? (
        <TeamCards teams={teams} fetchTeams={fetchTeams} />
      ) : null}
      {page === "Driver cards" ? (
        <DriverCards
          drivers={drivers}
          fetchDrivers={fetchDrivers}
          loadingDrivers={loadingDrivers}
          setLoadingDrivers={setLoadingDrivers}
          setMessage={setMessage}
        />
      ) : null}
    </div>
  );
}

export default FieldLogisticsBoard;
