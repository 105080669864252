import React, { useState, useEffect } from "react";
import "./VisitBoard.scss";
import { Button, Spin, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import DeleteButton from "../../../../Assets/svg/table-action-delete.svg";
import AddButton from "../../../../Assets/svg/addButton.svg";
import CalendarView from "./CalendarView/CalendarView";
import TableView from "./TableView/TableView";
import AddSiteVisitCardModal from "./Modals/AddSiteVisitCardModal";
import DeletedModal from "./TableView/modals/DeletedModal";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import DraftsTable from "./TableView/DraftsTable";
import { RolesEnum } from "../../../../services/rolesEnum";
import { isAuthorized } from "../../../../services/authService";

function VisitBoard() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeletedModalVisible, setIsDeletedModalVisible] = useState(false);
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [draftSiteVisitCards, setDraftSiteVisitCards] = useState([]);
  const [siteVisitCardsForExcel, setSiteVisitCardsForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [draftsLoading, setDraftsLoading] = useState(false);
  const [savingProgress, setSavingProgress] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const [draftCount, setDraftCount] = useState(0);
  const [draftsPagination, setDraftPagination] = useState({
    pageSize: 10,
    current: 1,
    total: 0,
  });
  const projectID = localStorage.getItem("currentProjectID");
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    fetchSiteVisitCards(projectID);
    fetchDraftSiteVisitCards(projectID);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDeletedModal = () => {
    setIsDeletedModalVisible(true);
  };

  const onChange = (key) => {
    if (key === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }
  };

  const fetchDraftSiteVisitCards = async (projectID, current, pageSize) => {
    setDraftsLoading(true);
    setSavingProgress(true);
    httpService.get(
      `/api/Draft/getAll?Type=1&ProjectId=${projectID}&pageNumber=${current}&pageSize=${pageSize}`,
      (response) => {
        const sitevisitcardsData = response?.data;

        setDraftCount(response?.metaData?.totalItemCount);
        setDraftPagination({
          ...draftsPagination,
          current: response?.metaData?.pageNumber,
          pageSize: response?.metaData?.pageSize,
          total: response?.metaData?.totalItemCount,
        });
        if (sitevisitcardsData.length > 0) {
          setDraftSiteVisitCards(sitevisitcardsData);
        } else {
          setDraftSiteVisitCards([]);
        }
        setDraftsLoading(false);
        setSavingProgress(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setDraftsLoading(false);
        setSavingProgress(false);
      }
    );
  };

  const fetchSiteVisitCards = (
    projectId,
    search,
    implementingPartnerId,
    country,
    siteVisitStatus,
    clusterId,
    month,
    year,
    IgnorePagination,
    GetDraftsOnly
  ) => {
    setLoading(true); // Show loader before request starts

    const params = {
      ProjectId: projectId,
      Search: search,
      ImplementingPartnerId: implementingPartnerId,
      State: country,
      SiteVisitStatus: siteVisitStatus,
      ClusterId: clusterId,
      Month: month,
      Year: year,
      IgnorePagination: IgnorePagination,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      GetDraftsOnly: GetDraftsOnly,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(
        ([_, v]) =>
          v !== undefined && v !== null && !(typeof v === "number" && isNaN(v))
      )
    );

    const queryString = new URLSearchParams(filteredParams).toString();

    httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}&IncludeOnlyWithTracking=false`,
      (response) => {
        const sitevisitcardsData = response?.data;
        setSiteVisitCards(sitevisitcardsData);
        setPagination({
          ...pagination,
          total: response?.metaData?.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visit cards:", error);
        setLoading(false);
      }
    );
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ pageSize, current: pageNumber });
  };

  const onDraftPageChange = (page, pageSize) => {
    fetchDraftSiteVisitCards(projectID, page, pageSize);
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableView
          onOk={handleOk}
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
          setSiteVisitCardsForExcel={setSiteVisitCardsForExcel}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "Kanban view",
    //   children: (
    //     <KanbanView
    //       siteVisitCards={siteVisitCards}
    //       fetchSiteVisitCards={fetchSiteVisitCards}
    //       loading={loading}
    //       setLoading={setLoading}
    //       onPageChange={onPageChange}
    //       pagination={pagination}
    //       setPagination={setPagination}
    //     />
    //   ),
    // },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarView
          siteVisitCards={siteVisitCards}
          fetchSiteVisitCards={fetchSiteVisitCards}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      key: "4",
      label: (
        <span className="custom-tab-label">
          Site visit drafts{" "}
          {loading ? (
            <span className="loader">
              <Spin />
            </span>
          ) : (
            <span className="draft-count">{draftCount}</span>
          )}
        </span>
      ),
      children: (
        <DraftsTable
          onOk={handleOk}
          siteVisitCards={draftSiteVisitCards}
          setSiteVisitCards={setDraftSiteVisitCards}
          fetchDraftSiteVisitCards={fetchDraftSiteVisitCards}
          draftsLoading={draftsLoading}
          setDraftsLoading={setDraftsLoading}
          onDraftPageChange={onDraftPageChange}
          draftsPagination={draftsPagination}
          setDraftPagination={setDraftPagination}
        />
      ),
    },
  ];

  useEffect(() => {
    fetchSiteVisitCards(projectID);
  }, [items.key, pagination.pageSize, pagination.current]);

  useEffect(() => {
    fetchDraftSiteVisitCards(projectID, 1, draftsPagination.pageSize);
  }, []);

  return (
    <div className="ip-planning-wrapper">
      <div className="tableViewcontainer visitBoardComponent">
        <div className="tableHeader">
          <MessagePopUp
            trigger={message.trigger}
            type={message.type}
            icon={message.icon}
            messageTitle={message.title}
            messageText={message.text}
          />
          <span className="tableViewHeaderText">Visits Board</span>
          <div className="tableViewHeaderButtons">
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
            ]) && (
              <Button className="inviteButtonTPM" onClick={showDeletedModal}>
                <img src={DeleteButton} alt="DeleteButton" />
                See deleted cards
              </Button>
            )}
            <Button
              className={`exportButtonTPM addButtonTPM ${
                removeExportButton ? "d-none" : ""
              }`}
              onClick={() => {
                if (siteVisitCardsForExcel.length !== 0) {
                  exportToExcel(siteVisitCardsForExcel, "SiteVisitCards");
                } else {
                  setMessage({
                    trigger: true,
                    type: "danger",
                    icon: "",
                    title: "Oops! A problem has occurred!",
                    text: "Please select which site visits you want to export!",
                  });
                  setTimeout(() => {
                    setMessage({
                      trigger: false,
                      type: "",
                      icon: "",
                      title: "",
                      text: "",
                    });
                  }, 3000);
                }
              }}
            >
              <img src={ExportButton} alt="ExportButton" />
              Export selected visits
            </Button>
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldLogistics,
            ]) && (
              <Button className="addButtonTPM" onClick={showModal}>
                <img src={AddButton} alt="AddButton" />
                Add new site visit card
              </Button>
            )}
            <AddSiteVisitCardModal
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              setMessage={setMessage}
              savingProgress={savingProgress}
              setSavingProgress={setSavingProgress}
            />
            <DeletedModal
              visible={isDeletedModalVisible}
              onOk={showDeletedModal}
              onCancel={() => {
                setIsDeletedModalVisible(false);
              }}
              fetchSiteVisitCards={fetchSiteVisitCards}
            />
          </div>
        </div>
        <div className="tab-container">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            className="tableviewTabs"
          />
        </div>
      </div>
    </div>
  );
}

export default VisitBoard;
