import { Modal, Form, Input, Button, Divider, Tooltip } from "antd";
import "./style.scss";
import GeneralDetails from "./GeneralDetails";
import LocationDetails from "./LocationDetails";
import VisitDetails from "./VisitDetails";
import Logistics from "./Logistics";
import ResourcesDetails from "./ResourcesDetails";
import TeamDetails from "./TeamDetails";
import Postponement from "./Postponement";
import { useEffect, useState } from "react";
import { httpService } from "../../../../../services/httpService.service";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import moment from "moment";
import { useSiteVisitStore } from "./store/siteVisitStore";
import showConfirm from "../../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";

const SiteVisitModal = ({ visible, onCancel, onOk, setMessage }) => {
  const [form] = Form.useForm();
  const [projectId, setProjectId] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [selectedSectorId, setSelectedSectorId] = useState("");
  const [clusters, setClusters] = useState([]);
  const [singleTeam, setSingleTeam] = useState(null);
  const [allIPPlans, setAllIPPlans] = useState([]);
  const [requiredPhotos, setRequiredPhotos] = useState(false);
  const [photosLimit, setPhotosLimit] = useState(0);
  const [requiredVideos, setRequiredVideos] = useState(false);
  const [videosLimit, setVideosLimit] = useState(0);
  const [kills, setKills] = useState(1);
  const [postponed, setPostponed] = useState(false);
  const [observationList, setObservationList] = useState(false);
  const [siteContactsRequired, setSiteContactsRequired] = useState(false);
  const [selectedIpPlan, setSelectedIpPlan] = useState([]);
  const [showHideIpPlanDetails, setShowHideIpPlanDetails] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [parishCheck, setParishCheck] = useState(false);
  const { setSiteVisitList, setOtherDocuments, setSiteVideos } =
    useSiteVisitStore();

  useEffect(() => {
    const projectId = localStorage.getItem("currentProjectID");
    setProjectId(projectId);
  }, []);

  const formatDate = (date, type) => {
    if (!date?.$isDayjsObject) return "";

    if (type) {
      const newDate = new Date(date);
      const formattedDate = moment(
        new Date(newDate.setDate(newDate.getDate() - 1))
      );
      return formattedDate.format("YYYY-MM-DD HH:mm:ss");
    } else {
      const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");
      return formattedDate;
    }
  };

  const initialValues = {
    observationList: false,
    isVirtual: false,
    trainingIsCompleted: false,
    latitude: "",
    longitude: "",
    beneficiariesCount: 1,
    kills: 1,
    observationChecklistCount: 1,
    mediaLimit: 3,
  };

  const handleSubmit = () => {
    setSubmitLoader(true);
    form
      .validateFields()
      .then((values) => {
        const plannedVisit =
          values.dateOfPlannedVisit &&
          `${formatDate(values.dateOfPlannedVisit)}`;
        const trainingDate =
          values.trainingDate && formatDate(values.trainingDate);
        const actualDateOfVisit =
          values.actualDateOfVisit && formatDate(values.actualDateOfVisit);
        const activationDate =
          values.dateOfPlannedVisit &&
          formatDate(values.dateOfPlannedVisit, "activation");
        const dateOfReport = formatDate(values.dateOfReport);
        const dateOfDebrief = formatDate(values.dateOfDebrief);

        let siteContacts = values.siteContacts ? [values.siteContacts] : [];
        let pointContacts = values.pointContacts ? [values.pointContacts] : [];
        let KIIcontacts = Array.isArray(values.KIIcontacts)
          ? values.KIIcontacts
          : [];

        siteContacts?.map((contact) => (contact.contactType = 1));
        pointContacts?.map((contact) => (contact.contactType = 2));
        KIIcontacts?.map((contact) => (contact.contactType = 3));

        const createContacts = [
          ...siteContacts,
          ...pointContacts,
          ...KIIcontacts,
        ];

        const formData = new FormData();
        formData.append("Name", values.name);
        formData.append("IPPlanId", values.IPlaningCard);
        formData.append("ClusterId", values.cluster);
        formData.append("SectorId", values.sector);
        formData.append("SubSectorId", values.subSector || "");

        formData.append("ProjectId", projectId);
        formData.append("LocationDetails.Latitude", values.latitude);
        formData.append("LocationDetails.Longitude", values.longitude);
        formData.append("Perish", values.parish || false);
        formData.append("isVirtual", values.isVirtual || false);
        formData.append("Visit.PlannedVisit", plannedVisit);

        createContacts?.forEach((contact, index) => {
          formData.append(
            `visit.createContacts[${index}].name`,
            contact.contactName
          );
          formData.append(
            `visit.createContacts[${index}].email`,
            contact.email
          );
          formData.append(`visit.createContacts[${index}].role`, contact.role);
          formData.append(
            `visit.createContacts[${index}].phone`,
            contact.phone || ""
          );
          formData.append(
            `visit.createContacts[${index}].skype`,
            contact.skype || ""
          );
          formData.append(
            `visit.createContacts[${index}].organization`,
            contact.organization || ""
          );
          formData.append(
            `visit.createContacts[${index}].contactType`,
            contact.contactType
          );
        });
        formData.append("Visit.ObservationListCheck", false);
        formData.append(
          "ObservationList",
          values.ObservationListCheck || false
        );
        formData.append(
          "Visit.CreateInterviewDetails.ObservationChecklist",
          values.observationChecklistCount || 0
        );
        formData.append(
          "Visit.CreateInterviewDetails.ObservationChecklistType",
          values.observationChecklistType || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.BeneficiariesCount",
          values.beneficiariesCount || 1
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientsCount",
          values.kills
        );

        formData.append("Visit.CreateInterviewDetails.MediaLimit", 1);
        formData.append(
          "Visit.CreateInterviewDetails.SamplingInstructions",
          values.samplingInstructions || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.ClientInstructions",
          values.clientInstructions || ""
        );
        formData.append(
          "Visit.CreateInterviewDetails.OtherInstructions",
          values.otherInstructions || ""
        );
        formData.append(
          "Visit.VisitPlanApproval",
          values.visitPlanApproval || ""
        );

        formData.append(
          "SpecificsOfOnSiteOperation",
          values?.specificsOfOnSiteOperation
        );
        formData.append("ObservationList", values.observationList || false);

        formData.append("Logistic.TPMCoordinatorId", values.tpmCoordinator);
        formData.append("Logistic.CoordinationType", values.coordinationType);
        formData.append("Logistic.FulcrumAccount", values.fulcrumAccount || "");
        formData.append("Logistic.ActivationDate", activationDate);
        formData.append("Logistic.IsTeamLocal", values.isTeamLocal || false);
        formData.append("Logistic.TeamId", values.team);
        formData.append("Logistic.IsPostponed", values.isPostponed || false);
        formData.append(
          "Logistic.PostponedReason",
          values.postponedReason || ""
        );
        formData.append(
          "Logistic.PostponedDetails",
          values.postponedDetails || ""
        );
        formData.append("Logistic.Notes", values.notes || "");

        formData.append("Tracking.ReportingYear", values.reportingYear);
        formData.append("Tracking.ReportingMonth", values.reportingMonth);
        formData.append("Tracking.TrainingDate", trainingDate);
        formData.append("Tracking.TrainingScoreInfo", values.trainingScoreInfo);
        formData.append("Tracking.ActualDateOfVisit", actualDateOfVisit);
        formData.append("Tracking.DateOfReport", dateOfReport);
        formData.append("Tracking.DateOfDebrief", dateOfDebrief);
        formData.append(
          "Tracking.PercentageOfDataCollectionCompleted",
          values.percentageOfDataCollectionCompleted
        );
        formData.append("Tracking.SiteVisitStatus", 1);
        formData.append("Tracking.FieldTeamReportingStatus", 1);
        formData.append(
          "Tracking.TrainingIsCompleted",
          values.trainingIsCompleted || false
        );

        formData.append("HasVideos", values.videosRequired || false);
        formData.append("VideoCount", values.videosLimit || 0);
        formData.append("HasPhotos", values.photosRequired || false);
        formData.append("PhotoCount", values.photosLimit || 0);

        const reportDocs = values.report_documents || [];
        const otherFiles = values.other_files || [];
        const videoFiles = values.video_files || [];

        reportDocs?.forEach((file, index) => {
          const newIndex = index;
          formData.append(`Files[${newIndex}].FileCategory`, 1);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        otherFiles?.forEach((file, index) => {
          const newIndex = index + (reportDocs?.length || 0);
          formData.append(`Files[${newIndex}].FileCategory`, 2);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        const videosUploaded = [];
        const imagesUploaded = [];
        videoFiles?.forEach((file) => {
          if (file.type.startsWith("image")) {
            imagesUploaded.push(file);
          } else if (file.type.startsWith("video")) {
            videosUploaded.push(file);
          }
        });

        videosUploaded?.forEach((file, index) => {
          const newIndex =
            index + (reportDocs?.length || 0) + (otherFiles?.length || 0);
          formData.append(`Files[${newIndex}].FileCategory`, 3);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        imagesUploaded?.forEach((file, index) => {
          const newIndex =
            index +
            (reportDocs?.length || 0) +
            (otherFiles?.length || 0) +
            (videosUploaded?.length || 0);
          formData.append(`Files[${newIndex}].FileCategory`, 4);
          formData.append(`Files[${newIndex}].file`, file.originFileObj);
        });

        httpService.post(
          "/api/Site/createSiteVisitCard",
          formData,
          (res) => {
            setMessage({
              trigger: true,
              type: "success",
              icon: "",
              title: "Site vist card created succesfully!",
              text: "A new site visit card has been created and saved into the database!",
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
            setSubmitLoader(false);
            form.resetFields();
            onOk();
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "Failed to create site visit. Please try again later.";
            console.error("There was an error adding the project!", error);
            setSubmitLoader(false);
            setMessage({
              trigger: true,
              type: "danger",
              icon: "",
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setTimeout(() => {
              setMessage({
                trigger: false,
                type: "",
                icon: "",
                title: "",
                text: "",
              });
            }, 3000);
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        setSubmitLoader(false);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    // Scroll to the first field with a validation error
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const onClose = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    onCancel();

    form.resetFields();
    setSiteVisitList([]);
    setOtherDocuments([]);
    setSiteVideos([]);
    setSingleTeam(null);
    setRequiredPhotos(false);
    setPhotosLimit(0);
    setRequiredVideos(false);
    setObservationList(false);
    setVideosLimit(0);
    setKills(1);
    setClusters([]);
    setSectors([]);
    setSelectedIpPlan("");
    setShowHideIpPlanDetails(false);
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const postJsonData = (values, filesData) => {
    const files = {
      report_documents: [],
      other_files: [],
      video_files: [],
    };
    if (filesData) {
      filesData?.forEach((file) => {
        if (file?.entityId.endsWith("11111")) {
          files?.report_documents.push(file);
        } else if (file?.entityId.endsWith("22222")) {
          files?.other_files.push(file);
        } else if (
          file?.entityId.endsWith("33333") ||
          file?.entityId.endsWith("44444")
        ) {
          files?.video_files.push(file);
        }
      });
    }
    const selectedCluster = clusters?.filter(
      (cluster) => cluster.id === values.cluster
    );

    const selectedIpPlan = allIPPlans.filter(
      (ipPlan) => ipPlan.id === values.IPlaningCard
    );

    let siteContacts = [values.siteContacts] || [];
    let pointContacts = [values.pointContacts] || [];
    let KIIcontacts = values.KIIcontacts || [];

    siteContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 1;
      }
    });
    pointContacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 2;
      }
    });
    KIIcontacts?.forEach((contact) => {
      if (contact) {
        contact.contactType = 3;
      }
    });

    const siteVisitDraft = {
      ProjectId: projectId,
      name: values.name,
      plannedVisit: formatDate(values.dateOfPlannedVisit),
      trainingDate: formatDate(values.trainingDate),
      actualDateOfVisit: formatDate(values.actualDateOfVisit),
      activationDate: formatDate(values.dateOfPlannedVisit, "activation"),
      dateOfReport: formatDate(values.dateOfReport),
      dateOfDebrief: formatDate(values.dateOfDebrief),
      IPPlan: selectedIpPlan,
      Cluster: selectedCluster,
      SectorId: values.sector,
      SubSectorId: values.subSector,
      LocationDetails: {
        Latitude: values.latitude,
        Longitude: values.longitude,
        isVirtual: values.isVirtual || false,
      },
      parish: values?.parish || "false",
      Visit: {
        PlannedVisit: values.plannedVisit,
        siteContactsRequired: values.siteContactsRequired,
        siteContacts: siteContacts,
        pointContacts: pointContacts,
        KIIcontacts: KIIcontacts,
        ObservationListCheck: values.ObservationListCheck,
        observationChecklistType: values.observationChecklistType,
        CreateInterviewDetails: {
          BeneficiariesCount: values.beneficiariesCount,
          ClientsCount: values.ClientsCount,
          ObservationChecklist: values.observationChecklistCount,
          MediaLimit: values.MediaLimit,
          SamplingInstructions: values.samplingInstructions,
          ClientInstructions: values.clientInstructions,
          OtherInstructions: values.otherInstructions,
        },
        VisitPlanApproval: values.visitPlanApproval,
      },
      SpecificsOfOnSiteOperation: values.specificsOfOnSiteOperation,
      ObservationList: values.observationList,
      Logistic: {
        TPMCoordinatorId: values.tpmCoordinator,
        CoordinationType: values.coordinationType,
        FulcrumAccount: values.fulcrumAccount,
        ActivationDate: values.activationDate,
        IsTeamLocal: values.isTeamLocal,
        TeamId: values.team,
        IsPostponed: values.isPostponed,
        PostponedReason: values.postponedReason,
        PostponedDetails: values.postponedDetails,
        Notes: values.notes,
      },
      ...(files && {
        files: files,
      }),
      HasVideos: values?.videosRequired,
      VideoCount: values?.videosLimit,

      HasPhotos: values?.photosRequired,
      PhotoCount: values?.photosLimit,
    };

    console.log(siteVisitDraft);

    const jsonData = JSON.stringify(siteVisitDraft);

    const params = {
      projectId: projectId,
      jsonData: jsonData,
      type: 1,
    };

    httpService.post(
      "/api/Draft/createOrEditDraft",
      params,
      (res) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Draft site visit card created successfully!",
          text: "A new draft site visit card has been created and saved into the database!",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
        setSubmitLoader(false);
        handleLeave();
        onOk();
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to create draft site visit. Please try again later.";
        console.error("There was an error adding the project!", error);
        setSubmitLoader(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
            type: "",
            icon: "",
            title: "",
            text: "",
          });
        }, 3000);
      }
    );
  };

  const handleSaveProgress = async () => {
    setSubmitLoader(true);
    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value?.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: "",
        title: "Validation Error",
        text: "You cannot save this site visit card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({ trigger: false });
      }, 1000);
      setSubmitLoader(false);
      return;
    }

    const reportedDocuments = values.report_documents;
    const otherFiles = values.other_files;
    const videoFiles = values.video_files;
    if (
      reportedDocuments?.length > 0 ||
      otherFiles?.length > 0 ||
      videoFiles?.length > 0
    ) {
      const formData = new FormData();

      formData.append("FolderName", "TPM");
      formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

      reportedDocuments?.forEach((file, index) => {
        formData.append(
          `entityFormFileDtos[${index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f611111"
        );
        formData.append(
          `entityFormFileDtos[${index}].formFile`,
          file.originFileObj
        );
      });

      otherFiles?.forEach((file, index) => {
        const filesLength = reportedDocuments?.length || 0;
        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f622222"
        );
        formData?.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      const videosUploaded = [];
      const imagesUploaded = [];
      videoFiles.forEach((file) => {
        if (file.type.startsWith("image")) {
          imagesUploaded.push(file);
        } else if (file.type.startsWith("video")) {
          videosUploaded.push(file);
        }
      });
      videosUploaded?.forEach((file, index) => {
        const filesLength =
          (reportedDocuments?.length || 0) + (otherFiles?.length || 0);

        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f633333"
        );
        formData.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      imagesUploaded?.forEach((file, index) => {
        const filesLength =
          videosUploaded?.length +
          (reportedDocuments?.length || 0) +
          (otherFiles?.length || 0);

        formData.append(
          `entityFormFileDtos[${filesLength + index}].entityId`,
          "3fa85f64-5717-4562-b3fc-2c963f644444"
        );
        formData.append(
          `entityFormFileDtos[${filesLength + index}].formFile`,
          file.originFileObj
        );
      });

      axios
        .post(
          "https://kapfilemanager.kapsurvey.com/FileData/addFile",
          formData,
          {
            headers: {
              Accept: "text/plain",
            },
          }
        )
        .then((response) => {
          const filesData = response.data.map((fileData) => fileData);
          postJsonData(values, filesData);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });
    } else {
      postJsonData(values);
    }
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable={false}
      width={800}
      footer={[
        <Button
          className="inviteButtonTPM"
          key="back"
          onClick={handleSaveProgress}
          loading={submitLoader}
        >
          Save as draft
        </Button>,
        <Button
          className="addButtonTPM"
          key="submit"
          type="primary"
          loading={submitLoader}
          onClick={handleSubmit}
        >
          Add site visit card
        </Button>,
      ]}
      title={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={cancelX}
              style={{ marginLeft: "auto", cursor: "pointer" }}
              onClick={onClose}
              alt="cancelX"
            />
          </div>
          <h2 className="modalHeader">Add site visit card</h2>
        </>
      }
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Site visit card identifier" name="identifier">
          <div className="form-control-plaintext">
            <p
              style={{
                border: "2px solid #d9d9d9",
                borderRadius: "10px",
                padding: "8px 12px",
                fontSize: "14px",
                color: "#495057",
              }}
            >
              <Tooltip title="This number will be generated automatically!">
                <span>00X - </span>
              </Tooltip>
              <Tooltip title="Selected Implementing Partner!">
                <span>
                  {selectedIpPlan?.implementingPartner?.name !== undefined
                    ? selectedIpPlan?.implementingPartner?.name
                    : "Implementing Partner"}
                </span>
              </Tooltip>
              <Tooltip title="Selected location city!">
                <span> - Location </span>
              </Tooltip>
              <Tooltip title="Created date!">
                <span>{`- ${formattedDate}`}</span>
              </Tooltip>
            </p>
          </div>
        </Form.Item>
        <GeneralDetails
          form={form}
          onClose={onClose}
          selectedSectorId={selectedSectorId}
          setSelectedSectorId={setSelectedSectorId}
          clusters={clusters}
          setClusters={setClusters}
          allIPPlans={allIPPlans}
          setAllIPPlans={setAllIPPlans}
          sectors={sectors}
          setSectors={setSectors}
          selectedIpPlan={selectedIpPlan}
          setSelectedIpPlan={setSelectedIpPlan}
        />
        <Divider />
        <h3>Location details</h3>
        <LocationDetails
          form={form}
          isEditing={false}
          onClose={onClose}
          parishCheck={parishCheck}
          setParishCheck={setParishCheck}
          selectedIpPlan={selectedIpPlan}
          setSelectedIpPlan={setSelectedIpPlan}
          showHideIpPlanDetails={showHideIpPlanDetails}
          setShowHideIpPlanDetails={setShowHideIpPlanDetails}
        />

        <Divider />
        <h3>Visit details</h3>

        <VisitDetails
          form={form}
          kills={kills}
          setKills={setKills}
          requiredPhotos={requiredPhotos}
          setRequiredPhotos={setRequiredPhotos}
          requiredVideos={requiredVideos}
          setRequiredVideos={setRequiredVideos}
          siteContactsRequired={siteContactsRequired}
          setSiteContactsRequired={setSiteContactsRequired}
          setVideosLimit={setVideosLimit}
          videosLimit={videosLimit}
          photosLimit={photosLimit}
          setPhotosLimit={setPhotosLimit}
          observationList={observationList}
          setObservationList={setObservationList}
        />

        <Divider />

        <h3>Logistics</h3>

        <Logistics />

        <Divider />
        <h3>Team</h3>
        <TeamDetails
          onCancel={onClose}
          singleTeam={singleTeam}
          setSingleTeam={setSingleTeam}
        />

        <Divider />
        <h3>Postponement</h3>
        <Postponement postponed={postponed} setPostponed={setPostponed} />

        <Divider />
        <h3>Resources</h3>

        <ResourcesDetails
          form={form}
          requiredPhotos={requiredPhotos}
          photosLimit={photosLimit}
          requiredVideos={requiredVideos}
          videosLimit={videosLimit}
          setMessage={setMessage}
        />

        <Divider />

        {/* <h3>Tracking</h3>
        <TrackingDetails /> */}

        {/* <Divider /> */}
      </Form>
    </Modal>
  );
};
export default SiteVisitModal;
