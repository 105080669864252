import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../../services/httpService.service";
import { toBeVisible } from "@testing-library/jest-dom/dist/matchers";

const { Option } = Select;

const PreviewTracking = ({ visible, onClose, siteVisitCardId }) => {
  console.log(siteVisitCardId);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [siteVisitCard, setSiteVisitCard] = useState({});
  const [filteredContacts, setFilteredContacts] = useState({
    siteContacts: [],
    pointOfContacts: [],
    KIIcontacts: [],
  });

  useEffect(() => {
    if (siteVisitCardId && toBeVisible) {
      setLoading(true);
      const apiSiteVisitCards = `/api/Site/getSiteVisit?id=${siteVisitCardId}`;

      httpService.get(
        apiSiteVisitCards,
        (res) => {
          setSiteVisitCard(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [siteVisitCardId]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    const contacts = siteVisitCard?.visit?.contacts || [];
    const siteContacts = contacts.filter(
      (contact) => contact.contactType === 1
    );
    const pointOfContacts = contacts.filter(
      (contact) => contact.contactType === 2
    );
    const KIIcontacts = contacts.filter((contact) => contact.contactType === 3);

    setFilteredContacts({
      siteContacts,
      pointOfContacts,
      KIIcontacts,
    });
  }, [siteVisitCard]);

  const renderMonth = (month) => {
    switch (month) {
      case "01":
        return "January";
        break;
      case "02":
        return "Febuary";
        break;
      case "03":
        return "March";
        break;
      case "04":
        return "April";
        break;
      case "05":
        return "May";
        break;
      case "06":
        return "June";
        break;
      case "07":
        return "July";
        break;
      case "08":
        return "August";
        break;
      case "09":
        return "September";
        break;
      case "10":
        return "October";
        break;
      case "11":
        return "November";
        break;
      case "12":
        return "December";
        break;
      default:
        return "Not set";
    }
  };

  const checkSiteVisitStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        text = "No Status";
        break;
      case 2:
        className = "prePlanningPhase";
        fill = "var(--Primary-Blueberry-700, #325899)";
        text = "Pre-planning phase";
        break;
      case 3:
        className = "planning";
        fill = "#964B00";
        text = "Planning";
        break;
      case 4:
        className = "dataCollection";
        fill = "#3B81FE";
        text = "Data Collection";
        break;
      case 5:
        className = "dataCheck";
        fill = "#045B46";
        text = "Post data collection/Data check";
        break;
      case 6:
        className = "visitCompleted";
        fill = "#0F7507";
        text = "Visit Completed";
        break;
      default:
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        text = "No status";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  const checkReportingStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        text = "Data collection";
        className = "dataCollection";
        fill = "#3B81FE";
        break;
      case 2:
        text = "Data check";
        className = "dataCheck";
        fill = "#045B46";
        break;
      case 3:
        text = "Field reporting writing";
        className = "fieldReportWriting";
        fill = "var(--Range-assessment-Excellent, #3D9B4C)";
        break;
      case 4:
        text = "Translation";
        className = "translation";
        fill = "#918d27";
        break;
      case 5:
        text = "Field reporting completed";
        className = "fieldReportCompleted";
        fill = "#9670b8";
        break;
      case 6:
        text = "Field report approved";
        className = "fieldReportApproved";
        fill = "var(--Graph-Green-1, #06768E)";
        break;
      case 7:
        text = "Field report submitted to client";
        className = "fieldReportSubmitted";
        fill = "var(--Graph-Green-3, #6FC5D5)";
        break;
      default:
        text = "Unknown";
        className = "noStatus";
        fill = "rgba(172, 12, 12, 0.777)";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };
  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Site visit identifier</span>
            }
          >
            <p className="previewText">{siteVisitCard?.identifier}</p>
          </Form.Item>
          {siteVisitCard.tracking && (
            <>
              <p className="modalsHeader1">Tracking</p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="reportingYear"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting year</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.reportingYear}
                  </p>
                </Form.Item>
                <Form.Item
                  name="reportingMonth"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting month</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {renderMonth(siteVisitCard?.tracking?.reportingMonth)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="trainingIsCompleted"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Training Completed
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {siteVisitCard?.tracking?.trainingIsCompleted
                      ? "Yes"
                      : "No"}
                  </p>
                </Form.Item>
              </div>

              <Form.Item
                name="trainingDate"
                label={
                  <span style={{ fontWeight: "bold" }}>Training date</span>
                }
                style={{ width: "33%" }}
              >
                <p className="previewText">
                  {formatDate(siteVisitCard?.tracking?.trainingDate)}
                </p>
              </Form.Item>
              <p className="modalsHeader1" style={{ fontSize: "14px" }}>
                Training score info
              </p>
              <div className="w-75 d-flex flex-column mb-5">
                <div className="d-flex flex-nowrap flex-row justify-content-between px-3 me-5">
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1"
                  >
                    Enumerator
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1 me-4"
                  >
                    Score
                  </p>
                </div>
                <div className="d-flex flex-column gap-3">
                  {siteVisitCard?.logistic?.team?.enumerators?.map(
                    (enumerator) => (
                      <div
                        key={enumerator?.id}
                        style={{
                          display: "flex",
                          padding: "10px 16px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "24px",
                          borderRadius: "8px",
                          border: "1px solid var(--5, #F5B0D2)",
                          background: "var(--7, #FFF5FA)",
                        }}
                        className="d-flex flex-row justify-content-start"
                      >
                        <p
                          className="m-0 p-0 w-75"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          {enumerator?.firstName && enumerator?.lastName
                            ? enumerator?.firstName + " " + enumerator?.lastName
                            : enumerator?.email}
                        </p>
                        <p
                          className="m-0 p-0"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          10
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Item
                  name="actualDateOfVisit"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Actual date of visit
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.actualDateOfVisit)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfReport"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Date of field report
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfReport)}
                  </p>
                </Form.Item>
                <Form.Item
                  name="dateOfDebrief"
                  label={
                    <span style={{ fontWeight: "bold" }}>Date of debrief</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText">
                    {formatDate(siteVisitCard?.tracking?.dateOfDebrief)}
                  </p>
                </Form.Item>
              </div>

              <p className="modalsHeader1" style={{ fontSize: "14px" }}>
                % of data collection completed
              </p>
              <div className="w-75 d-flex flex-column mb-5">
                <div className="d-flex flex-nowrap flex-row justify-content-between px-3">
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1"
                  >
                    Enumerator
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                    className="modalsHeader1 me-3"
                  >
                    % completed
                  </p>
                </div>
                <div className="d-flex flex-column gap-3">
                  {siteVisitCard?.logistic?.team?.enumerators?.map(
                    (enumerator) => (
                      <div
                        key={enumerator?.id}
                        style={{
                          display: "flex",
                          padding: "10px 16px",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "24px",
                          borderRadius: "8px",
                          border: "1px solid var(--5, #F5B0D2)",
                          background: "var(--7, #FFF5FA)",
                        }}
                        className="d-flex flex-row justify-content-start"
                      >
                        <p
                          className="m-0 p-0 w-75"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          {enumerator?.firstName && enumerator?.lastName
                            ? enumerator?.firstName + " " + enumerator?.lastName
                            : enumerator?.email}
                        </p>
                        <p
                          className="m-0 p-0"
                          style={{
                            color: "var(--Neutrals-Black, #3A3737)",
                            fontFamily: "Lato",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "22px",
                          }}
                        >
                          10%
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Form.Item
                  name="siteVisitStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>
                      Site visit status
                    </span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText implementationPage">
                    {checkSiteVisitStatus(
                      siteVisitCard?.tracking?.siteVisitStatus
                    )}
                  </p>
                </Form.Item>
                <Form.Item
                  name="fieldTeamReportingStatus"
                  label={
                    <span style={{ fontWeight: "bold" }}>Reporting Status</span>
                  }
                  style={{ width: "100%" }}
                >
                  <p className="previewText implementationPage">
                    {checkReportingStatus(
                      siteVisitCard?.tracking?.fieldTeamReportingStatus
                    )}
                  </p>
                </Form.Item>
              </div>
            </>
          )}

          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PreviewTracking;
