import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Spin,
  Divider,
} from "antd";
import cancelX from "../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../services/httpService.service";
import uploadIcon from "../../../../../Assets/svg/uploadIcon.svg";
import { useFilePreview } from "../../../completed/ReportingList/Download";

const { Option } = Select;

const Preview = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  const [redFlag, setRedFlag] = useState();
  const [loading, setLoading] = useState(false);
  const { previewFiles } = useFilePreview();

  useEffect(() => {
    if (record?.key) {
      fetchRedFlag(record?.key);
    }
  }, [form, visible, record]);

  async function fetchRedFlag(redFlagId) {
    setLoading(true);

    httpService.get(
      `/api/RedFlag/getRedFlag?id=${redFlagId}`,
      (res) => {
        setRedFlag(res.data);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching the red flag:", error);
        setLoading(false);
      }
    );
  }

  if (!record) {
    return null;
  }

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const checkSiteVisitStatus = (statusText) => {
    console.log("statusText", statusText, typeof statusText);

    let className = "";
    let fill = "";
    let text = "";

    switch (Number(statusText)) {
      case 1:
        className = "notAddressed";
        fill = "#AA1A5F";
        text = "Not addressed";
        break;
      case 2:
        className = "assigned";
        fill = "var(--system-yellow-warning, #FC9403)";
        text = "Assigned";
        break;
      case 3:
        className = "inProgress";
        fill = "var(--Primary-Blueberry-700, #325899);";
        text = "In progress";
        break;
      case 4:
        className = "onHold";
        fill = "#D46600";
        text = "On Hold";
        break;
      case 5:
        className = "resolved";
        fill = "#045B46";
        text = "Resolved";
        break;
      case 6:
        className = "archived";
        fill = "var(--Neutrals-Gray, #555)";
        text = "Archived";
        break;
      default:
        className = "noStatus";
        fill = "red";
        text = "No status";
        break;
    }

    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8px"
          height="8px"
          viewBox="0 0 9 9"
        >
          <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
        </svg>
        <span>{text}</span>
      </div>
    );
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return `${formattedDate}`;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={600}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview red flag card</h2>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "50vh" }}
        >
          <Spin spinning={loading}> </Spin>{" "}
        </div>
      ) : (
        <Form form={form} layout="vertical">
          {" "}
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>
                Name (type of red flag)
              </span>
            }
          >
            <p className="previewText">{redFlag?.name}</p>
          </Form.Item>
          <Form.Item
            name="ip"
            label={<span style={{ fontWeight: "bold" }}>Site visit card</span>}
          >
            <p className="previewText">{redFlag?.siteVisitIdentifier}</p>
          </Form.Item>
          <Form.Item
            name="ip"
            label={
              <span style={{ fontWeight: "bold" }}>Implementing Partner</span>
            }
          >
            <p className="previewText">{redFlag?.implementingPartnerName}</p>
          </Form.Item>
          <Form.Item
            name="ip"
            label={<span style={{ fontWeight: "bold" }}>Assign to</span>}
          >
            <p className="previewText">
              {redFlag?.assignedTo?.firstName && redFlag?.assignedTo?.lastName
                ? `${redFlag?.assignedTo?.firstName} ${redFlag?.assignedTo?.lastName}`
                : redFlag?.assignedTo?.username}
            </p>
          </Form.Item>
          <Form.Item
            name="description"
            label={<span style={{ fontWeight: "bold" }}>Description</span>}
          >
            <p className="previewText">{redFlag?.description}</p>
          </Form.Item>
          <Form.Item
            name="dateOfSiteVisit"
            label={
              <span style={{ fontWeight: "bold" }}>Date of the red flag</span>
            }
          >
            <p className="previewText">
              {formatDate(redFlag?.dateOfSiteVisit)}
            </p>
          </Form.Item>
          <Form.Item
            name="dueDate"
            label={
              <span style={{ fontWeight: "bold" }}>Due date for action</span>
            }
          >
            <p className="previewText">{formatDate(redFlag?.dueDate)}</p>
          </Form.Item>
          <Form.Item
            name="status"
            label={<span style={{ fontWeight: "bold" }}>Status</span>}
          >
            <p className="previewText flagsPage">
              {checkSiteVisitStatus(redFlag?.flagStatus)}
            </p>
          </Form.Item>
          <Form.Item
            name="followUpNotes"
            label={<span style={{ fontWeight: "bold" }}>Follow Up Notes</span>}
          >
            <p className="previewText">{redFlag?.followUpNotes}</p>
          </Form.Item>
          {redFlag?.redFlagFiles?.length > 0 && (
            <p className="modalsHeader1">Evidence</p>
          )}
          <div className="filesWrapper my-4">
            {redFlag?.redFlagFiles?.map((file) => (
              <div
                className="fileDetails"
                style={{ cursor: "pointer" }}
                onClick={(e) => previewFiles(e, file.filePath, file.id)}
              >
                <div className="fileIcon">
                  <img src={uploadIcon} alt="uploaded" />
                </div>
                <div className="fileInfo">
                  <span>{file?.fileName}</span>
                  <p className="mb">{file?.fileSize}</p>
                </div>
              </div>
            ))}
          </div>
          <Divider />
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default Preview;
