import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const DeleteComponent = ({
  text,
  header,
  visible,
  onClose,
  onConfirm,
  loading,
}) => {
  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <div>
      <Modal
        visible={visible}
        closable={false}
        onCancel={handleCancel}
        footer={false}
        width={600}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <img
            src={cancelX}
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={handleCancel}
          />
        </div>
        <h2 className="modalHeader">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            style={{ marginRight: "10px" }}
          >
            <path
              d="M9.99995 5.71582V10.3587M13.9898 18.8688C13.7219 19.1367 13.3586 19.2872 12.9797 19.2872H7.02028C6.64141 19.2872 6.27804 19.1367 6.01014 18.8688L1.13268 13.9914C0.864774 13.7235 0.714264 13.3601 0.714264 12.9812V7.02184C0.714264 6.64296 0.864774 6.27959 1.13268 6.01169L6.01014 1.13424C6.27804 0.86633 6.64141 0.71582 7.02028 0.71582H12.9797C13.3586 0.71582 13.7219 0.86633 13.9898 1.13424L18.8673 6.01169C19.1351 6.27959 19.2857 6.64296 19.2857 7.02184V12.9812C19.2857 13.3601 19.1351 13.7235 18.8673 13.9914L13.9898 18.8688Z"
              stroke="#AA1A5F"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <mask id="path-2-inside-1_3324_20473" fill="white">
              <path d="M10.0005 14.1661C9.53999 14.1661 9.16672 13.7931 9.16672 13.3328C9.16672 12.8725 9.53999 12.4995 10.0005 12.4995" />
              <path d="M9.99931 14.1662C10.4601 14.1662 10.8334 13.7932 10.8334 13.3329C10.8334 12.8726 10.4601 12.4995 9.99931 12.4995" />
            </mask>
            <path
              d="M10.0005 15.6661C10.8289 15.6661 11.5005 14.9946 11.5005 14.1661C11.5005 13.3377 10.8289 12.6661 10.0005 12.6661V15.6661ZM10.0005 13.9995C10.8289 13.9995 11.5005 13.3279 11.5005 12.4995C11.5005 11.6711 10.8289 10.9995 10.0005 10.9995V13.9995ZM9.99931 12.6662C9.17088 12.6662 8.49931 13.3378 8.49931 14.1662C8.49931 14.9946 9.17088 15.6662 9.99931 15.6662V12.6662ZM9.99931 10.9995C9.17088 10.9995 8.49931 11.6711 8.49931 12.4995C8.49931 13.328 9.17088 13.9995 9.99931 13.9995V10.9995ZM10.0005 12.6661C10.3676 12.6661 10.6667 12.9639 10.6667 13.3328H7.66672C7.66672 14.6223 8.71236 15.6661 10.0005 15.6661V12.6661ZM10.6667 13.3328C10.6667 13.7018 10.3676 13.9995 10.0005 13.9995V10.9995C8.71236 10.9995 7.66672 12.0433 7.66672 13.3328H10.6667ZM9.99931 15.6662C11.2874 15.6662 12.3334 14.6228 12.3334 13.3329H9.33338C9.33338 12.9635 9.63286 12.6662 9.99931 12.6662V15.6662ZM12.3334 13.3329C12.3334 12.043 11.2874 10.9995 9.99931 10.9995V13.9995C9.63286 13.9995 9.33338 13.7022 9.33338 13.3329H12.3334Z"
              fill="#AA1A5F"
              mask="url(#path-2-inside-1_3324_20473)"
            />
          </svg>
          {header}
        </h2>
        <p className="inviteText">
          {text} <b>Are you sure you want to continue?</b>
        </p>
        <div className="buttonsModal">
          <Button
            className="inviteButtonTPM"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            className="addButtonTPM"
            key="submit"
            type="primary"
            onClick={handleConfirm}
            loading={loading}
          >
            {!loading ? "Delete" : "Deleting..."}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

DeleteComponent.propTypes = {
  text: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

export default DeleteComponent;
