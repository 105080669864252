import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spin } from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import { httpService } from "../../../services/httpService.service";

const PreviewMember = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (record) {
      setLoading(true);
      const memberId = record?.id;
      httpService.get(
        `/api/User?userId=${memberId}`,
        (res) => {
          setUser(res.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [record]);

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const getContractNames = (userContractRoles) => {
    if (!userContractRoles) return [];
    return userContractRoles
      .filter((contract) => contract?.contract?.name)
      .map((contract) => contract.contract.name);
  };

  const roleNames = user?.userContractRoles?.[0]?.role?.roleName;
  const contractNames = getContractNames(user?.userContractRoles).join(", ");

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview Member</h2>
      <Spin spinning={loading}>
        {" "}
        {/* Loader wrapper around the form */}
        <Form form={form} layout="vertical">
          <p className="modalsHeader1">Personal Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {user?.userDetails?.firstName ? (
              <Form.Item
                name="name"
                label={<span style={{ fontWeight: "bold" }}>Name</span>}
              >
                <p className="previewText">
                  {user?.userDetails?.firstName || user?.userDetails?.lastName
                    ? `${user?.userDetails?.firstName} ${user?.userDetails?.lastName}`
                    : "N/A"}
                </p>
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item
              name="email"
              label={<span style={{ fontWeight: "bold" }}>Email</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{user?.email}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {user?.userDetails?.gender ? (
              <Form.Item
                name="gender"
                label={<span style={{ fontWeight: "bold" }}>Gender</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {user?.userDetails?.gender === 1 ? "Male" : "Female"}
                </p>
              </Form.Item>
            ) : (
              ""
            )}
            {user?.languages?.length ? (
              <Form.Item
                name="language"
                label={<span style={{ fontWeight: "bold" }}>Language</span>}
                style={{ width: "48%" }}
              >
                <p className="previewText">
                  {Array.isArray(user?.languages)
                    ? user?.languages
                        ?.map((language) => language.name)
                        .join(", ")
                    : user?.languages}
                </p>
              </Form.Item>
            ) : (
              ""
            )}
          </div>

          {user?.userDetails?.position && (
            <Form.Item
              name="position"
              label={<span style={{ fontWeight: "bold" }}>Position</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{user?.userDetails?.position}</p>
            </Form.Item>
          )}
          <p className="modalsHeader1">Location Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="country"
              label={<span style={{ fontWeight: "bold" }}>Offices</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">
                {user?.offices?.map((office) => office.name).join(", ")}
              </p>
            </Form.Item>
          </div>
          <p className="modalsHeader1">Roles and Workspaces</p>
          <div className="rolesAndPremissions">
            <Form.Item
              name="roles"
              label={<span style={{ fontWeight: "bold" }}>Roles</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{roleNames}</p>
            </Form.Item>
            <Form.Item
              name="workspaces"
              label={<span style={{ fontWeight: "bold" }}>Workspaces</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{contractNames}</p>
            </Form.Item>
          </div>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PreviewMember;
