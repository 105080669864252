import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableViewReporting from "./TableView/TableView";
import KanbanViewReporting from "./KanbanView/KanbanView";
import CalendarViewReporting from "./CalendarView/CalendarView";
import { httpService } from "../../../../services/httpService.service";
import { exportToExcel } from "../../../components/ExportToExcel/exportToExcel";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

function FieldTeamStatusBoard() {
  const [data, setData] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");
  const [removeExportButton, setRemoveExportButton] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: null,
    FieldTeamReportingStatus: null,
    ClusterId: "",
    Month: "",
    Year: "",
    Search: "",
  });

  const fetchSiteVisits = async (ignorePagination) => {
    const params = {
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters.SiteVisitStatus,
      FieldTeamReportingStatus: filters.FieldTeamReportingStatus,
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      Search: filters.Search,
      IncludeOnlyWithTracking: true,
      IgnorePagination: ignorePagination,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    setLoading(true);
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        setData(response.data);
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visits:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
  }, [filters, pagination.pageSize, pagination.current]);

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const changeSiteVisitCardStatus = (draggedTask) => {
    setLoading(true);
    const draggedSiteVisit = data?.filter(
      (siteVisitCard) => siteVisitCard?.id === draggedTask?.id
    );
    if (!draggedSiteVisit || draggedSiteVisit.length === 0) {
      console.error("No matching site visit card found");
      return;
    }

    const trackingId = draggedSiteVisit[0]?.tracking?.id;
    const fieldTeamReportingStatus = Number(draggedTask.column);

    if (!trackingId || isNaN(fieldTeamReportingStatus)) {
      console.error("Invalid trackingId or siteVisitStatus", {
        trackingId,
        fieldTeamReportingStatus,
      });
      return;
    }

    const updatedSiteVisitCards = data.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          tracking: {
            ...card.tracking,
            fieldTeamReportingStatus: fieldTeamReportingStatus,
          },
        };
      }
      return card;
    });

    setData(updatedSiteVisitCards);

    httpService.put(
      `/api/Site/changeFieldTeamReportingStatus?TrackingId=${trackingId}&FieldTeamReportingStatus=${fieldTeamReportingStatus}`,
      "",
      (response) => {
        fetchSiteVisits(false);
        setLoading(false);
      },
      (error) => {
        console.error("Error:", error);
        setLoading(false);
      }
    );

    setTimeout(() => {}, 3000);
  };

  const columns = [
    { id: 1, title: "Data Collection", color: "#3B81FE" },
    { id: 2, title: "Data Check", color: "#045B46" },
    {
      id: 3,
      title: "Field Reporting writing",
      color: "var(--Range-assessment-Excellent, #3D9B4C)",
    },
    { id: 4, title: "Translation", color: "#918d27" },
    {
      id: 5,
      title: "Field report completed",
      color: "var(--Graph-Green-3, #9670b8)",
    },
    {
      id: 6,
      title: "Field report approved",
      color: "var(--Graph-Green-1, #06768E)",
    },
    {
      id: 7,
      title: "Field report submitted to client",
      color: "var(--Graph-Green-3, #6FC5D5)",
    },
  ];
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate} Time: ${formattedTime}`;
  };
  // format data for Kanban view
  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: formatDate(siteVisitCard?.createdDate),
        ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      column: siteVisitCard?.tracking?.fieldTeamReportingStatus,
    };
  };

  const formattedData = data.map(formatData);

  const onChange = (key) => {
    if (key === "1") {
      setRemoveExportButton(false);
    } else {
      setRemoveExportButton(true);
    }

    if (key === "3") {
      fetchSiteVisits(true);
    } else {
      fetchSiteVisits(false);
    }
  };

  const items = [
    {
      key: "1",
      label: "Table view",
      children: (
        <TableViewReporting
          data={data}
          handleFilterChange={handleFilterChange}
          loading={loading}
          filters={filters}
          setFilters={setFilters}
          setDataForExcel={setDataForExcel}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
    },
    {
      key: "2",
      label: "Kanban view",
      children: (
        <KanbanViewReporting
          tasks={formattedData}
          columns={columns}
          changeStatus={changeSiteVisitCardStatus}
          handleFilterChange={handleFilterChange}
          setFilters={setFilters}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
        />
      ),
    },
    {
      key: "3",
      label: "Calendar view",
      children: (
        <CalendarViewReporting
          data={data}
          filters={filters}
          handleFilterChange={handleFilterChange}
          setFilters={setFilters}
          onPageChange={onPageChange}
          pagination={pagination}
          setPagination={setPagination}
        />
      ),
    },
  ];

  return (
    <div className="tableViewcontainer visitBoardComponent">
      <div className="tableHeader">
        <span className="tableViewHeaderText">Field Team Status Board</span>
        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="tableViewHeaderButtons">
          <Button
            className={`exportButtonTPM addButtonTPM ${
              removeExportButton ? "d-none" : ""
            }`}
            onClick={() => {
              if (dataForExcel.length !== 0) {
                exportToExcel(dataForExcel, "ReportingSiteVisits");
              } else {
                setMessage({
                  trigger: true,
                  type: "danger",
                  icon: "",
                  title: "Oops! A problem has occurred!",
                  text: "Please select which site visits you want to export!",
                });
                setTimeout(() => {
                  setMessage({
                    trigger: false,
                    type: "",
                    icon: "",
                    title: "",
                    text: "",
                  });
                }, 3000);
              }
            }}
          >
            <img src={ExportButton} alt="" />
            Export selected visits
          </Button>
        </div>
      </div>
      <div className="tabsHeader">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="customTabs"
        />
      </div>
    </div>
  );
}

export default FieldTeamStatusBoard;
