import { useEffect } from "react";
import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import IpPlanningBoard from "./IpPlanningBoard";
import TasksAssignments from "./TasksAssignments/TasksAssignments";
import { DataProvider } from "./TasksAssignments/DataContext";

import VisitsBoard from "./VisitBoard/VisitBoard";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";
import FieldLogisticsBoard from "../implementation/FieldLogisticsBoard/FieldLogisticsBoard";

const { TabPane } = Tabs;

const VALID_TABS = ["ip-planning", "visits", "tasks", "logistics"];

function Planning() {
  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultActiveKey = () => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    return VALID_TABS.includes(activeKey) ? activeKey : "ip-planning";
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    if (activeKey && !VALID_TABS.includes(activeKey)) {
      navigate("/project/planning");
    }
  }, [location.search, navigate]);

  const handleTabChange = (key) => {
    navigate(`/project/planning?${key}`);
  };
  return (
    <>
      <div className="planning-banner">
      <Banner text={`${localStorage.getItem("projectRoundName")} - Planning`} />
      </div>
      <div className="planning-page">
        <Tabs
          type="card"
          className="card-tabs"
          activeKey={getDefaultActiveKey()}
          onChange={handleTabChange}
          destroyInactiveTabPane={true}
        >
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.Guest,
          ]) && (
            <TabPane tab="IP Planning Board" key="ip-planning">
              <div className="insideTab">
                <IpPlanningBoard />
              </div>
            </TabPane>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
            RolesEnum.LimitedGuest,
          ]) && (
            <TabPane tab="Visits Board" key="visits">
              <div className="insideTab">
                <VisitsBoard />
              </div>
            </TabPane>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
            RolesEnum.Guest,
          ]) && (
            <TabPane tab="Tasks Assignments" key="tasks">
              <div className="insideTab">
                <DataProvider>
                  <TasksAssignments />
                </DataProvider>
              </div>
            </TabPane>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
            RolesEnum.Guest,
          ]) && (
            <TabPane tab="Field Logistics Board" key="logistics">
              <FieldLogisticsBoard></FieldLogisticsBoard>
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
}

export default Planning;
