import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Spin,
  Divider,
} from "antd";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import "react-phone-input-2/lib/style.css";
import { httpService } from "../../../../../../services/httpService.service";
import uploadIcon from "../../../../../../Assets/svg/uploadIcon.svg";
import { useFilePreview } from "../../../../completed/ReportingList/Download";

const { Option } = Select;

const Preview = ({ visible, onClose, record }) => {
  const [form] = Form.useForm();
  const [redFlag, setRedFlag] = useState();
  const [loading, setLoading] = useState(false);
  const { previewFiles } = useFilePreview();

  useEffect(() => {
    if (record?.id) {
      fetchRedFlag(record?.id);
    }
    console.log("teest", record?.id);
  }, [form, visible, record]);

  async function fetchRedFlag(redFlagId) {
    setLoading(true);

    httpService.get(
      `/api/RedFlag/getPostSiteVisit?id=${redFlagId}`,
      (res) => {
        setRedFlag(res.data);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching the red flag:", error);
        setLoading(false);
      }
    );
  }

  if (!record) {
    return null;
  }

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const convertAnswerText = (answer) => {
    switch (answer) {
      case "1":
        return "1 - Strongly Disagree";
      case "2":
        return "2 - Disagree";
      case "3":
        return "3 - Neither disagree nor agree";
      case "4":
        return "4 - Agree";
      case "5":
        return "5 - Strongly Agree";
      default:
        return answer; // Return original answer if it's not a number between 1 and 5
    }
  };
  const questions =
    redFlag?.responses?.map((response) => ({
      questionText: response?.question?.text,
      answerText: convertAnswerText(response?.option?.text),
      inputValue: response?.inputValue,
    })) || [];

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return `${formattedDate}`;
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">Preview</h2>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", height: "50vh" }}
        >
          <Spin spinning={loading}> </Spin>{" "}
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="name"
              label={<span style={{ fontWeight: "bold" }}>Visit date</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{formatDate(redFlag?.date)}</p>
            </Form.Item>
            <Form.Item
              name="ip"
              label={
                <span style={{ fontWeight: "bold" }}>Site visit card</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{redFlag?.siteVisit?.identifier}</p>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="name"
              label={
                <span style={{ fontWeight: "bold" }}>Implementing partner</span>
              }
              style={{ width: "48%" }}
            >
              <p className="previewText">{redFlag?.implementingPartner.name}</p>
            </Form.Item>
            {/* <Form.Item
              name="ip"
              label={<span style={{ fontWeight: "bold" }}>Location</span>}
              style={{ width: "48%" }}
            >
              <p className="previewText">{redFlag?.implementingPartnerName}</p>
            </Form.Item> */}
          </div>

          {/* Questions and Answers */}
          <div className="questionsDiv">
            {questions.map((item, index) => (
              <div key={index} style={{ width: "100%" }}>
                <p className="previewQuestion">{item?.questionText}</p>
                <p className="questionTitle">Answer</p>
                <p className="questionContent">{item?.answerText}</p>
                {item?.inputValue && (
                  <>
                    <p className="questionTitle">Explonation</p>
                    <p className="questionContent">{item.inputValue}</p>
                  </>
                )}
                <Divider />
              </div>
            ))}
          </div>
          <Form.Item
            name="name"
            label={
              <span style={{ fontWeight: "bold" }}>Any other comments</span>
            }
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.otherFeedback}</p>
          </Form.Item>
          <Form.Item
            name="ip"
            label={<span style={{ fontWeight: "bold" }}>Staff name </span>}
            style={{ width: "48%" }}
          >
            <p className="previewText">{redFlag?.staffName}</p>
          </Form.Item>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default Preview;
