import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import ReportingList from "./ReportingList/ReportingList";
import DatasetList from "./DatasetList/DatasetList";
import VisitsMap from "./VisitsMap/VisitsMap";
import MessagePopUp from "../../components/Messages/MessagePopUp";

const VALID_TABS = ["ReportingList", "DatasetList", "VisitsMap"];

function Completed() {
  const [activeComponent, setActiveComponent] = useState("ReportingList");
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const location = useLocation();
  const navigate = useNavigate();

  const getDefaultActiveKey = () => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    return VALID_TABS.includes(activeKey) ? activeKey : "ReportingList";
  };

  useEffect(() => {
    const defaultTab = getDefaultActiveKey();
    setActiveComponent(defaultTab);
  }, [location.search]);

  const handleTabChange = (key) => {
    if (VALID_TABS.includes(key)) {
      navigate(`/project/completed?${key}`);
    } else {
      navigate("/project/completed");
    }
  };

  const renderComponent = () => {
    if (activeComponent === "ReportingList") {
      return (
        <div>
          <ReportingList />
        </div>
      );
    } else if (activeComponent === "DatasetList") {
      return (
        <div>
          <DatasetList />
        </div>
      );
    } else if (activeComponent === "VisitsMap") {
      return (
        <div>
          <VisitsMap />
        </div>
      );
    }
  };
  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner
        text={`${localStorage.getItem("projectRoundName")} - Completed`}
      />
      <div style={{ background: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          <div>
            <label
              className={
                activeComponent === "ReportingList"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="ReportingList"
                checked={activeComponent === "ReportingList"}
                onChange={() => handleTabChange("ReportingList")}
              />
              Reporting List
            </label>
          </div>

          <div>
            <label
              className={
                activeComponent === "DatasetList"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="DatasetList"
                checked={activeComponent === "DatasetList"}
                onChange={() => handleTabChange("DatasetList")}
              />
              Dataset List
            </label>
          </div>
          <div>
            <label
              className={
                activeComponent === "VisitsMap"
                  ? "tab-nav-active"
                  : "tab-nav-not-active"
              }
            >
              <input
                type="radio"
                value="VisitsMap"
                checked={activeComponent === "VisitsMap"}
                onChange={() => handleTabChange("VisitsMap")}
              />
              Visits Map
            </label>
          </div>
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default Completed;
