import { Table, Modal, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "../../../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../../../Assets/svg/table-action-preview.svg";
import DeleteComponent from "../../../../components/DeleteComponent/DeleteComponent";
import EditSiteVisitCardModal from "../Modals/EditSiteVisitCardModal";
import Preview from "./modals/Preview";
import Filters from "../Filters";
import { httpService } from "../../../../../services/httpService.service";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import { RolesEnum } from "../../../../../services/rolesEnum";
import { isAuthorized } from "../../../../../services/authService";
import duplicateIcon from "../../../../../Assets/svg/duplicateIcon.svg";
import sitevisitsuccess from "../../../../../Assets/svg/sitevisitsuccess.svg";
import "./styles.scss";

function TableView({
  siteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  setSiteVisitCardsForExcel,
  onPageChange,
  pagination,
  setPagination,
  onOk,
}) {
  //Handle Modals
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const projectID = localStorage.getItem("currentProjectID");
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [filters, setFilters] = useState({
    Search: "",
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: "",
    ClusterId: "",
    Month: "",
    Year: "",
  });

  const [inputSearch, setInputSearch] = useState(filters.Search);

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record?.id;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.id;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };
  const [recordToDelete, setRecordToDelete] = useState();

  const handleDelete = (record) => {
    const siteVisitId = record.id;
    setRecordToDelete(siteVisitId);
    setShowDeleteModal(true);
  };

  const handleDuplicate = (record) => {
    setCurrentRecord(record);
    setShowDuplicateModal(true);
  };

  const confirmDuplicate = async () => {
    if (!currentRecord?.id) {
      console.error("Invalid record: missing siteVisitCard ID");
      return;
    }

    const siteVisitId = currentRecord.id;
    console.log("trying:", siteVisitId);

    const url = `/api/Site/duplicateSiteVisit?siteVisitId=${siteVisitId}`;

    try {
      setLoading(true);
      const response = await httpService.post(url);

      setMessage({
        trigger: true,
        type: "success",
        icon: sitevisitsuccess,
        title: "Site visit card duplicated successfully!",
        text: "You have successfully duplicated this site visit.",
      });
      fetchSiteVisitCards(projectID);
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
    } catch (error) {
      console.error("Failed to duplicate site visit:", error);
      setMessage({
        trigger: true,
        type: "error",
        icon: "",
        title: "Duplication Failed",
        text: "Site visit card duplication failed!",
      });
    } finally {
      setLoading(false);
      setShowDuplicateModal(false);
    }
  };

  const onEdited = () => {
    setShowEditModal(false);
    onOk();
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (recordToDelete) {
      const requestBody = [
        {
          id: recordToDelete,
          isDeleted: false,
        },
      ];

      const url = `/api/Site/deleteSiteVisits?includeIsDeleted=false`;
      httpService.delete(
        url,
        JSON.stringify(requestBody),
        (response) => {
          const message = response.message;
          setMessage({
            trigger: true,
            type: "success",
            icon: "",
            title: "Site visit card created successfully!",
            text:
              message ||
              "You have successfully deleted this site visit. You can revert it from deleted cards.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          fetchSiteVisitCards(projectID);
          setShowDeleteModal(false);
          setLoading(false);
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message || "Error deleting record.";
          console.error("Error deleting record:", error);

          setMessage({
            trigger: true,
            type: "danger",
            icon: "",
            title: "Oops! A problem has occurred!",
            text: errorMessage || "Failed to delete the record.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setLoading(false);
        }
      );

      setRecordToDelete(null);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setPagination({ ...pagination, current: 1 });
  };

  useEffect(() => {
    fetchSiteVisitCards(
      projectID,
      filters.Search,
      filters.ImplementingPartnerId,
      filters.State,
      filters.SiteVisitStatus,
      filters.ClusterId,
      filters.Month,
      filters.Year
    );
  }, [filters]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate}, ${formattedTime}`;
  };

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      dateOfPlannedVisit: siteVisitCard?.visit?.plannedVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      siteVisitStatus: formatStatus(siteVisitCard?.tracking?.siteVisitStatus),
      sector: siteVisitCard?.sector?.name,
      subsector: siteVisitCard?.subSector?.name,
      country: siteVisitCard?.locationDetails?.country,
      city: siteVisitCard?.locationDetails?.city,
      team: siteVisitCard?.logistic?.team?.name,
      siteVisitCard: siteVisitCard,
    };
  };

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
      dataIndex: "identifier", // Raw field for identifier
      key: "siteVisitIdentifier",
      render: (text, record) => (
        <span className="styledDataIndex3">{text}</span>
      ),
    },
    {
      title: <span className="styledTitle">Site visit name</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      dataIndex: "name", // Raw field for identifier
      key: "name",
      render: (text, record) => (
        <span className="styledDataIndex3">{text}</span>
      ),
    },

    {
      title: <span className="styledTitle">Date of planned visit</span>,
      sorter: (a, b) =>
        a.visit?.plannedVisit?.localeCompare(b.visit?.plannedVisit),
      key: "dateOfPlannedVisit",
      render: (text, record) => (
        <span className="styledDataIndex3">{record.visit?.plannedVisit}</span> // Accessing via record
      ),
    },

    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) =>
        a.ipPlan?.implementingPartner?.name.localeCompare(
          b.ipPlan?.implementingPartner?.name
        ),
      key: "ip",
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.ipPlan?.implementingPartner?.name}
        </span>
      ),
    },

    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) =>
        a.locationDetails?.country.localeCompare(b.locationDetails?.country),
      key: "location",
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.locationDetails?.country}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster?.name.localeCompare(b.cluster?.name),
      key: "cluster",
      render: (text, record) => (
        <span className="styledDataIndex3">{record.cluster?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Virtual</span>,
      sorter: (a, b) => a.isVirtual.localeCompare(b.isVirtual),
      key: "virtual",
      render: (text, record) => (
        <span className="styledDataIndex3">
          {record.isVirtual ? "Yes" : "No"}
        </span>
      ),
    },
    // {
    //   title: <span className="styledTitle">Site visit status</span>,
    //   sorter: (a, b) => a.siteVisitStatus.localeCompare(b.siteVisitStatus),
    //   dataIndex: "siteVisitStatus",
    //   key: "siteVisitStatus",
    //   render: (text) => {
    //     let className;
    //     let fill;
    //     if (text === "Data Collection") {
    //       className = "dataCollection";
    //       fill = "#3B81FE";
    //     } else if (text === "Post-data Collection") {
    //       className = "monitoring";
    //       fill = "#FC9403";
    //     } else if (text === "Finalization") {
    //       className = "review";
    //       fill = "#9670B8";
    //     } else if (text === "Delivered") {
    //       className = "delivered";
    //       fill = "#045B46";
    //     } else if (text === "IP Feedback") {
    //       className = "debrief";
    //       fill = "#0F7507";
    //     } else {
    //       className = "noStatus";
    //       fill = "rgba(172, 12, 12, 0.777)";
    //     }
    //     return (
    //       <div className={`${className} m-0`}>
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           width="8px"
    //           height="8px"
    //           viewBox="0 0 9 9"
    //         >
    //           <circle cx="4.5" cy="4.27344" r="4" fill={fill} />
    //         </svg>
    //         <span>{text}</span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: <span className="styledTitle"></span>,
      key: "action",

      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={duplicateIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDuplicate(record)}
              loading={loading}
              setMessage={setMessage}
            ></img>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
            RolesEnum.Guest,
          ]) && (
            <img
              src={previewIcon}
              onClick={() => handleTogglePreviewModal(record)}
              style={{ cursor: "pointer" }}
            ></img>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <img
              src={editIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(record)}
            ></img>
          )}
        </div>
      ),
    },
  ];

  const handleSelectionChange = (newSelectedRowKeys, selectedRows) => {
    console.log("New Selected Row Keys:", newSelectedRowKeys); // Debugging log
    if (!Array.isArray(newSelectedRowKeys)) {
      console.error("Invalid selectedRowKeys:", newSelectedRowKeys); // Check for any issues
      setSelectedRowKeys([]); // Default to an empty array if the type is incorrect
    } else {
      setSelectedRowKeys(newSelectedRowKeys); // Correctly update the state
    }

    const formattedData = selectedRows?.map((item) => ({
      "Site Visit Identifier": item.identifier || "N/A",
      "Site Visit Name": item?.name || "N/A",
      "Date of planned visit": item?.visit?.plannedVisit || "N/A",
      IP: item?.ipPlan?.implementingPartner?.name || "N/A",
      Location: item?.locationDetails?.country || "N/A",
      Cluster: item?.cluster?.name || "N/A",
      Virtual: (item?.isVirtual ? "Yes" : "No") || "N/A",
      "Site visit status":
        formatStatus(item?.tracking?.siteVisitStatus) || "N/A",
      sector: item?.sector?.name || "N/A",
      subsector: item?.subSector?.name || "N/A",
      country: item?.locationDetails?.country || "N/A",
      city: item?.locationDetails?.city || "N/A",
      team: item?.logistic?.team?.name || "N/A",
    }));
    setSiteVisitCardsForExcel(formattedData);
  };

  const rowSelection = {
    onChange: handleSelectionChange,
    selectedRowKeys,
  };

  // Formating data for the table
  const formatStatus = (status) => {
    if (status === 1) {
      return "No status";
    } else if (status === 2) {
      return "Pre-planning phase";
    } else if (status === 3) {
      return "Planning";
    } else if (status === 4) {
      return "Data collection";
    } else if (status === 5) {
      return "Post data collection/Data check";
    } else if (status === 6) {
      return "Visit completed";
    } else {
      return "No status";
    }
  };

  return (
    <>
      <Filters
        onFiltersChange={handleFiltersChange}
        filters={filters}
        setFilters={setFilters}
        pagination={pagination}
        setPagination={setPagination}
        setInputSearch={setInputSearch}
        inputSearch={inputSearch}
      />
      <div>
        <Table
          rowKey="id"
          loading={loading}
          rowSelection={{ ...rowSelection }}
          dataSource={siteVisitCards}
          columns={columns}
          pagination={{
            ...pagination,
            onChange: onPageChange,
          }}
          className="tableTPM"
          scroll={{ x: "max-content" }}
        />
      </div>
      <EditSiteVisitCardModal
        onOk={onEdited}
        visible={showEditModal}
        onCancel={handleToggleEditModal}
        siteVisitCardId={selectedSiteVisit}
        setMessage={setMessage}
      />
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Modal
        title="Duplicate Site Visit Card"
        visible={showDuplicateModal}
        onOk={confirmDuplicate}
        onCancel={() => setShowDuplicateModal(false)}
        className={loading ? "modal-loading" : ""}
        footer={[
          <Button
            key="back"
            onClick={() => setShowDuplicateModal(false)}
            className="inviteButtonTPM"
          >
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={confirmDuplicate}
            className="addButtonTPM"
          >
            Yes
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <div className="d-flex justify-content-center align-content-center">
            <p>Are you sure you want to duplicate this site visit card?</p>
            {loading ? (
              <div className="modal-spinner">{/* <Spin size="large" /> */}</div>
            ) : (
              <p></p>
            )}
          </div>
        </Spin>
      </Modal>
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete this site visit card. You can return it back from the deleted cards later! "
        }
        header={"Delete "}
        onConfirm={handleConfirmDelete}
        loading={loading}
      />
    </>
  );
}

export default TableView;
