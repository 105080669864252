import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpService } from "../../../services/httpService.service";
import SearchIcon from "./../../../Assets/svg/searchIcon.svg";

const { Option } = Select;

const TableFilters = ({ filters, setFilters, setPagination }) => {
  const [countries, setCountries] = useState([]);
  const [form] = Form.useForm();

  const handleCountry = (CountryId) => {
    console.log(CountryId);
    if (CountryId?.length < 1) {
      handleClearAll();
      return;
    }
    setFilters((prevFilters) => ({ ...prevFilters, CountryId }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  const handleClearAll = () => {
    setFilters({
      search: "",
      CountryId: "",
    });
    form.setFieldsValue({ country: undefined });
  };

  useEffect(() => {
    const apiCountries = "/api/GeoData/countries";

    httpService.get(
      apiCountries,
      (res) => setCountries(res.data),
      (error) => console.log(error)
    );
  }, []);

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleSearchChange = (e) => {
    const search = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search }));
    setPagination((prevPagination) => ({ ...prevPagination, current: 1 }));
  };

  return (
    <div className="filtersWrapper mb-3">
      <Form form={form} className="w-100">
        <div className="containerFilters w-100">
          <div className="d-flex align-items-center justify-content-between w-100 gap-2">
            <Input
              value={filters.search}
              placeholder="Search by office name..."
              onChange={handleSearchChange}
              style={{ width: "100%" }}
              suffix={<img src={SearchIcon} alt="Search" />}
            />
            <Button className="me-2 clearButton" onClick={handleClearAll}>
              Clear All
            </Button>
            <Button onClick={handleToggleFilters} className="toggleButton">
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
          </div>
        </div>
        {showFilters && (
          <div className="filtersContainer mt-4">
            <div>
              <p className="filterText">Country</p>
              <Form.Item name="country">
                <Select
                  value={filters.CountryId}
                  allowClear
                  showSearch
                  mode="multiple"
                  style={{ width: 200, marginBottom: 20 }}
                  placeholder="Select a country"
                  onChange={handleCountry}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {countries &&
                    countries.map((country) => (
                      <Option key={country.id} value={country.name}>
                        {country.stateName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default TableFilters;
