import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Spin } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";

const createIcon = (color, number) => {
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="background-color: ${color}; border-radius: 50%; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; color: white; font-weight: bold; font-size:18px">${number}</div>`,
    iconSize: [40, 40],
  });
};

const Map = ({ selectedIP }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);

  useEffect(() => {
    if (selectedIP) {
      fetchSiteVisits();
    }
  }, [selectedIP]);

  const fetchSiteVisits = () => {
    setLoading(true);
    const projectId = localStorage.getItem("currentProjectID");
    const params = new URLSearchParams({
      ProjectId: projectId,
      ...(selectedIP && { ImplementingPartnerId: selectedIP }),
    });

    httpService.get(
      `/api/Site/getAllSiteVisits?${params.toString()}&pageSize=1000`,
      (res) => {
        setData(res.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleMarkerClick = (siteVisitCardId) => {
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
    setSelectedSiteVisit(null);
  };


  return (
    <>
      <div className="ipCardDiv">
        <div style={{ position: "relative", height: "450px", width: "100%" }}>
          {loading && (
            <Spin
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            />
          )}
          <MapContainer
           minZoom={1}
           center={[20, 0]}
           zoom={2}
           style={{ height: "100%", width: "100%" }}
           maxBounds={[
             [-90, -180],
             [90, 180],
           ]}
           maxBoundsViscosity={1.0}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {data.map((item, idx) => (
              <Marker
                key={idx}
                position={[
                  item?.locationDetails?.latitude,
                  item?.locationDetails?.longitude,
                ]}
                icon={createIcon("#0f750766", idx + 1)}
                eventHandlers={{
                  click: () => handleMarkerClick(item.id),
                }}
              >
                
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleClosePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
    </>
  );
};

export default Map;
