import React, { useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../TableView/Filters";
import { httpService } from "../../../../../services/httpService.service";
import PreviewModal from "../../../planning/modals/PreviewModal";
import { Pagination, Spin } from "antd";

const KanbanViewIpDonor = ({
  handleFilterChange,
  setFilters,
  fetchIpPlan,
  IPPlans,
  setIPPlans,
  onPageChange,
  pagination,
  setPagination,
  loading,
  setLoading,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();

  const changeIPPlanStatus = (draggedTask) => {
    setLoading(true);
    const { id, column } = draggedTask;

    const updatedIpPlans = IPPlans.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          ipDonorReportingEnum: column,
        };
      }
      return card;
    });

    setIPPlans(updatedIpPlans);

    const requestBody = {
      ipPlanId: draggedTask.id,
      ipDonorReporting: draggedTask.column,
    };

    httpService.put(
      "/api/IPPlan/changeIPPlanDonorReportingStatus",
      requestBody,
      (res) => fetchIpPlan(false),
      (error) => console.log(error)
    );
  };

  const columns = [
    { id: 1, title: "Data collection", color: "#3B81FE" },
    { id: 2, title: "Data cleaning", color: "#06768E" },
    { id: 4, title: "Data translation", color: "#918d27" },
    { id: 3, title: "Data analysis", color: "#045B46" },
    {
      id: 5,
      title: "IP/Client report",
      color: "var(--Primary-Blueberry-700, #325899)",
    },
    {
      id: 6,
      title: "Donor Report",
      color: "#964B00",
    },
    { id: 7, title: "Completed", color: "#0F7507" },
  ];

  const handleTaskClick = (taskId) => {
    setSelectedTaskId(taskId);
    if (taskId) {
      setShowPreviewModal(!showPreviewModal);
    }
  };

  const handlePreviewOk = () => {
    setShowPreviewModal(false);
  };

  const handleCancel = () => {
    setShowPreviewModal(false);
  };

  return (
    <>
      <PreviewModal
        visible={showPreviewModal}
        record={selectedTaskId}
        onOk={handlePreviewOk}
        onCancel={handleCancel}
      />
      <Filters
        handleFilterChange={handleFilterChange}
        setFilters={setFilters}
      />
      <div id="excludedStrictMode">
        <Spin spinning={loading}>
          <KanbanBoardComponent
            tasks={IPPlans}
            columns={columns}
            changeStatus={changeIPPlanStatus}
            setClickedTaskId={handleTaskClick}
          />
        </Spin>
      </div>
      <div className="d-flex justify-content-end my-5">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={(page, pageSize) => {
            onPageChange(page, pageSize);
            setPagination({ ...pagination, current: page, pageSize });
          }}
        />
      </div>
    </>
  );
};

export default KanbanViewIpDonor;
