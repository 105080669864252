import { Modal, notification, Table, Spin } from "antd";
import { useState, useEffect } from "react";
import deleteIcon from "../../../../../../Assets/svg/table-action-delete.svg";
import { RevertButton } from "../../../../../../Assets/svg";
import { httpService } from "../../../../../../services/httpService.service";
import MessagePopUp from "../../../../../components/Messages/MessagePopUp";
import cancelX from "../../../../../../Assets/svg/cancelXbutton.svg";
import DeleteComponent from "../../../../../components/DeleteComponent/DeleteComponent";

const DeletedModal = ({ visible, onOk, onCancel, fetchSiteVisitCards }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isReverting, setIsReverting] = useState(false);
  const projectId = localStorage.getItem("currentProjectID");
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchData = async (pagination) => {
    setLoading(true);

    if (!projectId) {
      throw new Error("Project ID is not available");
    }

    const url = `/api/Site/getAllSiteVisits?ProjectId=${projectId}&GetDeletedOnly=true&page=${pagination.current}&pageSize=${pagination.pageSize}`;
    httpService.get(
      url,
      (result) => {
        if (!result || !result.data || !result.metaData) {
          throw new Error("API response is missing expected fields.");
        }
        setData(result.data);
        setPagination({
          ...pagination,
          total: result.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Failed to fetch tasks:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (visible) {
      fetchData(pagination);
    }
  }, [visible]);

  const handleConfirmDelete = () => {
    const url = `/api/Site/deleteSiteVisits?includeIsDeleted=true`;
    const data = JSON.stringify([{ id: recordToDelete, isDeleted: true }]);
    httpService.delete(
      url,
      data,
      (res) => {
        const message = res?.message || "Site Visits are successfully deleted!";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Site Visits are successfully deleted!",
          text: message,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        fetchData(pagination);

        setShowDeleteModal(false);
      },
      (error) => {
        const errorMessage = error?.errors?.[0]?.message;
        setShowDeleteModal(false);
        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        console.error("Failed to delete task:", error?.errors?.[0]?.message);
      }
    );
  };

  const deleteTask = (taskId) => {
    setRecordToDelete(taskId);
    setShowDeleteModal(true);
  };

  const recoverData = async (id) => {
    setIsReverting(true); // Set loading to true when revert starts
    httpService.put(
      `/api/Site/revertDelete`,
      {
        entityType: 11,
        ids: [id],
      },
      (res) => {
        const message = res.message || "Site visit card recovered successfully";
        setMessage({
          trigger: true,
          type: "success",
          icon: "",
          title: "Site visit reverted successfully!",
          text: message,
        });
        fetchData(pagination);
        fetchSiteVisitCards(projectId);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setIsReverting(false); // Set loading to false when revert is done
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to recover site visit card! Try again later...";

        setMessage({
          trigger: true,
          type: "danger",
          icon: "",
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setIsReverting(false); // Set loading to false if an error occurs
      }
    );
  };

  const handleTableChange = (pagination) => {
    fetchData(pagination);
  };

  const statusMappingSiteVisitStatus = (status) => {
    switch (status) {
      case 1:
        return {
          text: "Data collection in progress",
          className: "progress",
          fill: "#3B81FE",
        };
      case 2:
        return {
          text: "Selected for monitoring",
          className: "monitoring",
          fill: "#FC9403",
        };
      case 3:
        return { text: "Review", className: "review", fill: "#9670B8" };
      case 4:
        return { text: "Delivered", className: "delivered", fill: "#045B46" };
      case 5:
        return { text: "Debrief", className: "debrief", fill: "#0F7507" };
      default:
        return { text: "Unknown", className: "unknown", fill: "#000000" };
    }
  };
  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
      dataIndex: "identifier",
      key: "identifier",
      render: (record) => <span className="styledDataIndex3">{record}</span>,
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) =>
        a.implementingPartner.localeCompare(b.implementingPartner),
      dataIndex: "implementingPartner",
      key: "implementingPartner",
      render: (record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.locationDetails.localeCompare(b.locationDetails),
      dataIndex: "locationDetails",
      key: "locationDetails",
      render: (record) => (
        <span className="styledDataIndex3">{record?.country}</span>
      ),
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (record) => (
        <span className="styledDataIndex3">{record?.name}</span>
      ),
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.isVirtual.localeCompare(b.isVirtual),
      dataIndex: "isVirtual",
      key: "isVirtual",
      render: (record) => (
        <span className="styledDataIndex3">{record ? "Yes" : "No"}</span>
      ),
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && data.length > 0 ? "right" : undefined,
      width: 100,
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <div className="actionDiv">
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => deleteTask(record.id)}
              alt="deleteIcon"
            />
          </div>
          <div className="actionDiv">
            {isReverting ? (
              <Spin size="small" />
            ) : (
              <img
                src={RevertButton}
                style={{ cursor: "pointer" }}
                onClick={() => recoverData(record.id)}
                alt="RevertButton"
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Modal
        title={
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <img
                src={cancelX}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={onCancel}
                alt="cancelX"
              />
            </div>
            <h2 className="modalHeader">Deleted Site Visits</h2>
          </>
        }
        open={visible}
        onOk={onOk}
        closable={false}
        onCancel={onCancel}
        footer={[]}
        width={"70%"}
      >
        <Table
          loading={loading}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: "max-content" }}
        />
        <DeleteComponent
          visible={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          text={
            "You're about to delete this site visit card. This action can’t be undone. "
          }
          header={"Delete "}
          onConfirm={handleConfirmDelete}
        />
      </Modal>
    </div>
  );
};

export default DeletedModal;
