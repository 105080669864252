import React, { useState, useEffect } from "react";
import "../../../pages/reporting/Reporting.scss";
import { Button, Tabs } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableView from "./TableView/TableView";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";

function SiteVisitSummary() {
  const [data, setData] = useState([]);
  const [dataForExcel, setDataForExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1 });
  const projectID = localStorage.getItem("currentProjectID");

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    ImplementingPartnerId: "",
    State: "",
    SiteVisitStatus: null,
    FieldTeamReportingStatus: null,
    ClusterId: "",
    Month: "",
    Year: "",
    Search: "",
  });

  const fetchSiteVisits = async (ignorePagination) => {
    const params = {
      ProjectId: projectID,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters.SiteVisitStatus,
      FieldTeamReportingStatus: filters.FieldTeamReportingStatus,
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      Search: filters.Search,
      IgnorePagination: ignorePagination,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();
    setLoading(true);
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        setData(response.data);
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visits:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
  }, [filters, pagination.pageSize, pagination.current]);

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const changeSiteVisitCardStatus = (draggedTask) => {
    setLoading(true);
    const draggedSiteVisit = data?.filter(
      (siteVisitCard) => siteVisitCard?.id === draggedTask?.id
    );
    if (!draggedSiteVisit || draggedSiteVisit.length === 0) {
      console.error("No matching site visit card found");
      return;
    }

    const trackingId = draggedSiteVisit[0]?.tracking?.id;
    const fieldTeamReportingStatus = Number(draggedTask.column);

    if (!trackingId || isNaN(fieldTeamReportingStatus)) {
      console.error("Invalid trackingId or siteVisitStatus", {
        trackingId,
        fieldTeamReportingStatus,
      });
      return;
    }

    const updatedSiteVisitCards = data.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          tracking: {
            ...card.tracking,
            fieldTeamReportingStatus: fieldTeamReportingStatus,
          },
        };
      }
      return card;
    });

    setData(updatedSiteVisitCards);

    httpService.put(
      `/api/Site/changeFieldTeamReportingStatus?TrackingId=${trackingId}&FieldTeamReportingStatus=${fieldTeamReportingStatus}`,
      "",
      (response) => {
        fetchSiteVisits(false);
        setLoading(false);
      },
      (error) => {
        console.error("Error:", error);
        setLoading(false);
      }
    );

    setTimeout(() => {}, 3000);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);

    if (isNaN(date)) {
      return "Invalid date";
    }

    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    const formattedTime = date.toLocaleTimeString("en-US", { hour12: true });

    return `${formattedDate} Time: ${formattedTime}`;
  };
  // format data for Kanban view
  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: formatDate(siteVisitCard?.createdDate),
        ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      column: siteVisitCard?.tracking?.fieldTeamReportingStatus,
    };
  };
  const handleExport = () => {
    const projectID = localStorage.getItem("currentProjectID");
  
    if (!projectID) {
      setMessage({
        trigger: true,
        type: "error",
        icon: "error",
        title: "Export Failed",
        text: "Project ID is missing.",
      });
      return;
    }
  
    setLoading(true);
  
    httpService.post(
      `/api/Site/exportSiteVisits?projectId=${projectID}`,
      {},
      (response) => {
        setLoading(false);
        const blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "exported_file.xlsx"; // Default file name
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        // Revoke the URL after download
        URL.revokeObjectURL(url);
      },
      (error) => {
        setLoading(false);
        console.error("Export failed:", error);
        setMessage({
          trigger: true,
          type: "error",
          icon: "error",
          title: "Export Failed",
          text: "An error occurred while exporting.",
        });
      },
      { responseType: "arraybuffer" },
    );
  };
  
  return (
    <div
      className="tableViewcontainer visitBoardComponent"
      style={{ padding: "60px 40px 80px 40px" }}
    >
      <div className="tableHeader">
        <span className="tableViewHeaderText">Site Visit Summary</span>

        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="tableViewHeaderButtons">
          <Button
            className={`exportButtonTPM addButtonTPM`}
            onClick={handleExport}
          >
            <img src={ExportButton} alt="" />
            Export selected visits
          </Button>
        </div>
      </div>
      <TableView
        data={data}
        handleFilterChange={handleFilterChange}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        setDataForExcel={setDataForExcel}
        onPageChange={onPageChange}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );
}

export default SiteVisitSummary;
